import React, { useState } from 'react'
import LevelSplit from '../../components/offices/LevelSplit'
import RoleOffice from '../../components/offices/RoleOffice'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import toast from 'react-hot-toast'

function StudentTransferRequest() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [premsNo, setPremsNo] = useState("")
    const [student, setStudent] = useState(null)

    const probeStudent = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setStudent(null)

        try {
            const response = await fetch(`${BASE_API_URL}/students/probe/${premsNo}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setStudent(data)
            }
        }
        catch (error) {
            toast.error('Failed to search student. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const requestTransfer = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/transfer/student/request/${student?.id}`, {
                method: "POST",
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error('Failed to request student transfer. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>
                <LevelSplit primary={"Pupils"} ordinary={"Students"} /> Transfer Request
            </p>

            <form onSubmit={probeStudent} className="body shadow p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1 rounded-0"
                    placeholder={
                        auth.user.level === "PRIMARY" ?
                            "Search pupils by prem number" :
                            "Search students by prems number"
                    }
                    value={premsNo}
                    onChange={e => setPremsNo(e.target.value)}
                />
                <button type='submit' className="btn btn-sm btn-primary px-3 fw-bold rounded-0">
                    Search
                </button>
            </form>

            <div className="my-3 shadow p-2">
                {
                    isLoading && <div className="text-center">Loading...<Loading /></div>
                }
                {
                    !isLoading && student &&
                    <div className="fs-5">
                        <div className="row fw-bold">
                            <div className="col text-end text-muted">First Name: </div>
                            <div className="col">{student?.firstname}</div>
                        </div>
                        <div className="row fw-bold">
                            <div className="col text-end text-muted">Middle Name: </div>
                            <div className="col">{student?.middlename}</div>
                        </div>
                        <div className="row fw-bold">
                            <div className="col text-end text-muted">Last Name: </div>
                            <div className="col">{student?.lastname}</div>
                        </div>
                        <div className="row fw-bold">
                            <div className="col text-end text-muted">School: </div>
                            <div className="col">{student?.school}</div>
                        </div>
                        <div className="row fw-bold">
                            <div className="col text-end text-muted">Class/Form: </div>
                            <div className="col">{student?.form}</div>
                        </div>
                    </div>
                }
            </div>
            <div className="mb-3">
                <button
                    className="btn btn-primary rounded-0 w-100 fw-bold"
                    onClick={requestTransfer}
                    disabled={!student || isLoading}
                >
                    Request Transfer
                </button>
            </div>
        </div>
    )
}

export default StudentTransferRequest
