import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import { Link, useLocation, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import Loading from '../../../components/loading/Loading'
import RoleOffice from '../../../components/offices/RoleOffice'
import toast from 'react-hot-toast'

function BareSubjectTopic() {
    const auth = useAuth()
    const param = useParams()
    const topicId = param.topicId
    const location = useLocation()
    const form = location?.state?.form
    const subject = location?.state?.subject

    const [isLoading, setIsLoading] = useState(false)
    const [topic, setTopic] = useState(location?.state?.topic || {})
    const [editMode, setEditMode] = useState("")
    const [updatedTopic, setUpdatedTopic] = useState(topic?.name || "")
    const [updatedOrder, setUpdatedOrder] = useState(topic?.order)
    const [updatedSubTopic, setUpdatedSubTopic] = useState("")

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Sub Topic',
            cell: row => <div>
                {
                    editMode && editMode === row.id ?
                        <form
                            onSubmit={updateSubTopic}
                        >
                            <input
                                type='text'
                                className='form-control rounded-0'
                                autoFocus
                                value={updatedSubTopic}
                                onChange={e => setUpdatedSubTopic(e.target.value)}
                            />
                        </form>
                        :
                        <span>{row.name}</span>
                }
            </div>,
            sortable: true
        },
        {
            name: 'Action',
            cell: row =>
                <RoleOffice roles={["Admin"]} element={
                    <div>
                        {
                            editMode === row.id ?
                                (
                                    <button
                                        className='btn btn-primary rounded-0'
                                        disabled={isLoading}
                                        onClick={() => {
                                            updateSubTopic()
                                        }}
                                    >
                                        <i className='bi bi-check-lg'></i>
                                    </button>
                                ) :
                                (
                                    <button
                                        className='btn btn-primary rounded-0'
                                        disabled={isLoading}
                                        onClick={() => {
                                            setUpdatedSubTopic(row.name)
                                            setEditMode(row.id)
                                        }}
                                    >
                                        <i className='bi bi-pencil'></i>
                                    </button>
                                )
                        }
                    </div>
                } />,
            sortable: false,
            grow: 0
        }
    ]

    const fetchTopic = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/bare/${topicId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setTopic(data.topic)
            }
        }
        catch (error) {
            toast.error('Failed to fetch topic details. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const updateTopic = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/bare/${topicId}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    "topic": updatedTopic,
                    "order": updatedOrder
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to update topic. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const updateSubTopic = async (e) => {
        if (!updatedSubTopic || updatedSubTopic.length < 3) {
            setEditMode("")
            return
        }

        if (e) {
            e.preventDefault()
        }
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/bare/subtopic/${editMode}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    "sub_topic": updatedSubTopic
                })
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to update sub topic. Please try again.")
        }
        finally {
            setIsLoading(false)
            setEditMode("")
        }
    }

    useEffect(() => {
        fetchTopic()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-start justify-content-between mb-3">
                <div>
                    <p className='h3 fw-bold text-muted'>Registered Subject Topic</p>
                    <span className="fw-bold">
                        {form?.name} {subject?.name}
                    </span>
                </div>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <RoleOffice roles={["Admin"]} element={
                        <>
                            <Link
                                to='new'
                                state={{ topic }}
                                className='btn btn-primary btn-sm fw-bold rounded-0'
                            >
                                <span className="me-2">Add Sub Topics</span>
                                <i className='bi bi-plus-square-fill'></i>
                            </Link>
                        </>
                    } />
                </div>
            </div>

            <div className="mb-3">
                <div className='fw-bold px-1'>
                    {
                        editMode && editMode === "topic" ?
                            <form className="me-2" onSubmit={e => e.preventDefault()}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Topic Name</label>
                                    <input
                                        id="name"
                                        type="text"
                                        className="form-control rounded-0"
                                        value={updatedTopic}
                                        onChange={e => setUpdatedTopic(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="order" className="form-label">Topic Number</label>
                                    <input
                                        id="order"
                                        type="number"
                                        className="form-control rounded-0"
                                        value={updatedOrder}
                                        onChange={e => setUpdatedOrder(e.target.value)}
                                    />
                                </div>
                            </form>
                            :
                            <div>
                                <span className="h4 text-muted me-2">{topic?.name}</span>
                                <br />
                                <span className="h5 text-muted me-2">Topic Number: {topic?.order}</span>
                            </div>
                    }
                    <RoleOffice roles={["Admin"]} element={
                        <div className="text-end">
                            <button
                                className="btn btn-sm btn-primary rounded-0 fw-bold px-3"
                                // all ids have a length greater than 10, thus disable this button when edit mode
                                // is intended for sub topics
                                disabled={isLoading || editMode?.length > 10}
                                onClick={() => {
                                    if (editMode) {
                                        setEditMode("")
                                        updateTopic()
                                    }
                                    else {
                                        setEditMode("topic")
                                    }
                                }}
                            >
                                {
                                    editMode ?
                                        <span>
                                            <span className="me-2">Save</span>
                                            <i className="bi bi-check-lg"></i>
                                        </span>
                                        :
                                        <span>
                                            <span className="me-2">Edit</span>
                                            <i className="bi bi-pencil"></i>
                                        </span>
                                }
                            </button>
                        </div>
                    } />
                </div>
            </div>

            <div className="shadow p-2 mb-3">
                <p className="h5 text-muted">Sub Topics</p>
                <DataTable
                    columns={columns}
                    data={topic.sub_topics}
                    highlightOnHover
                    progressPending={isLoading}
                    progressComponent={<div className='text-center'>Loading... <Loading /></div>}
                    noDataComponent={"No sub topics found."}
                />
            </div>
        </div>
    )
}

export default BareSubjectTopic
