import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../context/AuthContext'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../../../utils/constants'
import DataTable from 'react-data-table-component'
import Loading from '../../../../components/loading/Loading'
import RoleOffice from '../../../../components/offices/RoleOffice'
import toast from 'react-hot-toast'

function PastPaperExam() {
    const auth = useAuth()
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [exam, setExam] = useState(location?.state?.exam)

    const handleDelete = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/past-paper-exams/${exam?.id}`, {
                method: "DELETE",
                headers: {
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to delete exam past paper. Please try again")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>{exam?.title}</p>
                <div className="d-flex flex-wrap align-items-center">
                    <RoleOffice roles={["Admin"]} element={
                        <button
                            className="btn btn-danger btn-sm fw-bold rounded-0"
                            disabled={isLoading}
                            onClick={handleDelete}
                        >
                            <span className="me-2">Delete</span>
                            <i className="bi bi-trash"></i>
                        </button>
                    } />
                </div>
            </div>

            <div className="shadow p-2 mb-3">
                <div className=''>
                    <p className='text-muted'>
                        Exam Type: <span className='fw-bold'>{exam?.type}</span>
                    </p>
                    <p className='text-muted'>
                        Start Date: <span className='fw-bold'>{exam?.start_date}</span>
                    </p>
                    <p className='text-muted'>
                        End Date: <span className='fw-bold'>{exam?.end_date}</span>
                    </p>
                </div>
            </div>

            <div className="mb-3">
                <Outlet />
            </div>
        </div>
    )
}

export default PastPaperExam
