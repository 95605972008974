import React, { useEffect, useState } from 'react'
import Loading from '../../../../components/loading/Loading'
import { useAuth } from '../../../../context/AuthContext'
import { BASE_API_URL } from '../../../../utils/constants'
import { Link, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import RoleOffice from '../../../../components/offices/RoleOffice'
import toast from 'react-hot-toast'

function BarePastPapers() {
    const auth = useAuth()
    const params = useParams()
    const pastPaperExamId = params.examId

    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [filters, setFilters] = useState({ "form": "", "subject": "", "marking_scheme": "" })

    const [classes, setClasses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [totalPapers, setTotalPapers] = useState(0)
    const [papers, setPapers] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (page - 1) + (index + 1),
            grow: 0,
        },
        {
            name: "Form/Class",
            selector: row => row?.form?.name,
            sortable: true,
        },
        {
            name: "Subject",
            selector: row => <span>{row?.subject?.name} {row?.practical}</span>,
            sortable: true,
        },
        {
            name: "Marking Scheme",
            selector: row => row?.has_marking_scheme ? "Available" : "Not Available",
            sortable: true,
        },
        {
            name: "Action",
            cell: row =>
                <div className='d-flex'>
                    <Link
                        to={`${row?.id}/edit`}
                        state={{ paper: row }}
                        className='btn btn-sm btn-primary rounded-0 me-2'>
                        <i className='bi bi-pencil-square fs-6 mx-1'></i>
                    </Link>
                    <Link
                        to={`${row?.id}`}
                        state={{ paper: row }}
                        className='btn btn-sm btn-primary rounded-0'>
                        <i className='bi bi-eye-fill fs-6 mx-1'></i>
                    </Link>
                </div>,
            sortable: false,
        }
    ]

    const fetchClasses = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes/bare`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setClasses(data.classes)
            }
        } catch (error) {
            toast.error("Failed to fetch classes. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            toast.error('Failed to fetch subjects. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchPastPapers = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/past-paper-exams/${pastPaperExamId}/papers`
            url += `?form=${filters["form"]}&subject=${filters["subject"]}&marking_scheme=${filters["marking_scheme"]}`
            url += `&page=${page}&per_page=${perPage}`

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setPapers(data.papers)
                setPage(data.page)
                setPerPage(data.per_page)
                setTotalPapers(data.total)
            }
        }
        catch (error) {
            toast.error('Failed to fetch past papers. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchClasses()
        fetchSubjects()
        fetchPastPapers()
    }, [])

    useEffect(() => {
        fetchPastPapers()
    }, [page, perPage])

    return (
        <div>
            <DataTable
                title={
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <div className="fs-5">Total Past Papers: {totalPapers}</div>
                        <RoleOffice roles={["Admin"]} element={
                            <Link
                                to={"new"}
                                className="btn btn-primary btn-sm fw-bold rounded-0"
                            >
                                <span className="me-2">Add Papers</span>
                                <i className="bi bi-plus-square"></i>
                            </Link>
                        } />
                    </div>
                }
                columns={columns}
                data={papers}
                progressPending={isLoading}
                progressComponent={
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                }
                pagination
                paginationServer
                paginationTotalRows={totalPapers}
                onChangePage={p => setPage(p)}
                onChangeRowsPerPage={pp => setPerPage(pp)}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10, 20, 30, 50]}
                noDataComponent="No past papers found."
            />
        </div>
    )
}

export default BarePastPapers
