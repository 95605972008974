// Utility function to convert date from dd/mm/yyyy to yyyy-mm-dd
const convertDateFormat = (date) => {
    if (!date) return date;

    // check if date isnt in the format and return date as it is
    if (!date.includes('/')) return date;

    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
};

// convert JS date objects to specified format
// if null is passed converts current date
const formatDate = (date=null, format="ymd") => {
    let today = date
    if (!date) {
        today = new Date()
    }

    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Month is zero-based
    const day = String(today.getDate()).padStart(2, '0')

    if (format === "yyyy-mm-dd") {
        return `${year}-${month}-${day}`
    }
    else {
        return `${day}/${month}/${year}`
    }
}

export { convertDateFormat, formatDate }
