import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import Loading from '../../../components/loading/Loading'
import RoleOffice from '../../../components/offices/RoleOffice'
import { BASE_API_URL } from '../../../utils/constants'
import { saveAs } from 'file-saver'
import toast from 'react-hot-toast'

function LessonNotesDownload({ purpose }) {
    const auth = useAuth()
    const params = useParams()
    const formId = params.formId
    const subjectId = params.subjectId
    const location = useLocation()
    const form = location?.state?.form
    const subject = location?.state?.subject

    const [isLoading, setIsLoading] = useState(false)
    const [notes, setNotes] = useState([])

    const fetchLessonNotes = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/${purpose}?form=${formId}&subject=${subjectId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                if (purpose === "books") {
                    setNotes(data.books)
                }
                else if (purpose === "syllabus") {
                    setNotes(data.syllabuses)
                }
                else {
                    setNotes(data.notes)
                }
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleDownload = async (note) => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/${purpose}/${note.id}/download`, {
                headers: {
                    "x-access-token": auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            // save file
            let filename = `MSSIS ${note.title}.${note.file_type}`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error("Failed to download file. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchLessonNotes()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>
                {form?.name} {subject?.name}&nbsp;
                {purpose === "notes" && "Notes"}
                {purpose === "books" && "TIE Books"}
                {purpose === "syllabus" && "Syllabus"}
            </p>

            {isLoading && <div className="text-center">Loading...<Loading /></div>}
            {!isLoading && notes.length < 1 && <div className="text-center">No lesson notes uploaded.</div>}

            {
                !isLoading &&
                <div className="row mx-0 mb-3">
                    {
                        notes?.map((note, index) => (
                            <div className='col-12 col-lg-3 shadow rounded-0 p-2 me-2 mb-2' key={index}>
                                <div className="mb-2">
                                    <span className="fw-bold">Title:</span> {note.title}
                                    {note.year && <><br /><span className="fw-bold">Year:</span> {note.year}</>}
                                    {note.author && <><br /><span className="fw-bold">Author:</span> {note.author}</>}
                                    {note.publisher && <><br /><span className="fw-bold">Publisher:</span> {note.publisher}</>}
                                </div>
                                <div className="d-flex flex-wrap align-items-center justify-content-end">
                                    <RoleOffice roles={["Admin"]} element={
                                        <Link
                                            to={`/teaching/learning-materials/${purpose}/${note.id}`}
                                            state={{ note }}
                                            className="btn btn-primary btn-sm rounded-0 me-2"
                                        >
                                            <span className="me-2">View</span>
                                            <i className="bi bi-eye-fill"></i>
                                        </Link>
                                    } />
                                    <button className="btn btn-primary btn-sm rounded-0" onClick={() => handleDownload(note)}>
                                        <span className="me-2">Download</span>
                                        <i className="bi bi-download"></i>
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default LessonNotesDownload
