import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { useLocation, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

function NewSubject({ isEditing }) {
    const auth = useAuth()
    const user = auth.user
    const params = useParams()
    const subjectId = params?.id
    const location = useLocation()
    const subject = location?.state?.subject

    const [isLoading, setIsLoading] = useState(false)

    const [name, setName] = useState(subject?.name || '')
    const [code, setCode] = useState(subject?.code || '')
    const [level, setLevel] = useState(subject?.level || '')
    const [abbreviation, setAbbreviation] = useState(subject?.abbreviation || '')
    const [order, setOrder] = useState(subject?.order || '')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/subjects/bare`
            let method = 'POST'

            if (isEditing) {
                url = `${BASE_API_URL}/subjects/${subjectId}`
                method = 'PUT'
            }

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    name,
                    code,
                    level,
                    abbreviation,
                    order
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        } catch (error) {
            toast.error("Failed to register subject. Please try again.")
        } finally {
            setIsLoading(false)
        }
    }

    const fetchSubject = async () => { }

    useEffect(() => {
        if (isEditing) {
            fetchSubject()
        }
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>
                {isEditing ? "Edit Subject" : "Register Subject"}
            </p>
            <div className='shadow p-2'>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='name'>Subject Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='name'
                                    placeholder='e.g Physics'
                                    required
                                    disabled={isLoading}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='abbreviation'>Subject Abbreviation</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='abbreviation'
                                    placeholder='e.g PHY'
                                    required
                                    disabled={isLoading}
                                    value={abbreviation}
                                    onChange={e => setAbbreviation(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='code'>Subject Code</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='code'
                                    placeholder='e.g 031'
                                    required
                                    disabled={isLoading}
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='order'>Subject Order</label>
                                <input
                                    type='number'
                                    className='form-control rounded-0'
                                    id='order'
                                    placeholder='e.g 1'
                                    required
                                    disabled={isLoading}
                                    value={order}
                                    onChange={e => setOrder(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='level'>Subject Level</label>
                                <select
                                    id="level"
                                    className='form-select rounded-0'
                                    required
                                    disabled={isLoading}
                                    value={level}
                                    onChange={e => setLevel(e.target.value)}
                                >
                                    <option value="">Choose subject level</option>
                                    <option value="PRIMARY">PRIMARY</option>
                                    <option value="ORDINARY">ORDINARY</option>
                                    <option value="ADVANCE">ADVANCE</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>
                                        {isEditing ? "Saving..." : "Registering..."} <Loading />
                                    </>
                                    :
                                    <>
                                        <span className="me-2">
                                            {isEditing ? "Save" : "Register"}
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewSubject
