import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import NewMembers from '../../components/subscription/NewMembers'
import toast from 'react-hot-toast'

function Subscription() {
    const auth = useAuth()
    const params = useParams()
    const subId = params.id
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [sub, setSub] = useState(location?.state?.subscription)

    const columns_payments = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Fee Type',
            selector: row => row.fee_type,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date_paid,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <div className='d-flex'>
                <button
                    type="button"
                    className={`btn btn-sm btn-danger rounded-0 disabled`}
                >
                    <span className="me-2">Delete</span>
                    <i className="bi bi-trash"></i>
                </button>
            </div>,
            sortable: false,
        }
    ]

    const columns_members = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Region',
            selector: row => row.region,
            sortable: true,
        },
        {
            name: 'District',
            selector: row => row.district,
            sortable: true,
        },
        {
            name: 'Ward',
            selector: row => row.ward,
            sortable: true,
        },
        {
            name: 'School',
            selector: row => row.name,
            sortable: true,
        }
    ]

    const fetchSubscription = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subscriptions/${subId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSub(data.subscription)
            }
        }
        catch (error) {
            toast.error('Failed to fetch subscription. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const terminateSubscription = async () => {
        setIsLoading(true)

        let status = "active"
        if (sub.status === "Active") {
            status = "suspended"
        }

        try {
            const response = await fetch(`${BASE_API_URL}/subscriptions/${subId}/${status}`, {
                headers: {
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
                fetchSubscription()
            }
        }
        catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubscription()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted mb-3'>Subscription: {sub?.name}</p>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <button
                        className='btn btn-warning btn-sm fw-bold rounded-0 me-2'
                        onClick={terminateSubscription}
                    >
                        {
                            sub?.status === "Active" ?
                                <>
                                    <span className="me-2">Suspend Service</span>
                                    <i className='bi bi-sign-stop fs-6'></i>
                                </>
                                :
                                <>
                                    <span className="me-2">Activate Service</span>
                                    <i className='bi bi-shield-check fs-6'></i>
                                </>
                        }
                    </button>
                    <Link
                        to={`record`}
                        className='btn btn-primary btn-sm fw-bold rounded-0'
                    >
                        <span className="me-2">Record Payment</span>
                        <i className='bi bi-cash-stack fs-6'></i>
                    </Link>
                </div>
            </div>

            <div className="p-2 shadow rounded-0 mb-3">
                <span className='fw-bold'>Entry Fee: </span>{sub?.entry_fee}
                <br />
                <span className='fw-bold'>Amount: </span>{sub?.amount}
                <br />
                <span className='fw-bold'>Date Started: </span>{sub?.date_started}
                <br />
                <span className='fw-bold'>Date Ended: </span>{sub?.date_ended}
                <br />
                <span className='fw-bold'>Service Status: </span>{sub?.status}
            </div>

            <div className="p-2 shadow rounded-0 mb-3">
                <Tabs>
                    <TabList>
                        <Tab>Members</Tab>
                        <Tab>Payments</Tab>
                        <Tab>Add Members</Tab>
                    </TabList>

                    <TabPanel>
                        <DataTable
                            columns={columns_members}
                            data={sub?.members}
                            progressPending={isLoading}
                            progressComponent={<div className="text-center">Loading...<Loading /></div>}
                            noDataComponent={"No subscription members available."}
                        />
                    </TabPanel>
                    <TabPanel>
                        <DataTable
                            columns={columns_payments}
                            data={sub?.payments}
                            progressPending={isLoading}
                            progressComponent={<div className="text-center">Loading...<Loading /></div>}
                            noDataComponent={"No subscription payments available."}
                        />
                    </TabPanel>
                    <TabPanel>
                        <NewMembers subscription={sub} />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default Subscription
