import React, { useEffect, useState } from 'react'
import { BASE_API_URL } from '../../utils/constants'
import { useAuth } from '../../context/AuthContext'
import DataTable from 'react-data-table-component'
import Loading from '../loading/Loading'
import toast from 'react-hot-toast'

function NewMembers({ subscription }) {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [schools, setSchools] = useState([])
    const [members, setMembers] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Region',
            selector: row => row.region,
            sortable: true,
        },
        {
            name: 'District',
            selector: row => row.district,
            sortable: true,
        },
        {
            name: 'Ward',
            selector: row => row.ward,
            sortable: true,
        },
        {
            name: 'School',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Level',
            selector: row => row.level,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <div className='d-flex'>
                <button
                    type="button"
                    className={`btn btn-sm btn-${!members.includes(row.id) ? 'outline-' : ''}primary rounded-0 me-2`}
                    onClick={() => {
                        if (!members.includes(row.id)) {
                            setMembers([...members, row.id])
                        }
                        else {
                            setMembers(members.filter(m => m !== row.id))
                        }
                    }}
                >
                    {members.includes(row.id) ? "Selected" : "Select"}
                </button>
            </div>,
            sortable: false,
        }
    ]

    const fetchSchools = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subscriptions/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            toast.error('Failed to fetch schools. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subscriptions/${subscription.id}/members`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({ members })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSchools()
    }, [])

    return (
        <div className='pt-2'>
            <p>Add new schools to this subscription.</p>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <DataTable
                        columns={columns}
                        data={schools}
                        progressPending={isLoading}
                        progressComponent={<div className="text-center">Loading...<Loading /></div>}
                        noDataComponent={"No schools available."}
                    />
                </div>
                <div className="text-end mb-3">
                    <button type='submit' className='btn btn-primary rounded-0' disabled={isLoading}>
                        {
                            isLoading ?
                                <>Registering... <Loading /></> :
                                <>
                                    <span className="me-2">
                                        Register
                                    </span>
                                    <i className='bi bi-floppy2-fill'></i>
                                </>
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default NewMembers
