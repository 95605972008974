import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import RoleOffice from '../../components/offices/RoleOffice'
import Loading from '../../components/loading/Loading'
import LevelSplit from '../../components/offices/LevelSplit'
import DataTable from 'react-data-table-component'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'
import toast from 'react-hot-toast'

function ViewCombination() {
    const auth = useAuth()
    const params = useParams()
    const combinationId = params.id

    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState({ form: "", sex: "" })
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [showDialog, setShowDialog] = useState(false)
    const [errors, setErrors] = useState([])
    const [forms, setForms] = useState([])

    const [combination, setCombination] = useState({})
    const [students, setStudents] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])
    const [totalStudents, setTotalStudents] = useState(0)
    const [toDelete, setToDelete] = useState({})

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (page - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'PREMS No',
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Admission No',
            selector: row => row.admission_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name:
                <LevelSplit
                    primary={null}
                    ordinary={
                        <div>
                            <input
                                type='checkbox'
                                className='me-1'
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedStudents(students.map(s => s.id))
                                    } else {
                                        setSelectedStudents([])
                                    }
                                }}
                            />
                            <span>({selectedStudents.length})</span>
                        </div>
                    }
                />,
            cell: row =>
                <LevelSplit
                    primary={null}
                    ordinary={
                        <div className='d-flex flex-wrap align-items-center'>
                            <button
                                className='btn btn-sm btn-danger rounded-0 me-1 mb-1'
                                onClick={() => {
                                    setToDelete(row)
                                    setShowDialog(true)
                                }}>
                                <i className='bi bi-trash-fill fs-6 mx-1'></i>
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm rounded-0 ${selectedStudents.includes(row.id) ? 'btn-primary' : 'btn-outline-primary'}`}
                                onClick={() => {
                                    if (selectedStudents.includes(row.id)) {
                                        setSelectedStudents(selectedStudents.filter(studentId => studentId !== row.id))
                                    } else {
                                        setSelectedStudents([...selectedStudents, row.id])
                                    }
                                }}>
                                {selectedStudents.includes(row.id) ? 'Selected' : 'Select'}
                            </button>
                        </div>
                    }
                />,
            sortable: false,
        }
    ]

    const fetchForms = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            toast.error('Failed to fetch forms. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchCombination = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/combinations`
            if (auth.user.role === "Admin") {
                url += `/bare`
            }
            url += `/${combinationId}`

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setCombination(data.combination)
            }
        }
        catch (error) {
            toast.error('Failed to fetch combination. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchCombinationStudents = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/combinations/${combinationId}/students`
            url += `?page=${page}&per_page=${perPage}&form=${filters?.form}&sex=${filters?.sex}`

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setStudents(data.students)
                setTotalStudents(data.total)
            }
        }
        catch (error) {
            toast.error('Failed to fetch combination students. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const deregisterStudent = async () => {
        setIsLoading(true)
        setErrors([])

        let studentsSubmit = []
        if (toDelete && toDelete.id) {
            studentsSubmit = [toDelete.id]
        }
        else {
            studentsSubmit = selectedStudents
        }

        if (studentsSubmit.length < 1) {
            toast.error("Select at least one student to deregister")
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/combinations/${combinationId}/students`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    "students": studentsSubmit
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setErrors(data.errors)
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
                setSelectedStudents([])
                fetchCombinationStudents()
            }
        }
        catch (error) {
            toast.error('Failed to deregister student. Please try again.')
        }
        finally {
            setToDelete({})
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchCombination()
        if (!["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
            fetchForms()
            fetchCombinationStudents()
        }
    }, [])

    useEffect(() => {
        if (!["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
            fetchCombinationStudents()
        }
    }, [page, perPage])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted mb-3'>Combination Details</p>
                <RoleOffice
                    roles={["Admin"]}
                    element={
                        <Link
                            to={`/academics/combinations/${combinationId}/edit`}
                            state={{ combination: combination }}
                            className='btn btn-primary btn-sm fw-bold rounded-0'
                        >
                            <span className="me-2">Edit</span>
                            <i className='bi bi-pencil-square'></i>
                        </Link>
                    }
                />
            </div>

            <div className="mb-3">
                <p className="fs-5">
                    <span className='fw-bold'>Name: </span>
                    <span className='text-muted'>
                        {combination?.name}
                    </span>
                    <br />
                    <span className='fw-bold'>Category: </span>
                    <span className='text-muted'>
                        {combination?.category}
                    </span>
                    <br />
                    <span className='fw-bold'>Subjects: </span>
                    {
                        combination?.subjects?.map((subject, index) => (
                            <span key={index} className='text-muted me-2'>
                                {subject.name},
                            </span>
                        ))
                    }
                </p>
            </div>

            <div className="mb-3">
                <p>
                    <span className='fw-bold'>
                        Enrolled Students:
                    </span>
                    <br />
                    <span className='text-muted fs-5'>
                        {
                            combination?.students && combination?.students?.length > 0 ?
                                combination?.students.map((student, index) => (
                                    <>
                                        <span key={index}>
                                            {student?.form?.name} - {student?.count}
                                        </span>
                                        <br />
                                    </>
                                ))
                                :
                                (
                                    "No students enrolled"
                                )
                        }
                    </span>
                </p>
            </div>

            <RoleOffice
                roles={["Academic Master", "Headmaster", "Second Master"]}
                element={
                    <>
                        <div className="mb-3 shadow p-2">
                            <p className='text-muted fw-bold'>
                                Filter Students
                            </p>
                            <form onSubmit={e => {
                                e.preventDefault()
                                fetchCombinationStudents()
                            }}>
                                <div className="row mx-0 ">
                                    <div className="col-12 col-lg-3 mb-2">
                                        <label htmlFor='form' className='form-label'>Form</label>
                                        <select
                                            id='form'
                                            className='form-select rounded-0'
                                            value={filters?.form}
                                            onChange={e => setFilters({ ...filters, form: e.target.value })}
                                        >
                                            <option value="">All Forms</option>
                                            {
                                                forms.filter(f => f.level === "ADVANCE").map(form =>
                                                    <option key={form.id} value={form.id}>{form.name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-12 col-lg-3 mb-2">
                                        <label htmlFor='sex' className='form-label'>Sex</label>
                                        <select
                                            id='sex'
                                            className='form-select rounded-0'
                                            value={filters?.sex}
                                            onChange={e => setFilters({ ...filters, sex: e.target.value })}
                                        >
                                            <option value="">All Sex</option>
                                            <option value="Female">Female</option>
                                            <option value="Male">Male</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                                        <button type='submit' className='btn btn-primary rounded-0 mt-4'>
                                            <span className="me-2">Filter</span>
                                            <i className='bi bi-funnel-fill'></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="shadow p-2 mb-4">
                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                <p className='h5 fw-bold text-muted'>
                                    Enrolled Students
                                </p>
                                <LevelSplit
                                    primary={null}
                                    ordinary={
                                        <Link
                                            to={`/academics/combinations/${combinationId}/enrol`}
                                            className='btn btn-primary btn-sm fw-bold rounded-0 px-2'
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            <span className="me-2 mb-2">
                                                Enrol Students
                                            </span>
                                            <i className='bi bi-person-fill-add'></i>
                                        </Link>
                                    }
                                />
                            </div>

                            <div className="mb-3">
                                {
                                    errors.map((error, index) => (
                                        <div className="alert alert-warning p-2 mb-1" key={index}>
                                            {error}
                                        </div>
                                    ))
                                }
                            </div>

                            <ConfirmationDialog
                                open={showDialog}
                                onClose={() => {
                                    setToDelete({})
                                    setShowDialog(false)
                                    setIsLoading(false)
                                }}
                                onConfirm={() => {
                                    deregisterStudent()
                                    setShowDialog(false)
                                }}
                                isLoading={isLoading}
                                title={"Deregister Student"}
                                message={
                                    toDelete && toDelete.id ?
                                        `Are you sure you want to deregister ${toDelete.firstname} ${toDelete.lastname} from this combination?` :
                                        `Are you sure you want to deregister ${selectedStudents.length} students from this combination?`
                                }
                            />

                            <DataTable
                                title={
                                    <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                        <span>{totalStudents} Enrolled Students</span>
                                        <button
                                            className='btn btn-sm btn-danger rounded-0'
                                            disabled={selectedStudents.length < 1}
                                            onClick={() => {
                                                setToDelete({})
                                                setShowDialog(true)
                                            }}
                                        >
                                            <span className="me-2">Delete Selected</span>
                                            <i className='bi bi-trash'></i>
                                        </button>
                                    </div>
                                }
                                columns={columns}
                                data={students}
                                progressPending={isLoading}
                                progressComponent={
                                    <div className="text-center">
                                        Loading... <Loading />
                                    </div>
                                }
                                pagination
                                paginationServer
                                paginationTotalRows={totalStudents}
                                paginationPerPage={perPage}
                                onChangePage={p => setPage(p)}
                                onChangeRowsPerPage={pp => setPerPage(pp)}
                                paginationRowsPerPageOptions={[10, 30, 50, 150]}
                                noDataComponent={"No students enrolled."}
                            />
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default ViewCombination
