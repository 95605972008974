import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import LevelSplit from '../../../components/offices/LevelSplit'
import Loading from '../../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { saveAs } from 'file-saver'
import toast from 'react-hot-toast'

function DisqualifiedStudents() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [students, setStudents] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'PREMS No',
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date_disqualified,
            sortable: true
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: false
        }
    ]

    const fetchStudents = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/students/disqualified`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setStudents(data.students)
            }
        }
        catch (error) {
            toast.error('Failed to fetch disqualified students. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleExport = async (exportType) => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/students/disqualified?format=${exportType}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }

            let extension = "pdf"
            if (exportType === "excel") {
                extension = "xlsx"
            }

            const filename = `${suff} Disqualified Students.${extension}`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error('Failed to export disqualified students. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStudents()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>
                Disqualified <LevelSplit primary={"Pupils"} ordinary={"Students"} />
            </p>

            <div className="mb-3 shadow rounded-0 p-2">
                <DataTable
                    title={
                        <div className='d-flex align-items-center justify-content-between'>
                            <span>
                                {students.length} Disqualified <LevelSplit primary={"Pupils"} ordinary={"Students"} />
                            </span>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <button className='btn btn-sm btn-primary rounded-0 me-2' onClick={e => handleExport("pdf")}>
                                        <span className="me-2">Export to PDF</span>
                                        <i className='bi bi-file-earmark-pdf'></i>
                                    </button>
                                    <button className='btn btn-sm btn-primary rounded-0' onClick={e => handleExport("excel")}>
                                        <span className="me-2">Export to Excel</span>
                                        <i className='bi bi-file-earmark-spreadsheet'></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    columns={columns}
                    data={students}
                    progressPending={isLoading}
                    progressComponent={
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    }
                    noDataComponent={<LevelSplit
                        primary={"No disqualified pupils found."}
                        ordinary={"No disqualified students found."}
                    />}
                />
            </div>
        </div>
    )
}

export default DisqualifiedStudents
