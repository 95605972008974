import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import LevelSplit from '../../components/offices/LevelSplit'
import toast from 'react-hot-toast'

function EnrolStudents({ purpose }) {
    const auth = useAuth()
    const params = useParams()
    const subjectId = params.id

    const [isLoading, setIsLoading] = useState(true)
    const [errors, setErrors] = useState([])

    const [forms, setForms] = useState([])
    const [form, setForm] = useState("")
    const [students, setStudents] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])

    const students_columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'PREMS No',
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Admission No',
            selector: row => row.admission_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name:
                <div>
                    <input
                        type='checkbox'
                        className='me-1'
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedStudents(students.map(subject => subject.id))
                            } else {
                                setSelectedStudents([])
                            }
                        }}
                    />
                    <span>({selectedStudents.length})</span>
                </div>,
            cell: row => <button
                type='button'
                className={`btn btn-sm rounded-0 ${selectedStudents.includes(row.id) ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => {
                    if (selectedStudents.includes(row.id)) {
                        setSelectedStudents(selectedStudents.filter(subjectId => subjectId !== row.id))
                    } else {
                        setSelectedStudents([...selectedStudents, row.id])
                    }
                }}>
                {selectedStudents.includes(row.id) ? 'Selected' : 'Select'}
            </button>,
            sortable: false,
        }
    ]

    const fetchForms = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            toast.error('Failed to fetch forms. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchStudents = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            // per_page=10000 an arbitrary large number to fetch all students of the selected form
            const response = await fetch(`${BASE_API_URL}/students?page=1&per_page=10000&form=${form}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setStudents(data.students)
            }
        }
        catch (error) {
            toast.error('Failed to fetch students. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleEnrol = async () => {
        setIsLoading(true)
        setErrors([])

        if (selectedStudents.length < 1) {
            toast.error("Select at least one student to enrol")
            setIsLoading(false)
            return
        }

        try {
            let url = `${BASE_API_URL}/subjects/${subjectId}/students`
            if (purpose === "combinations") {
                url = `${BASE_API_URL}/combinations/${subjectId}/students`
            }

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    "form": form,
                    "students": selectedStudents
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to enrol students. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchForms()
    }, [])

    return (
        <div className='pt-2'>
            <p className='mb-3'>
                <span className="h3 fw-bold text-muted">
                    Enrol <LevelSplit primary={"Pupils"} ordinary={"Students"} />
                </span>
                <br />
                <span>
                    Select class/form then select <LevelSplit primary={"pupils"} ordinary={"students"} /> that are to be enrolled to this
                    {purpose === "combinations" ? " combination" : " subject"}.
                </span>
            </p>

            <div className="shadow p-2 mb-3">
                <form className="d-flex align-items-center" onSubmit={fetchStudents}>
                    <select
                        className="form-select rounded-0 me-1"
                        id="class"
                        name="class"
                        required
                        value={form}
                        onChange={e => setForm(e.target.value)}
                    >
                        <option value="">Select class/form</option>
                        {
                            forms.filter(f => purpose !== "combinations" || f.level === "ADVANCE").map(form => (
                                <option key={form.id} value={form.id}>{form.name}</option>
                            ))
                        }
                    </select>
                    <button type='submit' className="btn btn-primary px-3 fw-bold rounded-0">
                        Fetch
                    </button>
                </form>
            </div>

            <div className="mb-3">
                {
                    errors && errors.map((error, index) => (
                        <div className="alert alert-warning p-2 mb-1" key={index}>
                            {error}
                        </div>
                    ))
                }
            </div>

            <div className="shadow p-2 mb-3">
                <DataTable
                    title={
                        <div className='d-flex align-items-center justify-content-between'>
                            <span>{students.length} <LevelSplit primary={"Pupils"} ordinary={"Students"} /></span>
                            <button className='btn btn-primary rounded-0 fw-bold' onClick={handleEnrol}>
                                <span className="me-2">Enrol</span>
                                <i className='bi bi-person-fill-add'></i>
                            </button>
                        </div>
                    }
                    columns={students_columns}
                    data={students}
                    progressPending={isLoading}
                    progressComponent={
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    }
                    noDataComponent={<LevelSplit primary={"No pupils found."} ordinary={"No students found."} />}
                />
                <div className="text-end my-3">
                    <button className='btn btn-primary rounded-0 fw-bold' onClick={handleEnrol}>
                        <span className="me-2">Enrol</span>
                        <i className='bi bi-person-fill-add'></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EnrolStudents
