import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import toast from 'react-hot-toast'

function NewSubjectTopic() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    const [periods, setPeriods] = useState("")
    const [order, setOrder] = useState("")
    const [subtopics, setSubtopics] = useState([])
    const [forms, setForms] = useState([])
    const [subjects, setSubjects] = useState([])

    const [selectedForm, setSelectedForm] = useState("")
    const [selectedSubject, setSelectedSubject] = useState("")

    const fetchForms = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes/bare`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            toast.error('Failed to fetch forms. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            toast.error('Failed to fetch subjects. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const addSubTopic = () => {
        setSubtopics([...subtopics, ''])
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/topic`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    selectedForm,
                    selectedSubject,
                    name,
                    periods,
                    order,
                    subtopics
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }

        }
        catch (error) {
            toast.error("Failed to register topic. Please try again later.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchForms()
        fetchSubjects()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>New Subject Topic</p>

            <div className='shadow p-2 mb-3'>
                <form onSubmit={handleSubmit} className='mb-3'>
                    <div className="row mb-3">
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='form' className='form-label'>Form</label>
                            <select
                                id='form'
                                className='form-select rounded-0'
                                required
                                value={selectedForm}
                                onChange={e => setSelectedForm(e.target.value)}
                            >
                                <option>Select Form</option>
                                {
                                    forms.map(form =>
                                        <option key={form.id} value={form.id}>{form.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='subject' className='form-label'>Subject</label>
                            <select
                                id='subject'
                                className='form-select rounded-0'
                                required
                                value={selectedSubject}
                                onChange={e => setSelectedSubject(e.target.value)}
                            >
                                <option>Select Subject</option>
                                {
                                    subjects.map(subject =>
                                        <option key={subject.id} value={subject.id}>
                                            {subject.name} - {subject.level}
                                        </option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="name" className="form-label">Topic Name</label>
                        <input
                            type="text"
                            id="name"
                            className="form-control rounded-0"
                            placeholder="e.g Introduction to Biology"
                            required
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="periods" className="form-label">Periods to Cover the Topic</label>
                        <input
                            type="number"
                            id="periods"
                            className="form-control rounded-0"
                            placeholder="e.g 6"
                            required
                            value={periods}
                            onChange={e => setPeriods(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="order" className="form-label">Topic Number</label>
                        <input
                            type="number"
                            id="order"
                            className="form-control rounded-0"
                            placeholder="e.g 1"
                            required
                            value={order}
                            onChange={e => setOrder(e.target.value)}
                        />
                    </div>
                    {
                        subtopics && subtopics.length > 0 &&
                        <>
                            <div className="mb-1 text-info">
                                In case you add a subtopic that you dont want to fill it's value, just ignore it.&nbsp;
                                Empty subtopics will be ignored by the system when you submit.
                            </div>
                            <div className='mb-3'>
                                {
                                    subtopics?.map((subtopic, index) => (
                                        <div className="mb-3">
                                            <label htmlFor={`subtopic${index}`} className="form-label">Sub Topic {index + 1}</label>
                                            <input
                                                type="text"
                                                id={`subtopic${index}`}
                                                className="form-control rounded-0"
                                                value={subtopics[index]}
                                                onChange={e => {
                                                    const newSubtopicValues = [...subtopics]
                                                    newSubtopicValues[index] = e.target.value
                                                    setSubtopics(newSubtopicValues)
                                                }}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }
                    
                    <div className="me-0 ms-auto text-end mt-4">
                        <button
                            type='button'
                            className='btn btn-secondary rounded-0 me-2'
                            disabled={isLoading}
                            onClick={addSubTopic}
                        >
                            <span className="me-2">Add Sub Topic</span>
                            <i className='bi bi-plus-square'></i>
                        </button>
                        <button
                            type='submit'
                            className='btn btn-primary rounded-0'
                            disabled={isLoading}
                        >
                            <span className="me-2">Register</span>
                            <i className='bi bi-floppy2'></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewSubjectTopic
