import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import LevelSplit from '../../components/offices/LevelSplit'
import toast from 'react-hot-toast'

function HeadmasterSchool() {
    const auth = useAuth()
    const location = useLocation()
    const school = location.state.school

    const toastId = "HeadmasterSchool"
    const [isLoading, setIsLoading] = useState(false)

    const [staffList, setStaffList] = useState([])
    const [staffListResult, setStaffListResult] = useState([])
    const [headmaster, setHeadmaster] = useState("")

    const fetchStaff = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/employees/unallocated`

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                setStaffList(data.employees)
                setStaffListResult(data.employees)
            }
        }
        catch (error) {
            toast.error('Failed to fetch staff. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSearch = async (value) => {
        if (value.length < 4) {
            setStaffListResult(staffList)
        }
        else {
            try {
                const response = await fetch(`${BASE_API_URL}/employees/search?q=${value}`, {
                    headers: {
                        'x-access-token': auth.token
                    }
                })
                const data = await response.json()

                if (!response.ok) {
                    toast.error(data.message, {id: toastId})
                }
                else {
                    setStaffListResult(data.employees)
                }
            }
            catch (error) {
                toast.error('Failed to search staff. Please try again.', {id: toastId})
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/${school.id}/headmaster`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ headmaster })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                toast.success(data.message, {id: toastId})
            }
        }
        catch (error) {
            toast.error('Failed to assign school headmaster. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStaff()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>
                Assign School <LevelSplit primary={"Head Teacher"} ordinary={"Headmaster"} />
            </p>

            <div className="mb-4">
                <p>
                    <span className='fw-bold me-2'>Name:</span>
                    <span className='text-muted fs-5'>
                        {school.name} <LevelSplit primary={"Primary"} ordinary={"Secondary"} /> School
                    </span>
                </p>
                <p>
                    <span className='fw-bold me-2'>Registration Number:</span>
                    <span className='text-muted fs-5'>
                        {school.reg_no}
                    </span>
                </p>
                <p>
                    <span className='fw-bold me-2'>Current Headmaster:</span>
                    {
                        school.headmaster ?
                            <Link to={`/staffs/${school.headmaster.id}`} className='fs-5'>
                                {school.headmaster.firstname} {school.headmaster.lastname}
                            </Link>
                            :
                            <span className='text-danger'>None Assigned</span>
                    }
                </p>
            </div>

            {
                isLoading ?
                    <div className="text-center">
                        <Loading />
                    </div>
                    :
                    (
                        <div className='mb-4'>
                            <p className="h4 fw-bold text-muted mb-1">
                                Select a staff member to assign as <LevelSplit primary={"head teacher"} ordinary={"headmaster"} />
                            </p>
                            {/* search for staff members */}
                            <div className="body shadow p-2 mb-3 d-flex">
                                <input
                                    type="text"
                                    className="form-control me-1 rounded-0"
                                    placeholder="Search staff..."
                                    onChange={e => handleSearch(e.target.value)}
                                />
                                <button className="btn btn-sm btn-primary px-3 fw-bold rounded-0">
                                    Search
                                </button>
                            </div>

                            {/* actual form to select staff */}
                            <form onSubmit={handleSubmit}>
                                <div className="row mx-1">
                                    {
                                        staffListResult && staffListResult.map((staff, index) => (
                                            <div key={index} className="col-12 col-lg-auto m-2 p-2 border rounded-0">
                                                <input
                                                    type="radio"
                                                    name="headmaster"
                                                    id={staff.id}
                                                    className='form-radio'
                                                    value={staff.id}
                                                    onChange={e => setHeadmaster(e.target.value)}
                                                />
                                                <label htmlFor={staff.id} className='ms-2 form-label'>
                                                    {staff.firstname} {staff.lastname}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="text-end">
                                    <button type="submit" className='btn btn-primary fw-bold rounded-0 px-4'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    )
            }
        </div>
    )
}

export default HeadmasterSchool
