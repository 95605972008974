import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import toast from 'react-hot-toast'

function EditGrades() {
    const auth = useAuth()
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [grades, setGrades] = useState(location.state.grades ? location.state.grades : {})
    const [level, setLevel] = useState(location.state.level ? location.state.level : auth.user.level)

    const validateGrades = () => {
        if (grades.length < 3) {
            toast.error('Please add at least three grades.')
            return false
        }

        for (let i = 0; i < grades.length; i++) {
            // check that no two grades have the same minimum score
            for (let j = i + 1; j < grades.length; j++) {
                if (grades[i].min_score === grades[j].min_score) {
                    toast.error('Two grades cannot have the same minimum score.')
                    return false
                }
            }

            // check that the minimum score is a number
            if (isNaN(grades[i].min_score)) {
                toast.error('Minimum score must be a number.')
                return false
            }

            // check that the minimum score is not negative or greater than 100
            if (grades[i].min_score < 0 || grades[i].min_score > 100) {
                toast.error('Minimum score cannot be negative or greater than 100.')
                return false
            }

            return true
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        if (!validateGrades()) {
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/grades`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ grades, level })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        } catch (error) {
            toast.error('Failed to update grades. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Edit Grading System</p>

            <form onSubmit={handleUpdate}>
                {
                    grades.map((grade, index) => (
                        <div className='row mx-0 mb-3 border p-1' key={index}>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='grade' className='form-label'>Grade</label>
                                    <input
                                        type='text'
                                        id='grade'
                                        className='form-control rounded-0'
                                        placeholder='A'
                                        required
                                        value={grade.grade}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].grade = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='score' className='form-label'>Minimum Score</label>
                                    <input
                                        type='text'
                                        id='score'
                                        className='form-control rounded-0'
                                        placeholder='80'
                                        required
                                        value={grade.min_score}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].min_score = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='points' className='form-label'>Points</label>
                                    <input
                                        type='text'
                                        id='points'
                                        className='form-control rounded-0'
                                        placeholder='1'
                                        required
                                        value={grade.points}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].points = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='remark' className='form-label'>Remark</label>
                                    <input
                                        type='text'
                                        id='remark'
                                        className='form-control rounded-0'
                                        placeholder='Excellent'
                                        required
                                        value={grade.remarks}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].remarks = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-2 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='indicator' className='form-label'>
                                        <span className='me-2'>Indicator</span>
                                        <span className={`badge bg-${grade.indicator} rounded-0`}>{grade.remarks}</span>
                                    </label>
                                    <select
                                        className='form-select rounded-0'
                                        id='indicator'
                                        required
                                        value={grade.indicator}
                                        onChange={e => {
                                            const newGrades = [...grades]
                                            newGrades[index].indicator = e.target.value
                                            setGrades(newGrades)
                                        }}
                                    >
                                        <option value='success'>Green</option>
                                        <option value='primary'>Blue</option>
                                        <option value='info'>Light Blue</option>
                                        <option value='warning'>Yellow</option>
                                        <option value='danger'>Red</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    ))
                }

                <div className="text-end mb-3">
                    <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                        {
                            isLoading ?
                                <>Updating... <Loading /></> :
                                <>
                                    <span className="me-2">
                                        Update
                                    </span>
                                    <i className='bi bi-floppy2-fill'></i>
                                </>
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditGrades
