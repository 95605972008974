import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { BASE_API_URL } from '../../utils/constants'
import { saveAs } from 'file-saver'
import Loading from '../../components/loading/Loading'
import LevelSplit from '../../components/offices/LevelSplit'
import ResultsTitle from '../../components/results/ResultsTitle'
import toast from 'react-hot-toast'

function WardRanking() {
    const auth = useAuth()
    const location = useLocation()
    const exam = location.state.exam
    const form = location.state.form

    const contentRef = useRef()
    const [isLoading, setIsLoading] = useState(true)
    const [results, setResults] = useState({})

    const divisions_ordinary = [
        {
            title: "DIV I",
            key: "I"
        },
        {
            title: "DIV II",
            key: "II"
        },
        {
            title: "DIV III",
            key: "III"
        },
        {
            title: "DIV IV",
            key: "IV"
        },
        {
            title: "DIV 0",
            key: "0"
        },
        {
            title: "INCOMPLETES",
            key: "incomplete"
        },
        {
            title: "ABSENTEES",
            key: "absent"
        }
    ]

    const divisions_primary = [
        {
            title: "A",
            key: "A"
        },
        {
            title: "B",
            key: "B"
        },
        {
            title: "C",
            key: "C"
        },
        {
            title: "D",
            key: "D"
        },
        {
            title: "E",
            key: "E"
        },
        {
            title: "ABSENTEES",
            key: "absent"
        }
    ]

    const divisions = auth.user.level === "PRIMARY" ? divisions_primary : divisions_ordinary

    const handlePrint = useReactToPrint({
        documentTitle: `MSSIS-${exam.name}-Schools Ranking`,
        removeAfterPrint: true,
    })

    const handleExport = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/results/external/export/${exam.id}/${form.id ? form.id : form}/ward-rank`
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            const filename = response.headers.get("x-filename")
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error("Failed to export results to PDF. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchResults = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/results/analysis/${exam.id}/${form.id ? form.id : form}/ward-rank`
            if (exam.type === "External") {
                url = `${BASE_API_URL}/results/external/analysis/${exam.id}/${form.id ? form.id : form}/ward-rank`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setResults(data)
            }
        }
        catch (error) {
            toast.error('Failed to fetch exam results. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchResults()
    }, [])

    return (
        <div className='pt-2'>
            <div className="mb-3 text-end">
                <button
                    className='btn btn-sm btn-primary rounded-0'
                    disabled={isLoading}
                    onClick={() => {
                        // handlePrint(null, () => contentRef.current)
                        handleExport()
                    }}
                >
                    <span className="me-2">Export to PDF</span>
                    <i className='bi bi-file-earmark-pdf'></i>
                </button>
            </div>

            {
                isLoading ?
                    (
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <div className="body shadow p-2 mb-3" id="printContent" ref={contentRef}>
                            <div className="mb-3">
                                <ResultsTitle
                                    exam={exam}
                                    form={form}
                                    school={results.school}
                                    title={"OVERALL WARDS RANKING"}
                                />
                            </div>
                            <div className="mb-3">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover">
                                        <thead>
                                            <tr className='align-middle'>
                                                <th rowSpan={3}>S/N</th>
                                                <th rowSpan={3}>WARD</th>
                                                <th rowSpan={2} colSpan={3}>CANDIDATES SAT</th>
                                                <th colSpan={3 * divisions.length}>RESULTS SUMMARY</th>
                                                <th rowSpan={3}>
                                                    <LevelSplit
                                                        primary={"PASSING % (A - C)"}
                                                        ordinary={"PASSING % (DIV I - DIV IV)"}
                                                    />
                                                </th>
                                                <th rowSpan={3}>AVERAGE</th>
                                                <th rowSpan={3}>GRADE</th>
                                                <LevelSplit
                                                    primary={null}
                                                    ordinary={
                                                        <th rowSpan={3}>GPA</th>
                                                    }
                                                />
                                                <th rowSpan={3} className='vertical-text'>POSITION</th>
                                            </tr>
                                            <tr className='align-middle'>
                                                {
                                                    divisions.map((div, index) => (
                                                        <th className='nowrap' colSpan={3} key={index}>{div.title}</th>
                                                    ))
                                                }
                                            </tr>
                                            <tr className='align-middle'>
                                                {
                                                    [...Array(divisions.length + 1)].map((_, index) => (
                                                        <Fragment key={index}>
                                                            <th>F</th>
                                                            <th>M</th>
                                                            <th>T</th>
                                                        </Fragment>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                results.ranks && results.ranks.map((school, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td className='text-start'>{school.name?.toUpperCase()}</td>
                                                        <td>{school.candidates && school.candidates["F"]}</td>
                                                        <td>{school.candidates && school.candidates["M"]}</td>
                                                        <td>{school.candidates && school.candidates["T"]}</td>
                                                        {
                                                            divisions.map((div, i) => (
                                                                <Fragment key={i}>
                                                                    <td>{school[div.key] && school[div.key]["F"]}</td>
                                                                    <td>{school[div.key] && school[div.key]["M"]}</td>
                                                                    <td>{school[div.key] && school[div.key]["T"]}</td>
                                                                </Fragment>
                                                            ))
                                                        }
                                                        <td>{school.percent}</td>
                                                        <td>{school.passing_average}</td>
                                                        <td>{school.grade}</td>
                                                        <LevelSplit
                                                            primary={null}
                                                            ordinary={
                                                                <td>{school.gpa}</td>
                                                            }
                                                        />
                                                        <td>{school.position}/{results.ranks.length}</td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                results.bottom_summary &&
                                                <tr className="fw-bold">
                                                    <td colSpan={2} className='bg-info'>GRAND TOTAL</td>
                                                    <td className='bg-info'>{results.bottom_summary["candidates"]["F"]}</td>
                                                    <td className='bg-info'>{results.bottom_summary["candidates"]["M"]}</td>
                                                    <td className='bg-info'>{results.bottom_summary["candidates"]["T"]}</td>
                                                    {
                                                        divisions.map((div, i) => (
                                                            <Fragment key={i}>
                                                                <td className='bg-info'>
                                                                    {
                                                                        results?.bottom_summary[div.key] && results?.bottom_summary[div.key]["F"]
                                                                    }
                                                                </td>
                                                                <td className='bg-info'>
                                                                    {
                                                                        results?.bottom_summary[div.key] && results?.bottom_summary[div.key]["M"]
                                                                    }
                                                                </td>
                                                                <td className='bg-info'>
                                                                    {
                                                                        results?.bottom_summary[div.key] && results?.bottom_summary[div.key]["T"]
                                                                    }
                                                                </td>
                                                            </Fragment>
                                                        ))
                                                    }
                                                    <td className='bg-info'>{results.bottom_summary["percent"]}</td>
                                                    <td className='bg-info'>{results.bottom_summary["passing_average"]}</td>
                                                    <td className='bg-info'>{results.bottom_summary["grade"]}</td>
                                                    <LevelSplit
                                                        primary={null}
                                                        ordinary={
                                                            <td className='bg-info'>
                                                                {results.bottom_summary["gpa"]}
                                                            </td>
                                                        }
                                                    />
                                                    <td className='bg-info'></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default WardRanking
