import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import RoleOffice from '../../components/offices/RoleOffice'
import toast from 'react-hot-toast'

function StaffTransfer() {
    const auth = useAuth()

    const toastId = "StaffTransfer"
    const [isLoading, setIsLoading] = useState(false)

    const [filterStatus, setFilterStatus] = useState("")
    const [transfers, setTransfers] = useState([])

    const columns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Staff",
            selector: row => row.staff,
            grow: 2
        },
        {
            name: "School",
            selector: row => row.school
        },
        {
            name: "Date Requested",
            selector: row => row.date_requested
        },
        {
            name: "Status",
            selector: row => row.status
        },
        {
            name: "Date Approved",
            selector: row => row.date_approved
        },
        {
            name: "Action",
            cell: row => <div className='d-flex'>
                <RoleOffice
                    roles={["Headmaster", "Second Master"]}
                    reverse={true}
                    element={
                        <>
                            <button
                                className='btn btn-sm btn-danger rounded-0 me-2'
                                disabled={row.status !== "Pending" || isLoading}
                                onClick={() => approveTransfer(row.id, "rejected")}
                            >
                                <i className='bi bi-x-lg fs-6 mx-1'></i>
                            </button>
                            <button
                                className='btn btn-sm btn-primary rounded-0'
                                disabled={row.status !== "Pending" || isLoading}
                                onClick={() => approveTransfer(row.id, "approved")}
                            >
                                <i className='bi bi-check-lg fs-6 mx-1'></i>
                            </button>
                        </>
                    }
                />
            </div>
        }
    ]

    const fetchTransfers = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/transfer/staffs?status=${filterStatus}`
            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                setTransfers(data.transfers)
            }
        }
        catch (error) {
            toast.error('Failed to fetch transfers. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    const approveTransfer = async (transferId, status) => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/transfer/staff/approve/${transferId}/${status}`
            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                toast.success(data.message, {id: toastId})
            }
        }
        catch (error) {
            toast.error('Failed to approve transfer. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTransfers()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>
                Staff Transfer
            </p>

            <div className="my-3 shadow p-2">
                <p className='text-muted fw-bold'>
                    Filter Transfer Requests
                </p>
                <form onSubmit={e => {
                    e.preventDefault()
                    fetchTransfers()
                }}>
                    <div className="row mx-0 ">
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='status' className='form-label'>Status</label>
                            <select
                                id='status'
                                className='form-select rounded-0'
                                value={filterStatus}
                                onChange={e => setFilterStatus(e.target.value)}
                            >
                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                            <button type='submit' className='btn btn-primary rounded-0 mt-4'>
                                <span className="me-2">Filter</span>
                                <i className='bi bi-funnel-fill'></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="my-3 shadow p-2">
                <DataTable
                    columns={columns}
                    data={transfers}
                    progressPending={isLoading}
                    progressComponent={<>Loading...<Loading /></>}
                />
            </div>
        </div>
    )
}

export default StaffTransfer
