import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import toast from 'react-hot-toast'

const LESSON_NOTES_TYPE = [
    "Notes", "Novel", "Play", "Poem", "Tamthilia", "Riwaya", "Ushairi", "Practical Handout",
    "English Orientation Book", "Map Sheet", "Photographs"
]

function NewLessonNotes({ purpose }) {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [classes, setClasses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [lessonNote, setLessonNote] = useState({
        "title": "", "author": "", "publisher": "", "year": "", "type": "",
        "form": "", "subject": "", "hidden": "", "file": ""
    })

    const fetchClasses = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes/bare`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setClasses(data.classes)
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            toast.error('Failed to fetch subjects. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            let formData = new FormData()
            formData.append("title", lessonNote["title"])
            formData.append("author", lessonNote["author"])
            formData.append("publisher", lessonNote["publisher"])
            formData.append("year", lessonNote["year"])
            formData.append("type", lessonNote["type"])
            formData.append("form", lessonNote["form"])
            formData.append("subject", lessonNote["subject"])
            formData.append("hidden", lessonNote["hidden"])
            formData.append("file", lessonNote["file"])

            const response = await fetch(`${BASE_API_URL}/${purpose}`, {
                method: "POST",
                headers: {
                    "x-access-token": auth.token
                },
                body: formData
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to upload. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchClasses()
        fetchSubjects()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h4 fw-bold text-muted mb-3'>
                {purpose === "notes" && "New Lesson Notes"}
                {purpose === "books" && "New TIE Book"}
                {purpose === "syllabus" && "New Syllabuses"}
            </p>

            <div className='shadow p-2 mb-3'>
                <form onSubmit={handleUpload} encType="multipart/form-data">
                    <div className="form-group mb-3">
                        <label htmlFor="title" className="form-label">
                            Title <strong className='text-danger ms-1'>*</strong>
                        </label>
                        <input
                            id="title"
                            type="text"
                            className="form-control rounded-0"
                            placeholder="e.g Respiration"
                            required
                            value={lessonNote["title"]}
                            onChange={e => setLessonNote({ ...lessonNote, "title": e.target.value })}
                        />
                    </div>
                    {
                        (purpose === "books" || purpose === "syllabus") &&
                        <>
                            <div className="form-group mb-3">
                                <label htmlFor="author" className="form-label">
                                    Author
                                </label>
                                <input
                                    id="author"
                                    type="text"
                                    className="form-control rounded-0"
                                    placeholder="e.g Nyambari Nyangwine"
                                    value={lessonNote["author"]}
                                    onChange={e => setLessonNote({ ...lessonNote, "author": e.target.value })}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="publisher" className="form-label">
                                    Publisher
                                </label>
                                <input
                                    id="publisher"
                                    type="text"
                                    className="form-control rounded-0"
                                    placeholder="e.g Mkuki na Nyota"
                                    value={lessonNote["publisher"]}
                                    onChange={e => setLessonNote({ ...lessonNote, "publisher": e.target.value })}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="year" className="form-label">
                                    Year
                                </label>
                                <input
                                    id="year"
                                    type="number"
                                    min={1900}
                                    className="form-control rounded-0"
                                    placeholder="e.g 2020"
                                    value={lessonNote["year"]}
                                    onChange={e => setLessonNote({ ...lessonNote, "year": e.target.value })}
                                />
                            </div>
                        </>
                    }
                    {
                        purpose === "notes" &&
                        <div className="form-group mb-3">
                            <label htmlFor="type" className="form-label">
                                Notes Type<strong className='text-danger ms-1'>*</strong>
                            </label>
                            <select
                                id="type"
                                className="form-select rounded-0"
                                required
                                value={lessonNote["type"]}
                                onChange={e => setLessonNote({ ...lessonNote, "type": e.target.value })}
                            >
                                <option value="">Select lesson type</option>
                                {
                                    LESSON_NOTES_TYPE.map((note, index) => (
                                        <option value={note} key={index}>{note}</option>
                                    ))
                                }
                            </select>
                        </div>
                    }
                    {
                        purpose !== "syllabus" &&
                        <div className="form-group mb-3">
                            <label htmlFor="form" className="form-label">
                                Class/Form <strong className='text-danger ms-1'>*</strong>
                            </label>
                            <select
                                id="form"
                                className="form-select rounded-0"
                                required
                                value={lessonNote["form"]}
                                onChange={e => setLessonNote({ ...lessonNote, "form": e.target.value })}
                            >
                                <option value="">Select class/form</option>
                                {
                                    classes.map((cls, index) => (
                                        <option value={cls.id} key={index}>{cls.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    }
                    <div className="form-group mb-3">
                        <label htmlFor="subject" className="form-label">
                            Subject <strong className='text-danger ms-1'>*</strong>
                        </label>
                        <select
                            id="subject"
                            className="form-select rounded-0"
                            required
                            value={lessonNote["subject"]}
                            onChange={e => setLessonNote({ ...lessonNote, "subject": e.target.value })}
                        >
                            <option value="">Select subject</option>
                            {
                                subjects.map((subject, index) => (
                                    <option value={subject.id} key={index}>{subject.name} - {subject.level}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <div className="d-flex flex-align-items-center">
                            <span className="me-2">
                                Hidden: ({lessonNote["hidden"] ? "Yes" : "No"})
                            </span>
                            <input
                                type="checkbox"
                                id="hidden"
                                className="form-check rounded-0"
                                checked={lessonNote["hidden"]}
                                onChange={e => setLessonNote({ ...lessonNote, "hidden": !lessonNote["hidden"] })}
                            />
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="file" className="form-label">
                            File <strong className='text-danger ms-1'>*</strong>
                        </label>
                        <input
                            type="file"
                            id="file"
                            className="form-control rounded-0"
                            required
                            accept='.pdf, .doc, .docx'
                            onChange={e => setLessonNote({ ...lessonNote, "file": e.target.files[0] })}
                        />
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <span>
                            <strong className='text-danger ms-1'>*</strong> indicates required fields
                        </span>
                        <button type='submit' className='btn btn-primary fw-bold rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Uploading... <Loading /></> :
                                    <>
                                        <span className="me-2">Upload</span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export { NewLessonNotes, LESSON_NOTES_TYPE }
