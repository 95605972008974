import React, { useEffect, useState } from 'react'
import RoleOffice from '../../components/offices/RoleOffice'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable, { SortOrder } from 'react-data-table-component'
import LevelSplit from '../../components/offices/LevelSplit'
import toast from 'react-hot-toast'

function Announcements() {
	const auth = useAuth()

	const [isLoading, setIsLoading] = useState(false)
	const [content, setContent] = useState("")
	const [type, setType] = useState("")
	const [category, setCategory] = useState("")
	const [charsInSMS, setCharsInSMS] = useState(0)

	const [forms, setForms] = useState([])
	const [staffs, setStaffs] = useState([])
	const [recepients, setRecepients] = useState([])

	const columns = [
		{
			name: "S/N",
			selector: (row, index) => index + 1,
			grow: 0
		},
		{
			name: "Name",
			selector: row => `${row.firstname} ${row.lastname}`,
			sortable: true
		},
		{
			name: "Phone",
			selector: row => row.phone,
			sortable: false
		},
		{
			name: 'Station',
			selector: row => row.station,
			sortable: true,
		},
		{
			name: <div>
				<input
					type='checkbox'
					className='me-1'
					onChange={(e) => {
						if (e.target.checked) {
							setRecepients(staffs.map(s => s.id))
						} else {
							setRecepients([])
						}
					}}
				/>
				<span>({recepients.length})</span>
			</div>,
			cell: row => <div>
				<button
					type='button'
					className={`btn btn-sm rounded-0 ${recepients.includes(row.id) ? 'btn-primary' : 'btn-outline-primary'}`}
					onClick={() => {
						if (recepients.includes(row.id)) {
							setRecepients(recepients.filter(id => id !== row.id))
						} else {
							setRecepients([...recepients, row.id])
						}
					}}>
					{recepients.includes(row.id) ? 'Selected' : 'Select'}
				</button>
			</div>,
			sortable: false
		}
	]

	const sendMessage = async (e) => {
		e.preventDefault()
		setIsLoading(true)

		if (["Headmaster", "Second Master", "Academic Master", "Teacher", "Temporary Teacher"].includes(category)) {
			if (recepients.length < 1) {
				toast.error("Please select at least 1 recepient.")
				setIsLoading(false)
				return
			}
		}

		try {
			const response = await fetch(`${BASE_API_URL}/sms/announcements/${type}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": auth.token
				},
				body: JSON.stringify({
					"message": content,
					"category": category,
					"recepients": recepients
				})
			})

			const data = await response.json()
			if (!response.ok) {
				toast.error(data.message)
			}
			else {
				toast.success(data.message)
			}
		}
		catch (error) {
			toast.error("Failed to send SMS. Please try again.")
		}
		finally {
			setIsLoading(false)
		}
	}

	const fetchSmsDetails = async () => {
		setIsLoading(true)

		try {
			const response = await fetch(`${BASE_API_URL}/sms/details`, {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				toast.error(data.message)
			}
			else {
				setCharsInSMS(data.chars_in_sms)
			}
		}
		catch (error) {
			toast.error("Failed to fetch SMS details. Please try again.")
		}
		finally {
			setIsLoading(false)
		}
	}

	const fetchStaffs = async () => {
		if (!category || category === "all") {
			setRecepients([])
			return
		}

		setIsLoading(true)

		try {
			let url = `${BASE_API_URL}/employees?per_page=10000&designation=${category}`
			if (category === "AEK") {
				url = `${BASE_API_URL}/employees/dseo?station=${category}`
			}

			const response = await fetch(url, {
				headers: {
					"Content-Type": "application/json",
					"x-access-token": auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				toast.error(data.message)
			}
			else {
				setStaffs(data.employees)
			}
		}
		catch (error) {
			toast.error("Failed to fetch staffs. Please try again.")
		}
		finally {
			setIsLoading(false)
		}
	}

	const fetchForms = async () => {
		setIsLoading(true)

		try {
			const response = await fetch(`${BASE_API_URL}/classes`, {
				headers: {
					'x-access-token': auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				toast.error(data.message)
			}
			else {
				setForms(data.classes)
			}
		} catch (error) {
			toast.error("Something went wrong. Please try again.")
		}
		finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchSmsDetails()
		fetchForms()
	}, [])

	useEffect(() => {
		if (type === "staff") {
			fetchStaffs()
		}
	}, [category])

	return (
		<div className='pt-2'>
			<p>
				<span className='h3 fw-bold text-muted'>Announcements</span>
				<br />
				<span>Send <strong>SMS</strong> messages to selected recepients.</span>
			</p>

			{
				isLoading &&
				<div className="text-center">
					Sending... <Loading />
				</div>
			}

			<div className="shadow p-2 rounded-0">
				<form onSubmit={sendMessage}>
					<div className="mb-3">
						<label htmlFor="message" className="form-label">
							<span className="me-2">Message</span>
							<span>
								{content.length % charsInSMS}/{charsInSMS} ({Math.floor((content.length + charsInSMS - 1) / charsInSMS)})
							</span>
						</label>
						<textarea
							name="message"
							id="message"
							className="form-control rounded-0"
							rows={5}
							required
							disabled={isLoading}
							value={content}
							onChange={e => setContent(e.target.value)}
						></textarea>
					</div>
					<div className="mb-3">
						<span className="me-3">Choose Recepient Type:</span>
						<RoleOffice roles={["Headmaster", "Second Master"]} element={
							<span className="px-3 py-1 border border-secondary rounded-0 me-2">
								<label htmlFor="parents" className="form-label me-2">Parents</label>
								<input
									type="radio"
									name="type"
									id="parents"
									disabled={isLoading}
									value={"parent"}
									checked={type === "parent"}
									onChange={e => setType(e.target.value)}
								/>
							</span>
						} />
						<span className="px-3 py-1 border border-secondary rounded-0 me-2">
							<label htmlFor="staffs" className="form-label me-2">Staffs</label>
							<input
								type="radio"
								name="type"
								id="staffs"
								disabled={isLoading}
								value={"staff"}
								checked={type === "staff"}
								onChange={e => setType(e.target.value)}
							/>
						</span>
					</div>
					<div className="mb-3">
						<label htmlFor="category" className="form-label">Choose Recepient Category:</label>
						<select
							name="category"
							id="category"
							className="form-select rounded-0"
							required
							disabled={isLoading}
							value={category}
							onChange={e => setCategory(e.target.value)}
						>
							<option value="">Choose category</option>
							{
								type && <option value="all">
									All
									<RoleOffice
										roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]}
										element={" (Except AEKs)"}
									/>
								</option>
							}
							{
								type === "staff" &&
								<>
									<RoleOffice
										roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"]}
										element={
											<>
												<RoleOffice roles={["AEK"]} reverse={true} element={
													<option value="AEK">AEK</option>
												} />
												<option value="Headmaster">
													<LevelSplit primary={"Head Teachers"} ordinary={"Headmasters/Headmistresses"} />
												</option>
											</>
										}
									/>
									<option value="Academic Master">
										<LevelSplit primary={"Academic Teachers"} ordinary={"Academic Masters/Mistresses"} />
									</option>
									<option value="Teacher">Regular Teachers</option>
									<option value="Temporary Teacher">Temporary Teachers</option>
								</>
							}
							{
								type === "parent" &&
								<>
									{
										forms?.map((form, index) => (
											<option value={form.id}>{form.name} Parents</option>
										))
									}
								</>
							}
						</select>
					</div>
					{
						type === "staff" && category && category !== "all" &&
						<div className="mb-3">
							<span className="mb-3 text-muted fw-bold">Recepients Selected: {recepients.length}</span>
							<DataTable
								columns={columns}
								data={staffs}
								progressPending={isLoading}
								progressComponent={
									<div className="text-center">
										Loading... <Loading />
									</div>
								}
								noDataComponent={"No staff members found."}
							/>
						</div>
					}

					<div className="mb-3 text-end">
						<button className="btn btn-primary fw-bold rounded-0" disabled={isLoading}>
							<span className="me-2">Send</span>
							<i className="bi bi-send-fill"></i>
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default Announcements
