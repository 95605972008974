import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import toast from 'react-hot-toast'

function NewSubscription() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [schools, setSchools] = useState([])
    const [sub, setSub] = useState({
        name: "", amount: "", entry_fee: "", date_started: "", members: []
    })

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Region',
            selector: row => row.region,
            sortable: true,
        },
        {
            name: 'District',
            selector: row => row.district,
            sortable: true,
        },
        {
            name: 'Ward',
            selector: row => row.ward,
            sortable: true,
        },
        {
            name: 'School',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Level',
            selector: row => row.level,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <div className='d-flex'>
                <button
                    type="button"
                    className={`btn btn-sm btn-${!sub.members.includes(row.id) ? 'outline-' : ''}primary rounded-0 me-2`}
                    onClick={() => {
                        if (!sub.members.includes(row.id)) {
                            const members = [...sub.members, row.id]
                            setSub({ ...sub, members: members })
                        }
                        else {
                            const members = sub.members.filter(m => m !== row.id)
                            setSub({ ...sub, members: members })
                        }
                    }}
                >
                    {sub.members.includes(row.id) ? "Selected" : "Select"}
                </button>
            </div>,
            sortable: false,
        }
    ]

    const fetchSchools = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subscriptions/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            toast.error('Failed to fetch schools. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subscriptions`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify(sub)
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch(error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSchools()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>New Subscription</p>
            <div className='shadow p-2 mb-4'>
                <form onSubmit={handleSubmit}>
                    <div className='form-group mb-3'>
                        <label htmlFor='name' className='form-label'>Name</label>
                        <input
                            type='text'
                            className='form-control rounded-0'
                            id='name'
                            placeholder='eg. Meatu District'
                            required
                            disabled={isLoading}
                            value={sub.name}
                            onChange={e => setSub({ ...sub, name: e.target.value })}
                        />
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor='entry_fee' className='form-label'>Entry Fee</label>
                        <input
                            type='number'
                            className='form-control rounded-0'
                            id='entry_fee'
                            placeholder='eg. 6000000'
                            min={10000}
                            required
                            disabled={isLoading}
                            value={sub.entry_fee}
                            onChange={e => setSub({ ...sub, entry_fee: e.target.value })}
                        />
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor='amount' className='form-label'>Amount</label>
                        <input
                            type='number'
                            className='form-control rounded-0'
                            id='amount'
                            placeholder='eg. 300000'
                            min={10000}
                            required
                            disabled={isLoading}
                            value={sub.amount}
                            onChange={e => setSub({ ...sub, amount: e.target.value })}
                        />
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor='date_started' className='form-label'>Date Started</label>
                        <input
                            type='date'
                            className='form-control rounded-0'
                            id='date_started'
                            required
                            disabled={isLoading}
                            value={sub.date_started}
                            onChange={e => setSub({ ...sub, date_started: e.target.value })}
                        />
                    </div>
                    <div className="mb-3">
                        <p>Members</p>
                        <DataTable
                            columns={columns}
                            data={schools}
                            progressPending={isLoading}
                            progressComponent={<div className="text-center">Loading...<Loading /></div>}
                            noDataComponent={"No schools available."}
                        />
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Registering... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Register
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewSubscription
