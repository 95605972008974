import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import RoleOffice from '../../components/offices/RoleOffice'
import { saveAs } from 'file-saver'
import toast from 'react-hot-toast'

function ExamCandidates({ hideOptions, purpose }) {
    const auth = useAuth()
    const params = useParams()
    const location = useLocation()
    const examId = params.exam_id
    const form = location?.state?.form

    const [isLoading, setIsLoading] = useState(true)
    const [errors, setErrors] = useState([])
    const [showDialog, setShowDialog] = useState(false)

    const [candidates, setCandidates] = useState([])
    const [fetchedCandidates, setFetchedCandidates] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])
    const [toDelete, setToDelete] = useState(null)
    const [candidateFilter, setCandidateFilter] = useState("")
    const [singleStudent, setSingleStudent] = useState({})

    const candidates_columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Index No',
            selector: row => row.index_number,
            sortable: true,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name: 'Subjects',
            selector: row => row.subjects,
            sortable: true,
            grow: 0,
        },
        auth.user.role !== "DSEO" &&
        {
            name:
                <RoleOffice roles={["Headmaster", "Second Master", "Academic Master", form]} element={
                    <div>
                        <input
                            type='checkbox'
                            className='me-1'
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedStudents(candidates.map(s => s.candidate_id))
                                } else {
                                    setSelectedStudents([])
                                }
                            }}
                        />
                        <span>({selectedStudents.length})</span>
                    </div>
                } />,
            cell: row =>
                <RoleOffice roles={["Headmaster", "Second Master", "Academic Master", form]} element={
                    <div className='d-flex flex-wrap align-items-center'>
                        <RoleOffice roles={["Headmaster", "Second Master", "Academic Master"]} element={
                            !hideOptions &&
                            <button
                                className='btn btn-sm btn-danger rounded-0 me-1 mb-1'
                                disabled={purpose === "necta"}
                                onClick={() => {
                                    setToDelete(row)
                                    setShowDialog(true)
                                }}>
                                <i className='bi bi-trash-fill fs-6 mx-1'></i>
                            </button>
                        } />
                        <button
                            type='button'
                            className={`btn btn-sm rounded-0 ${selectedStudents.includes(row.candidate_id) ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => {
                                if (selectedStudents.includes(row.candidate_id)) {
                                    let newStudents = selectedStudents.filter(studentId => studentId !== row.candidate_id)
                                    setSelectedStudents(newStudents)

                                    // if the student is the only one selected, set the single student to the remaining student
                                    if (newStudents.length < 2 && newStudents.length > 0) {
                                        setSingleStudent(candidates.find(s => s.candidate_id === newStudents[0]))
                                    }
                                    else {
                                        setSingleStudent({})
                                    }
                                } else {
                                    let newStudents = [...selectedStudents, row.candidate_id]
                                    setSelectedStudents(newStudents)

                                    // if the student is the only one selected, set the single student to the remaining student
                                    if (newStudents.length < 2 && newStudents.length > 0) {
                                        setSingleStudent(row)
                                    }
                                    else {
                                        setSingleStudent({})
                                    }
                                }
                            }}>
                            {selectedStudents.includes(row.candidate_id) ? 'Selected' : 'Select'}
                        </button>
                    </div>
                } />,
            sortable: false,
        }
    ]

    const filterStudents = (filter) => {
        if (filter.length < 1) {
            setCandidates(fetchedCandidates)
            return
        }

        setCandidates(fetchedCandidates.filter(cand =>
            cand.firstname.toLowerCase().includes(filter) ||
            cand.middlename.toLowerCase().includes(filter) ||
            cand.lastname.toLowerCase().includes(filter) ||
            cand.school.toLowerCase().includes(filter)
        ))
    }

    const fetchCandidates = async () => {
        setIsLoading(true)

        try {
            // per_page=10000, an arbitrary large number to fetch all candidates at once
            let url = `${BASE_API_URL}/exams/${examId}/candidates?page=1&per_page=10000&form=${form}`
            if (purpose === "necta") {
                url = `${BASE_API_URL}/necta/exams/${examId}/candidates`
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setErrors(data.errors)
                toast.error(data.message)
            }
            else {
                setCandidates(data.candidates)
                setFetchedCandidates(data.candidates)
            }
        }
        catch (error) {
            toast.error('Failed to fetch candidates. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const deleteCandidates = async () => {
        setIsLoading(true)
        setErrors([])

        let students = []
        if (toDelete && toDelete.candidate_id) {
            students = [toDelete.candidate_id]
        }
        else {
            students = selectedStudents
        }

        if (students.length < 1) {
            toast.error("Select at least one candidate to delete")
            setIsLoading(false)
            return
        }

        try {
            let url = `${BASE_API_URL}/exams/${examId}/candidates`
            if (purpose === "necta") {
                url = `${BASE_API_URL}/necta/exams/${examId}/candidates`
            }

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    "candidates": students
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setErrors(data.errors)
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
                setSelectedStudents([])
                fetchCandidates()
            }
        }
        catch (error) {
            toast.error('Failed to deregister student. Please try again.')
        }
        finally {
            setToDelete({})
            setIsLoading(false)
        }
    }

    const downloadReports = async () => {
        setIsLoading(true)
        setErrors([])

        if (selectedStudents.length < 1) {
            toast.error("Select at least one candidate to download reports")
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/report/${examId}/${form}/download`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    "candidates": selectedStudents
                })
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            const filename = response.headers.get("x-filename")
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error('Failed to download student report. Please try again.')
        }
        finally {
            setToDelete({})
            setIsLoading(false)
        }
    }

    const downloadCAL = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/exams/${examId}/cal/${form}`
            if (purpose === "necta") {
                url = `${BASE_API_URL}/necta/exams/${examId}/cal`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }
            const filename = `${suff}-${form}-CAL.xlsx`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error('Failed to download CAL. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const sendSMS = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/sms/report/${examId}/${form}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({
                    type: "selected",
                    candidates: selectedStudents
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to send SMS. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        filterStudents(candidateFilter)
    }, [candidateFilter])

    useEffect(() => {
        fetchCandidates()
    }, [])

    return (
        <div className='pt-2'>
            <div className='d-flex flex-wrap align-items-center justify-content-between mb-3'>
                <span className="h3 fw-bold text-muted">{form} Candidates</span>
                <div className='d-flex flex-wrap align-items-center justify-content-between'>
                    <RoleOffice
                        roles={["Headmaster", "Second Master", "Academic Master"]}
                        element={
                            !hideOptions &&
                            <>
                                <button
                                    className='btn btn-primary btn-sm rounded-0 fw-bold me-2'
                                    onClick={downloadCAL}
                                    disabled={isLoading}
                                >
                                    <span className="me-2">Download CAL</span>
                                    <i className='bi bi-download'></i>
                                </button>
                                <Link
                                    to={'register'}
                                    state={{ form: form }}
                                    className='btn btn-primary btn-sm fw-bold rounded-0'
                                >
                                    <span className="me-2">Register Candidates</span>
                                    <i className='bi bi-person-fill-add'></i>
                                </Link>
                            </>
                        }
                    />
                </div>
            </div>

            <div className="mb-3">
                {
                    errors && errors.map((error, index) => (
                        <div className="alert alert-warning p-2 mb-1" key={index}>
                            {error}
                        </div>
                    ))
                }
            </div>

            <ConfirmationDialog
                open={showDialog}
                onClose={() => {
                    setToDelete({})
                    setShowDialog(false)
                    setIsLoading(false)
                }}
                onConfirm={() => {
                    deleteCandidates()
                    setShowDialog(false)
                }}
                isLoading={isLoading}
                title="Deregister Student"
                message={
                    toDelete && toDelete.id ?
                        `Are you sure you want to delete candidate ${toDelete.firstname} ${toDelete.lastname}?` :
                        `Are you sure you want to delete ${selectedStudents.length} candidates?`
                }
            />

            <div className="shadow p-2 mb-3">
                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control rounded-0"
                        placeholder="Search candidates"
                        disabled={isLoading}
                        value={candidateFilter}
                        onChange={e => setCandidateFilter(e.target.value)}
                    />
                </div>
                <DataTable
                    title={
                        <div className='d-flex align-items-center justify-content-between'>
                            <span>{candidates ? candidates.length : 0} Candidates</span>
                            <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                <RoleOffice
                                    roles={["Headmaster", "Second Master", "Academic Master"]}
                                    element={
                                        !hideOptions &&
                                        <button
                                            className='btn btn-sm btn-danger rounded-0 me-2'
                                            disabled={selectedStudents.length < 1 || isLoading || purpose === "necta"}
                                            onClick={() => {
                                                setToDelete({})
                                                setShowDialog(true)
                                            }}
                                        >
                                            <span className="me-2">Delete Selected</span>
                                            <i className='bi bi-trash'></i>
                                        </button>
                                    }
                                />
                                <RoleOffice roles={["Headmaster", "Second Master", "Academic Master", form]} element={
                                    purpose !== "sms" ?
                                        <button
                                            className='btn btn-sm btn-primary rounded-0'
                                            disabled={selectedStudents.length < 1 || isLoading || purpose === "necta"}
                                            onClick={() => {
                                                if (selectedStudents.length > 50) {
                                                    toast.error("Currently you can only download report forms of at most 50 students at a time.")
                                                }
                                                else {
                                                    downloadReports()
                                                }
                                            }}
                                        >
                                            <span className="me-2">Download Report</span>
                                            <i className='bi bi-file-earmark-arrow-down-fill'></i>
                                        </button>
                                        :
                                        <button
                                            className='btn btn-sm btn-primary rounded-0'
                                            disabled={selectedStudents.length < 1 || isLoading || purpose === "necta"}
                                            onClick={() => sendSMS()}
                                        >
                                            <span className="me-2">Send SMS</span>
                                            <i className='bi bi-send-fill'></i>
                                        </button>
                                } />
                            </div>
                        </div>
                    }
                    columns={candidates_columns}
                    data={candidates}
                    progressPending={isLoading}
                    progressComponent={
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    }
                    noDataComponent={"No candidates found."}
                />
            </div>
        </div>
    )
}

export default ExamCandidates
