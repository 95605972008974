import React from 'react'
import { useAuth } from '../../context/AuthContext'
import RoleOffice from '../offices/RoleOffice'
import LevelSplit from '../offices/LevelSplit'

function ContextSwitch({ isLoading, setIsLoading, orientation }) {
    const auth = useAuth()

    return (
        <div className={orientation === 'horizontal' ? 'd-flex flex-wrap' : ''}>
            <RoleOffice roles={["AEK"]} element={
                <div className={`${orientation === 'vertical' ? 'ms-4 mb-3' : 'me-2 mb-1 row mx-0'}`}>
                    {
                        orientation === 'horizontal' &&
                        <label
                            htmlFor="level"
                            className={`
                            fw-bold text-muted ps-0
                            ${orientation === 'vertical' ? 'form-label' : 'col-form-label col-auto'}
                        `}
                        >
                            Section:
                        </label>
                    }
                    <select
                        id='level'
                        className={`
                            form-select form-select-sm rounded-0 w-100 
                            ${isLoading ? 'disabled' : ''}
                            ${orientation === 'vertical' ? '' : 'col'}
                        `}
                        value={auth.user.level}
                        onChange={(e) => {
                            setIsLoading(true)
                            auth.updateLevel(e.target.value)
                        }}
                    >
                        <option value="PRIMARY">Primary</option>
                        <option value="ORDINARY">Secondary</option>
                    </select>
                </div>
            } />
            <div className={`${orientation === 'vertical' ? 'ms-4 mb-3' : 'row mx-0'}`}>
                {
                    orientation === 'horizontal' &&
                    <label
                        htmlFor="year"
                        className={`
                            fw-bold text-muted ps-0
                            ${orientation === 'vertical' ? 'form-label' : 'col-form-label col-auto'}
                        `}
                    >
                        Academic Year:
                    </label>
                }
                <select
                    id='year'
                    className={`
                        form-select form-select-sm rounded-0 w-100 
                        ${isLoading ? 'disabled' : ''}
                        ${orientation === 'vertical' ? '' : 'col'}
                    `}
                    value={auth?.selected_academic_year}
                    onChange={(e) => {
                        setIsLoading(true)
                        auth.updateAcademicYear(e.target.value)
                    }}
                >
                    {
                        auth?.user?.academic_years?.map((year, index) => (
                            <option key={index} value={year.year}>
                                {year.year}
                                <LevelSplit
                                    primary={null}
                                    ordinary={
                                        auth.user.has_a_level && 
                                        <>&nbsp;(A Level: {year.a_level})</>
                                    }
                                />
                            </option>
                        ))
                    }
                </select>
            </div>
        </div>
    )
}

export default ContextSwitch
