import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import toast from 'react-hot-toast'

function NewBareClass() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    const [level, setLevel] = useState("")

    const classes = [
        "Form I", "Form II", "Form III", "Form IV", "Form V", "Form VI", "DRS AWALI", "Pre-Unit II", 
        "Class I", "Class II", "Class III", "Class IV", "Class V", "Class VI", "Class VII"
    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes/bare`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    name,
                    level
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to register class. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Register Bare Class</p>
            <div className='shadow p-2'>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='name' className='form-label'>Class Name</label>
                                <select
                                    className='form-select rounded-0'
                                    id='name'
                                    required
                                    disabled={isLoading}
                                    value={name}
                                    onChange={e => setName(e.target.value)}>
                                    <option value=''>Select Class</option>
                                    {
                                        classes && classes.map((form, index) => (
                                            <option key={index} value={form}>{form}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='level' className='form-label'>Class Level</label>
                                <select
                                    className='form-select rounded-0'
                                    id='level'
                                    required
                                    disabled={isLoading}
                                    value={level}
                                    onChange={e => setLevel(e.target.value)}>
                                    <option value=''>Select Class Level</option>
                                    <option value="PRIMARY">PRIMARY</option>
                                    <option value="ORDINARY">ORDINARY</option>
                                    <option value="ADVANCE">ADVANCE</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Registering... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Register
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewBareClass
