import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import logo from '../../assets/mssis.png'

function Public() {
    return (
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-white rounded" aria-label="Eleventh navbar example">
                <div className="container-fluid">
                    <a className="navbar-brand" href="https://mssis.co.tz/">
                        <img src={logo} alt="MSSIS logo" height="60" className="me-1" />
                        MSSIS
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarsExample09">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="https://mssis.co.tz/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://mssis.co.tz/#features">Features</a>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to={"/public/necta"}>NECTA Results</Link>
                            </li> */}
                        </ul>
                        <div>
                            <a href="https://app.mssis.co.tz" className="btn btn-outline-secondary fw-bold me-2">Got to App</a>
                            <a href="https://mssis.co.tz/get-started" className="btn btn-primary fw-bold">Get Started</a>
                        </div>
                    </div>
                </div>
            </nav>

            <div>
                <Outlet />
            </div>

            <div>
                <div className="text-center fw-bold text-muted my-4">
                    <p>
                        +255 625 973 343 &nbsp;|&nbsp; +255 759 640 777
                        <br />
                        P.O.BOX 338, Dodoma Tanzania
                    </p>
                    <p>Modern Streamlined Schools' Information System (MSSIS) 2025</p>
                </div>
            </div>
        </div>
    )
}

export default Public
