import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import RoleOffice from '../../components/offices/RoleOffice'
import DataTable from 'react-data-table-component'
import LevelSplit from '../../components/offices/LevelSplit'
import FacilitiesTable from '../../components/facilities/FacilitiesTable'
import toast from 'react-hot-toast'

function ViewSchool() {
    const auth = useAuth()
    const params = useParams()
    const schoolId = params.id

    const toastId = "ViewSchool"
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [school, setSchool] = useState({})

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'First Name',
            selector: row => row.firstname,
            sortable: true
        },
        {
            name: 'Last Name',
            selector: row => row.lastname,
            sortable: true
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Actions',
            cell: row => <Link to={`/staffs/${row.id}`} className='btn btn-primary btn-sm fw-bold rounded-0'>
                <span className="me-2">View</span>
                <i className='bi bi-eye'></i>
            </Link>
        }
    ]

    const fetchSchool = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/${schoolId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                setSchool(data.school)
            }
        }
        catch (error) {
            toast.error('Failed to fetch school. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSchool()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted mb-3'>School Details</p>
                <RoleOffice
                    roles={["DSEO", "DSAO"]}
                    element={
                        <Link to={`/schools/${schoolId}/edit`} className='btn btn-primary btn-sm fw-bold rounded-0'>
                            <span className="me-2">Edit</span>
                            <i className='bi bi-pencil-square'></i>
                        </Link>}
                />
            </div>

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div> :
                    <>
                        <div className='shadow p-2 mb-4'>
                            <div>
                                <div className="">
                                    <p>
                                        <span className='fw-bold me-2'>Name:</span>
                                        <span className='text-muted fs-5'>
                                            {school.name} <LevelSplit primary={"Primary"} ordinary={"Secondary"} /> School
                                        </span>
                                    </p>
                                </div>
                                <div className="">
                                    <p>
                                        <span className='fw-bold me-2'>NECTA Registration Number:</span>
                                        <span className='text-muted fs-5'>
                                            {school.reg_no}
                                        </span>
                                    </p>
                                </div>
                                <div className="">
                                    <p>
                                        <span className='fw-bold me-2'>School Registration Number:</span>
                                        <span className='text-muted fs-5'>
                                            {school.school_reg_no}
                                        </span>
                                    </p>
                                </div>
                                <div className="">
                                    <p>
                                        <span className='fw-bold me-2'>Ownership:</span>
                                        <span className='text-muted fs-5'>
                                            {school.ownership}
                                        </span>
                                    </p>
                                </div>
                                <div className="">
                                    <p>
                                        <span className='fw-bold me-2'>Location:</span>
                                        <span className='text-muted fs-5'>
                                            {school.location}
                                        </span>
                                    </p>
                                </div>
                                <div className="">
                                    <p>
                                        <span className='fw-bold me-2'>Date Launched:</span>
                                        <span className='text-muted fs-5'>
                                            {school.date_launched}
                                        </span>
                                    </p>
                                </div>
                                <div className="">
                                    <p>
                                        <span className='fw-bold me-2'><LevelSplit primary={"Head Teacher"} ordinary={"Headmaster"} />: </span>
                                        {
                                            school.headmaster ?
                                                <Link to={`/staffs/${school.headmaster.id}`} className='fs-5'>
                                                    {school.headmaster.firstname} {school.headmaster.lastname}
                                                </Link>
                                                :
                                                <span className='text-danger'>None Assigned</span>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>

                        <RoleOffice
                            roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]}
                            element={
                                <div className="shadow p-2 mb-4">
                                    <p className='h5 fw-bold text-muted mb-3'>Actions</p>
                                    <div className="text-center">
                                        <Link to={`/schools/${schoolId}/headmaster`} state={{ school: school }} className='btn btn-primary btn-sm fw-bold rounded-0 px-2 m-2'>
                                            <span className="me-2">
                                                Assign <LevelSplit primary={"Head Teacher"} ordinary={"Headmaster"} />
                                            </span>
                                            <i className='bi bi-person'></i>
                                        </Link>
                                        <Link to={`/schools/${schoolId}/staff`} state={{ school: school }} className='btn btn-primary btn-sm fw-bold rounded-0 px-2 m-2'>
                                            <span className="me-2">Assign Staff</span>
                                            <i className='bi bi-person'></i>
                                        </Link>
                                    </div>
                                </div>
                            }
                        />

                        <div className='shadow p-2 mb-4'>
                            <p className='h5 fw-bold text-muted mb-3'>Facilities</p>
                            <div>
                                <FacilitiesTable school={school?.id} disableAction={true} />
                            </div>
                        </div>

                        <div className='shadow p-2 mb-4'>
                            <p className='h5 fw-bold text-muted mb-3'>Teachers</p>
                            <div>
                                {
                                    school.teachers && school.teachers.length > 0 ?
                                        <DataTable
                                            columns={columns}
                                            data={school.teachers}
                                            pagination
                                            paginationPerPage={perPage}
                                            onChangePage={page => setCurrentPage(page)}
                                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                            highlightOnHover
                                            striped
                                            responsive
                                        />
                                        :
                                        <p className="text-center">
                                            School has no teachers
                                        </p>
                                }
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

export default ViewSchool
