import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import Loading from '../../../components/loading/Loading'
import LevelSplit from '../../../components/offices/LevelSplit'
import { BASE_API_URL } from '../../../utils/constants'
import toast from 'react-hot-toast'

function NewDisqualifiedStudents() {
    const auth = useAuth()
    const params = useParams()
    const studentId = params.studentId
    const location = useLocation()
    const student = location?.state?.student
    const classes = location?.state?.classes

    const [isLoading, setIsLoading] = useState(false)
    const [other, setOther] = useState("")
    const [reason, setReason] = useState("")
    const [reasons, setReasons] = useState([
        "Pregnancy", "Truancy", "Sickness", "Death", "Expelled from school", "Other"
    ])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/students/disqualified/${studentId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({reason, other})
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>
                Disqualify <LevelSplit primary={"Pupil"} ordinary={"Student"} />
            </p>

            {
                isLoading &&
                <div className="text-center">
                    Loading... <Loading />
                </div>
            }

            {
                !isLoading &&
                <>
                    <div className="p-2 shadow rounded-0 mb-3">
                        <span className='fw-bold me-2'>Name:</span>
                        <span className='text-muted'>
                            {student.firstname} {student.middlename} {student.lastname}
                        </span>
                        <br />
                        <span className='fw-bold me-2'>Sex:</span>
                        <span className='text-muted'>
                            {student.sex}
                        </span>
                        <br />
                        <span className='fw-bold me-2'>
                            <LevelSplit primary={"PREM"} ordinary={"PREMs"} /> Number:
                        </span>
                        <span className='text-muted'>
                            {student.prems_number}
                        </span>
                        <br />
                        <span className='fw-bold me-2'>Class:</span>
                        <span className='text-muted'>
                            {
                                classes.find(cls => cls.id === student?.form)?.name
                            }
                        </span>
                    </div>
                    <div className="p-2 shadow rounded-0 mb-3">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="reason" className="form-label">Reason</label>
                                <select 
                                    name="reason" 
                                    id="reason" 
                                    className="form-select"
                                    required
                                    value={reason}
                                    onChange={e => setReason(e.target.value)}
                                >
                                    <option value="">Select Reason</option>
                                    {
                                        reasons?.map((res, index) => (
                                            <option key={index} value={res}>{res}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {
                                reason === "Other" &&
                                <div className="form-group mb-3">
                                    <label htmlFor="other" className="form-label">Other Reasons</label>
                                    <textarea 
                                        name="other" 
                                        id="other" 
                                        className="form-control"
                                        required
                                        value={other}
                                        onChange={e => setOther(e.target.value)}
                                    ></textarea>
                                </div>
                            }
                            <div className="mb-3 text-end">
                                <button className="btn btn-primary rounded-0" type="submit">
                                    <span className="me-2 fw-bold">Submit</span>
                                    <i className="bi bi-floppy2"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            }
        </div>
    )
}

export default NewDisqualifiedStudents
