import { Outlet, Route, Routes } from 'react-router-dom'
import './App.css'
import { AuthProvider } from './context/AuthContext'
import Home from './pages/Home'
import Login from './pages/Login'
import Schools from './pages/school/Schools'
import Dashboard from './pages/dashboard/Dashboard'
import NewSchool from './pages/school/NewSchool'
import ViewSchool from './pages/school/ViewSchool'
import EditSchool from './pages/school/EditSchool'
import Staffs from './pages/staff/Staffs'
import NewStaff from './pages/staff/NewStaff'
import ViewStaff from './pages/staff/ViewStaff'
import EditStaff from './pages/staff/EditStaff'
import HeadmasterSchool from './pages/school/HeadmasterSchool'
import RoleRedirect from './components/offices/RoleRedirect'
import Subjects from './pages/subject/Subjects'
import NewSubject from './pages/subject/NewSubject'
import ViewSubject from './pages/subject/ViewSubject'
import NotFound from './components/notfound/NotFound'
import StaffSchool from './pages/school/StaffSchool'
import Classes from './pages/class/Classes'
import NewClass from './pages/class/NewClass'
import NewSubjectTeachers from './pages/subject/NewSubjectTeachers'
import { Students } from './pages/student/Students'
import NewStudents from './pages/student/NewStudents'
import ViewStudent from './pages/student/ViewStudent'
import Grades from './pages/grades/Grades'
import NewGrades from './pages/grades/NewGrades'
import EditGrades from './pages/grades/EditGrades'
import ViewClass from './pages/class/ViewClass'
import NewSchoolSubject from './pages/subject/NewSchoolSubject'
import TeachingClasses from './pages/teaching/TeachingClasses'
import Exams from './pages/exams/Exams'
import NewExam from './pages/exams/NewExam'
import TeachingResultsExams from './pages/teaching/TeachingResultsExams'
import TeachingResultsSubjects from './pages/teaching/TeachingResultsSubjects'
import TeachingResultsUpload from './pages/teaching/TeachingResultsUpload'
import ViewExam from './pages/exams/ViewExam'
import OverallResults from './pages/results/OverallResults'
import ExternalResults from './pages/results/ExternalResults'
import InternalResults from './pages/results/InternalResults'
import SchoolFacilities from './pages/facilities/SchoolFacilities'
import Facilities from './pages/facilities/Facilities'
import Profile from './pages/settings/Profile'
import PasswordUpdate from './pages/settings/PasswordUpdate'
import PasswordReset from './pages/settings/PasswordReset'
import PasswordResetNew from './pages/settings/PasswordResetNew'
import SchoolDesignation from './pages/administration/designation/SchoolDesignation'
import StudentsCount from './pages/dashboard/StudentsCount'
import StaffCount from './pages/dashboard/StaffCount'
import CreateResultsTitle from './pages/administration/CreateResultsTitle'
import SingleFacility from './pages/facilities/SingleFacility'
import EnrolStudents from './pages/subject/EnrolStudents'
import TeachingClassStudents from './pages/teaching/TeachingClassStudents'
import ExamCandidates from './pages/exams/ExamCandidates'
import ExamCandidatesRegister from './pages/exams/ExamCandidatesRegister'
import OverallTenStudents from './pages/results/OverallTenStudents'
import StudentsSubjectWise from './pages/results/StudentsSubjectWise'
import NewLocation from './pages/locations/NewLocation'
import Locations from './pages/locations/Locations'
import EditExam from './pages/exams/EditExam'
import OverallStudentRanking from './pages/results/OverallStudentRanking'
import OverallSubjectRanking from './pages/results/OverallSubjectRanking'
import TeachersRanking from './pages/results/TeachersRanking'
import TeachersSubjectWise from './pages/results/TeachersSubjectWise'
import SchoolRanking from './pages/results/SchoolRanking'
import StudentDiscipline from './pages/discipline/StudentDiscipline'
import ReportDetails from './pages/report/ReportDetails'
import NewReportDetails from './pages/report/NewReportDetails'
import TeachingLearningMaterials from './pages/teaching/TeachingLearningMaterials'
import SubjectTopicCoverage from './pages/teaching/subject-topic-coverage/SubjectTopicCoverage'
import TeachersLogbook from './pages/teaching/teachers-logbook/TeachersLogbook'
import NewSubjectTopicCoverage from './pages/teaching/subject-topic-coverage/NewSubjectTopicCoverage'
import ViewSubjectTopics from './pages/teaching/subject-topic-coverage/ViewSubjectTopics'
import ViewSubTopics from './pages/teaching/subject-topic-coverage/ViewSubTopics'
import UpdateTopicCoverage from './pages/teaching/subject-topic-coverage/UpdateTopicCoverage'
import TopicCoverageReports from './pages/teaching/subject-topic-coverage/TopicCoverageReports'
import BareSubjectTopics from './pages/teaching/subject-topic-coverage/BareSubjectTopics'
import BareSubjectTopic from './pages/teaching/subject-topic-coverage/BareSubjectTopic'
import ApproveSubjectTopicCoverage from './pages/teaching/subject-topic-coverage/ApproveSubjectTopicCoverage'
import LogoAndStamps from './pages/administration/LogoAndStamps'
import SubjectLogBook from './pages/teaching/teachers-logbook/SubjectLogBook'
import NewSubjectTopic from './pages/teaching/subject-topic-coverage/NewSubjectTopic'
import NewSubTopic from './pages/teaching/subject-topic-coverage/NewSubTopic'
import Settings from './pages/settings/Settings'
import Announcements from './pages/administration/Announcements'
import SMSManagement from './pages/sms/SMSManagement'
import Designation from './pages/administration/designation/Designation'
import DSEOStaff from './pages/staff/DSEOStaff'
import NewBareClass from './pages/class/NewBareClass'
import ClassUpgrade from './pages/class/ClassUpgrade'
import DeliveryReport from './pages/settings/tabs/DeliveryReport'
import BareFacilities from './pages/facilities/BareFacilities'
import NewFacilities from './pages/facilities/NewFacilities'
import BareFacility from './pages/facilities/BareFacility'
import FillSchoolFacility from './pages/facilities/FillSchoolFacility'
import SubjectTeachers from './pages/subject/SubjectTeachers'
import { CLASSES_PRIMARY, CLASSES_SECONDARY } from './utils/constants'
import StudentTransfer from './pages/transfer/StudentTransfer'
import StudentTransferRequest from './pages/transfer/StudentTransferRequest'
import StaffTransfer from './pages/transfer/StaffTransfer'
import NectaExams from './pages/exams/necta/NectaExams'
import NewNectaExam from './pages/exams/necta/NewNectaExam'
import ViewNectaExam from './pages/exams/necta/ViewNectaExam'
import NectaResults from './pages/results/necta/NectaResults'
import Repeaters from './pages/class/Repeaters'
import NectaResultsAnalysisRequest from './pages/public/necta/NectaResultsAnalysisRequest'
import Public from './pages/public/Public'
import DisqualifiedStudents from './pages/student/disqualified/DisqualifiedStudents'
import NewDisqualifiedStudents from './pages/student/disqualified/NewDisqualifiedStudents'
import PastPapers from './pages/teaching/past-papers/PastPapers'
import NewPastPapers from './pages/teaching/past-papers/admin/NewPastPapers'
import { PastPaperExams } from './pages/teaching/past-papers/admin/PastPaperExams'
import NewPastPaperExams from './pages/teaching/past-papers/admin/NewPastPaperExams'
import PastPaperExam from './pages/teaching/past-papers/admin/PastPaperExam'
import BarePastPapers from './pages/teaching/past-papers/admin/BarePastPapers'
import PastPapersDownload from './pages/teaching/past-papers/PastPapersDownload'
import PastPaper from './pages/teaching/past-papers/admin/PastPaper'
import EditPastPaper from './pages/teaching/past-papers/admin/EditPastPaper'
import LessonNotes from './pages/teaching/lesson-notes/LessonNotes'
import { NewLessonNotes } from './pages/teaching/lesson-notes/NewLessonNotes'
import LessonNotesDownload from './pages/teaching/lesson-notes/LessonNotesDownload'
import LessonNote from './pages/teaching/lesson-notes/LessonNote'
import RoleOffice from './components/offices/RoleOffice'
import Combinations from './pages/combination/Combinations'
import NewCombination from './pages/combination/NewCombination'
import NewSchoolCombination from './pages/combination/NewSchoolCombination'
import ViewCombination from './pages/combination/ViewCombination'
import Subscriptions from './pages/subscription/Subscriptions'
import NewSubscription from './pages/subscription/NewSubscription'
import Subscription from './pages/subscription/Subscription'
import RecordPayment from './pages/subscription/RecordPayment'
import Attendance from './pages/attendance/Attendance'
import RecordAttendance from './pages/attendance/RecordAttendance'
import WardRanking from './pages/results/WardRanking'
import Syllabus from './pages/teaching/syllabus/Syllabus'
import toast, { ToastBar, Toaster } from 'react-hot-toast'

function App() {
	return (
		<AuthProvider>
			<Toaster 
				toastOptions={{
					className: "rounded-0",
					duration: 30000
				}}
			>
				{(t) => (
					<ToastBar toast={t}>
						{({ icon, message }) => (
							<>
								{icon}
								{message}
								{t.type !== 'loading' && (
									<button className='btn rounded-0' onClick={() => toast.dismiss(t.id)}>
										<i className='bi bi-x-lg'></i>
									</button>
								)}
							</>
						)}
					</ToastBar>
				)}
			</Toaster>
			<Routes>
				<Route path="/" element={<Home />}>
					<Route path="/" element={<Outlet />}>
						<Route index element={<Dashboard />} />
						<Route path="/dashboard/students" element={<StudentsCount />} />
						<Route path="/dashboard/staffs" element={<StaffCount />} />
					</Route>

					<Route path="/schools" element={
						<RoleRedirect roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"]} children={<Outlet />} />
					}>
						<Route path='list' element={<Schools />} />
						<Route path=":id" element={<ViewSchool />} />
						<Route path="new" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} children={<NewSchool />} />
						} />
						<Route path=":id/edit" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} children={<EditSchool />} />
						} />
						<Route path=":id/headmaster" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} children={<HeadmasterSchool />} />
						} />
						<Route path=":id/staff" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} children={<StaffSchool />} />
						} />
					</Route>

					<Route path="/staffs" element={
						<RoleRedirect
							roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK", "Headmaster", "Second Master"]}
							children={<Outlet />}
						/>
					}>
						<Route path="school" element={<Staffs />} />
						<Route path=":id" element={<ViewStaff />} />
						<Route path="new" element={<NewStaff />} />
						<Route path=":id/edit" element={
							<RoleRedirect
								roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster", "Second Master"]}
								children={<EditStaff />}
							/>
						} />
						<Route path="dseo" element={
							<RoleRedirect
								roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]}
								children={<DSEOStaff />}
							/>
						} />
						<Route path="transfers" element={
							<RoleRedirect
								roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster", "Second Master"]}
								children={<StaffTransfer />}
							/>
						} />
					</Route>

					<Route path="/academics" element={<Outlet />}>
						<Route path="subjects" element={
							<RoleRedirect roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK", "Headmaster", "Second Master", "Academic Master"]} children={<Outlet />} />
						}>
							<Route index element={<Subjects />} />
							<Route path="new" element={
								<RoleRedirect roles={["Admin"]} children={<NewSubject isEditing={false} />} />
							} />
							<Route path="new/school" element={<NewSchoolSubject />} />
							<Route path=":id" element={<ViewSubject />} />
							<Route path=":id/edit" element={
								<RoleRedirect roles={["Admin"]} children={<NewSubject isEditing={true} />} />
							} />
							<Route path=":id/assign-teachers" element={<NewSubjectTeachers />} />
							<Route path=":id/enrol" element={
								<RoleRedirect roles={["Headmaster", "Second Master", "Academic Master"]}>
									<EnrolStudents purpose={"subjects"} />
								</RoleRedirect>
							} />
							<Route path="teachers" element={<SubjectTeachers />} />
						</Route>

						<Route path="classes" element={
							<RoleRedirect
								roles={[
									"Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO",
									"Headmaster", "Second Master", "Academic Master", "AEK"
								]}
								children={<Outlet />}
							/>
						}>
							<Route index element={<Classes />} />
							<Route path="new" element={<NewClass />} />
							<Route path="new/bare" element={<RoleRedirect roles={["Admin"]} children={<NewBareClass />} />} />
							<Route path=":id" element={<ViewClass />} />
							<Route path="upgrade" element={
								<RoleRedirect
									roles={["Headmaster", "Second Master", "Academic Master"]}
									children={<ClassUpgrade />}
								/>
							} />
							<Route path="repeaters" element={
								<RoleRedirect
									roles={[
										"DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO",
										"Headmaster", "Second Master", "Academic Master", "AEK"
									]}
									children={<Outlet />}
								/>
							}>
								<Route index element={<Repeaters />} />
								<Route path="register" element={<ExamCandidatesRegister purpose={"repeaters"} />} />
							</Route>
						</Route>

						<Route path="grades" element={
							<RoleRedirect
								roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK", "Headmaster", "Second Master", "Academic Master"]}
								children={<Outlet />}
							/>
						}>
							<Route index element={<Grades />} />
							<Route path="new" element={<NewGrades />} />
							<Route path=":id/edit" element={<EditGrades />} />
						</Route>

						<Route path="combinations" element={
							<RoleRedirect roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK", "Headmaster", "Second Master", "Academic Master"]} children={<Outlet />} />
						}>
							<Route index element={<Combinations />} />
							<Route path="new" element={
								<RoleRedirect roles={["Admin"]} children={<NewCombination isEditing={false} />} />
							} />
							<Route path="new/school" element={<NewSchoolCombination />} />
							<Route path=":id" element={<ViewCombination />} />
							<Route path=":id/edit" element={
								<RoleRedirect roles={["Admin"]} children={<NewCombination isEditing={true} />} />
							} />
							<Route path=":id/enrol" element={
								<RoleRedirect roles={["Headmaster", "Second Master", "Academic Master"]}>
									<EnrolStudents purpose={"combinations"} />
								</RoleRedirect>
							} />
						</Route>

						<Route path="exams" element={<Outlet />}>
							<Route index element={<Exams />} />
							<Route path="new" element={<NewExam />} />
							<Route path=":exam_id" element={<Outlet />}>
								<Route index element={<ViewExam />} />
								<Route path="edit" element={<EditExam />} />
								<Route path="candidates" element={<Outlet />}>
									<Route index element={<ExamCandidates hideOptions={false} purpose={"candidates"} />} />
									<Route path="register" element={<ExamCandidatesRegister purpose={""} />} />
								</Route>
								<Route path="report" element={<Outlet />}>
									<Route index element={<ReportDetails />} />
									<Route path="new" element={<NewReportDetails />} />
								</Route>
							</Route>
						</Route>

						<Route path="necta/exams" element={<Outlet />}>
							<Route index element={<NectaExams />} />
							<Route path="new" element={
								<RoleRedirect roles={["Admin"]}><NewNectaExam /></RoleRedirect>
							} />
							<Route path=":exam_id" element={<Outlet />}>
								<Route index element={<ViewNectaExam />} />
								<Route path="candidates" element={<Outlet />}>
									<Route index element={<ExamCandidates hideOptions={false} purpose={"necta"} />} />
									<Route path="register" element={<ExamCandidatesRegister purpose={"necta"} />} />
								</Route>
							</Route>
						</Route>

						<Route path="results" element={<Outlet />}>
							<Route path=":exam_id/internal" element={<InternalResults />} />
							<Route path=":exam_id/internal/:form_id" element={<Outlet />}>
								<Route index element={<OverallResults />} />
								<Route path="overall-ten" element={<OverallTenStudents />} />
								<Route path="subjectwise" element={<StudentsSubjectWise />} />
								<Route path="reports" element={<ExamCandidates hideOptions={true} purpose={"reports"} />} />
								<Route path="sms" element={<ExamCandidates hideOptions={true} purpose={"sms"} />} />
							</Route>
							<Route path=":exam_id/external" element={<ExternalResults />} />
							<Route path=":exam_id/external/:form_id" element={<Outlet />}>
								<Route index element={<OverallResults />} />
								<Route path="overall-ten" element={<OverallTenStudents />} />
								<Route path="subjectwise" element={<StudentsSubjectWise />} />
								<Route path="overall-ranks" element={<OverallStudentRanking />} />
								<Route path="subject-ranks" element={<OverallSubjectRanking />} />
								<Route path="teacher-ranks" element={<TeachersRanking />} />
								<Route path="subjectwise-teachers" element={<TeachersSubjectWise />} />
								<Route path="school-ranks" element={<SchoolRanking />} />
								<Route path="ward-ranks" element={<WardRanking />} />
								<Route path="reports" element={<ExamCandidates hideOptions={true} purpose={"reports"} />} />
								<Route path="sms" element={<ExamCandidates hideOptions={true} purpose={"sms"} />} />
							</Route>
							<Route path=":exam_id/necta" element={<NectaResults />} />
						</Route>

						<Route path="discipline" element={<Outlet />}>
							<Route path="student/:exam_id" element={<StudentDiscipline />} />
						</Route>
					</Route>

					<Route path="/teaching" element={<Outlet />}>
						<Route path="classes" element={
							<RoleRedirect roles={["Headmaster", "Second Master", "Academic Master", "Teacher", "Temporary Teacher"]} children={<Outlet />} />
						}>
							<Route index element={<TeachingClasses />} />
							<Route path='students' element={<TeachingClassStudents />} />
						</Route>
						<Route path="results" element={
							<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "Headmaster", "Second Master", "Academic Master", "Teacher", "Temporary Teacher"]} children={<Outlet />} />
						}>
							<Route index element={<TeachingResultsExams />} />
							{/* id => selected exam id */}
							<Route path=":exam_id/" element={<TeachingResultsSubjects />} />
							{/* page where results are uploaded */}
							<Route path="upload/:exam_subject_id/" element={<TeachingResultsUpload />} />
						</Route>

						<Route path="learning-materials" element={<Outlet />}>
							<Route index element={<TeachingLearningMaterials />} />
							<Route path="subject-topic-coverage" element={<Outlet />}>
								<Route index element={<SubjectTopicCoverage />} />
								<Route path="view" element={
									<RoleRedirect roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} children={<Outlet />} />
								}>
									<Route index element={<BareSubjectTopics />} />
									<Route path=":topicId" element={<Outlet />}>
										<Route index element={<BareSubjectTopic />} />
										<Route path="new" element={<NewSubTopic />} />
									</Route>
									<Route path="new" element={<NewSubjectTopic />} />
								</Route>
								<Route path="new" element={
									<RoleRedirect roles={["Admin"]} children={<NewSubjectTopicCoverage />} />
								} />
								<Route path="report" element={<TopicCoverageReports type={"district"} />} />
								<Route path="view/:formId/:subjectId" element={<Outlet />}>
									<Route index element={<ViewSubjectTopics />} />
									<Route path=":topicId" element={<ViewSubTopics />} />
									<Route path=":topicId/:subtopicId" element={<UpdateTopicCoverage />} />
									<Route path="report" element={<TopicCoverageReports type={"subject"} />} />
								</Route>
								<Route path="approve" element={
									<RoleRedirect roles={["Headmaster", "Second Master", "Academic Master", "Teacher"]} children={<ApproveSubjectTopicCoverage />} />
								} />
							</Route>
							<Route path="teachers-logbook" element={<Outlet />}>
								<Route index element={<TeachersLogbook />} />
								<Route path=":formId/:subjectId" element={<SubjectLogBook />} />
							</Route>
							<Route path="past-papers" element={<Outlet />}>
								<Route index element={<PastPapers />} />
								<Route path=":formId/:examType" element={<PastPapersDownload />} />
								<Route path="exams" element={
									<RoleRedirect roles={["Admin"]} children={<Outlet />} />
								}>
									<Route index element={<PastPaperExams />} />
									<Route path="new" element={<NewPastPaperExams purpose={"new"} />} />
									<Route path=":examId" element={<PastPaperExam />}>
										<Route index element={<BarePastPapers />} />
										<Route path="new" element={<NewPastPapers />} />
										<Route path=":pastPaperId" element={<PastPaper />} />
										<Route path=":pastPaperId/edit" element={<EditPastPaper />} />
										{/* edit the past paper exam */}
										<Route path="edit" element={<NewPastPaperExams purpose={"edit"} />} />
									</Route>
								</Route>
							</Route>
							<Route path="notes" element={<Outlet />}>
								<Route index element={<LessonNotes purpose={"notes"} />} />
								<Route path="new" element={
									<RoleRedirect roles={["Admin"]} children={<NewLessonNotes purpose={"notes"} />} />
								} />
								<Route path=":formId/:subjectId" element={<LessonNotesDownload purpose={"notes"} />} />
								<Route path=":noteId" element={
									<RoleRedirect roles={["Admin"]} children={<LessonNote purpose={"notes"} />} />
								} />
							</Route>
							<Route path="books" element={<Outlet />}>
								<Route index element={<LessonNotes purpose={"books"} />} />
								<Route path="new" element={
									<RoleRedirect roles={["Admin"]} children={<NewLessonNotes purpose={"books"} />} />
								} />
								<Route path=":formId/:subjectId" element={<LessonNotesDownload purpose={"books"} />} />
								<Route path=":noteId" element={
									<RoleRedirect roles={["Admin"]} children={<LessonNote purpose={"books"} />} />
								} />
							</Route>
							<Route path="syllabus" element={<Outlet />}>
								<Route index element={<Syllabus />} />
								<Route path="new" element={
									<RoleRedirect roles={["Admin"]} children={<NewLessonNotes purpose={"syllabus"} />} />
								} />
								<Route path="subject/:subjectId" element={<LessonNotesDownload purpose={"syllabus"} />} />
								<Route path=":noteId" element={
									<RoleRedirect roles={["Admin"]} children={<LessonNote purpose={"syllabus"} />} />
								} />
							</Route>
						</Route>
					</Route>

					<Route path="/students" element={<Outlet />}>
						<Route path='all' element={<Students />} />
						<Route path=":id" element={<ViewStudent />} />
						<Route path="new" element={
							<RoleRedirect roles={["Headmaster", "Second Master", "Academic Master"]} children={<NewStudents />} />}
						/>
						<Route path="transfers"
							element={
								<RoleRedirect
									roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster", "Second Master"]}
									children={<Outlet />}
								/>
							}
						>
							<Route index element={<StudentTransfer />} />
							<Route path="request" element={<StudentTransferRequest />} />
						</Route>
						<Route path="disqualified"
							element={
								<RoleRedirect
									roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster", "Second Master"]}
									children={<Outlet />}
								/>
							}
						>
							<Route index element={<DisqualifiedStudents />} />
							<Route path="request/:studentId" element={<NewDisqualifiedStudents />} />
						</Route>
						<Route path="attendance" element={<Outlet />}>
							<Route index element={<Attendance />} />
							<Route path="record" element={<RecordAttendance />} />
						</Route>
					</Route>

					<Route path="/administration" element={<Outlet />}>
						<Route path='designation' element={
							<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "Headmaster", "Second Master"]} children={<Designation />} />}
						/>
						<Route path='title' element={
							<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} children={<CreateResultsTitle />} />}
						/>
						<Route path='logo-stamps' element={
							<RoleRedirect roles={["Headmaster", "Second Master"]} children={<LogoAndStamps />} />}
						/>
						<Route path='announcements' element={
							<RoleRedirect roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK", "Headmaster", "Second Master"]} children={<Announcements />} />}
						/>
						<Route path='facilities' element={<Outlet />}>
							<Route path='bare' element={
								<RoleRedirect roles={["Admin"]} children={<BareFacilities />} />
							} />
							<Route path='bare/new' element={
								<RoleRedirect roles={["Admin"]} children={<NewFacilities />} />
							} />
							<Route path='bare/:facilityId' element={
								<RoleRedirect roles={["Admin"]} children={<BareFacility />} />
							} />
							<Route path='scope' element={
								<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"]} children={<Outlet />} />
							}>
								<Route index element={<Facilities />} />
								<Route path=':facilityId' element={<SingleFacility />} />
							</Route>
							<Route path='scope/manage' element={
								<RoleRedirect roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} children={<BareFacilities />} />
							} />
							<Route path='school' element={
								<RoleRedirect roles={["Headmaster", "Second Master"]} children={<Outlet />} />
							}>
								<Route index element={<SchoolFacilities />} />
								<Route path=':facilityId' element={<FillSchoolFacility />} />
							</Route>
						</Route>
					</Route>

					<Route path="/settings" element={<Outlet />}>
						<Route path="" element={
							<RoleRedirect
								roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK", "Headmaster", "Second Master"]}
								children={<Outlet />}
							/>
						}>
							<Route index element={<Settings />} />
							<Route path="sms/delivery-report/:smsUsageId" element={<DeliveryReport />} />
						</Route>
						<Route path="profile" element={<Profile />} />
						<Route path="password" element={<PasswordUpdate />} />
					</Route>

					<Route path="/locations" element={
						<RoleRedirect roles={["Admin"]} children={<Outlet />} />
					}>
						<Route index element={<Locations />} />
						<Route path="new" element={<NewLocation />} />
					</Route>

					{/* users are different from employees/staff */}
					<Route path="/users" element={
						<RoleRedirect roles={["Admin"]} children={<Outlet />} />
					}>
						<Route index element={<NewStaff />} />
					</Route>

					{
						/* SMS management, different form SMS settings in user accounts. 
						Here we set price, approve orders and other SMS administrative tasks. */
					}
					<Route path="/sms" element={
						<RoleRedirect roles={["Admin"]} children={<Outlet />} />
					}>
						<Route index element={<SMSManagement />} />
						<Route path="delivery-report/:smsUsageId" element={<DeliveryReport />} />
					</Route>

					<Route path="/subscriptions" element={
						<RoleRedirect roles={["Admin"]} children={<Outlet />} />
					}>
						<Route index element={<Subscriptions />} />
						<Route path="new" element={<NewSubscription />} />
						<Route path=":id" element={<Subscription />} />
						<Route path=":id/record" element={<RecordPayment />} />
					</Route>

					<Route path="*" element={<NotFound />} />
				</Route>

				{/* public routes */}
				<Route path="/public" element={<Public />}>
					<Route path="necta" element={<Outlet />}>
						<Route index element={<NectaResultsAnalysisRequest />} />
					</Route>

					{/* merge these into single component, there's some unnecessarily repeatetive code */}
					<Route path="login" element={<Login />} />
					<Route path="password-reset" element={<PasswordReset />} />
					<Route path="password-reset-new" element={<PasswordResetNew />} />
				</Route>

			</Routes>
		</AuthProvider>
	)
}

export default App
