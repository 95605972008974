import { createContext, useState, useContext } from "react"
import { jwtDecode } from 'jwt-decode'
import { BASE_API_URL } from "../utils/constants";

export const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
	const [token, setToken] = useState(localStorage.getItem("token"))
	const [refreshToken, setRefreshToken] = useState(localStorage.getItem("refresh_token"))

	const login = (token_string, refresh_token) => {
		const user = jwtDecode(token_string)
		localStorage.setItem('user', JSON.stringify(user))
		localStorage.setItem('token', token_string)
		localStorage.setItem('refresh_token', refresh_token)
		setUser(user)
		setToken(token_string)
		setRefreshToken(refresh_token)
	}

	const logout = async () => {
		try {
			const response = await fetch(`${BASE_API_URL}/logout`, {
				headers: {
					'x-access-token': token
				}
			})

			localStorage.removeItem('user')
			localStorage.removeItem('token')
			localStorage.removeItem('refresh_token')
			setUser(null)
			setToken(null)
		}
		catch (error) {
			// nothing to do for now
		}
	}

	const refresh = async () => {
		const resp = await fetch(`${BASE_API_URL}/refresh`, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				"refresh_token": refreshToken
			})
		})

		if (!resp.ok) {
			return false
		}
		else {
			const d = await resp.json()
			localStorage.setItem('token', d.token)
			const user = jwtDecode(d.token)
			localStorage.setItem('user', JSON.stringify(user))
			setToken(d.token)
			setUser(user)
			return true
		}
	}

	const isLoggedIn = () => {
		try {
			const loggedinUser = JSON.parse(localStorage.getItem('user'))
			if (loggedinUser === null) {
				return false
			}

			const isExpired = loggedinUser.exp * 1000 < Date.now()
			if (isExpired) {
				return false
			}
			return true
		}
		catch (err) {
			return false
		}
	}

	const updateProfile = (profile) => {
		setUser({ ...user, profile: profile })
	}

	const updateLevel = async (level) => {
		// only AEK can update level
		if (!["AEK"].includes(user.role)) {
			return
		}

		try {
			const response = await fetch(`${BASE_API_URL}/level/update?level=${level}`, {
				headers: {
					'x-access-token': token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				return
			}

			let receivedLevel = data?.level
			let temp = { ...user, level: receivedLevel }

			// update their has A level status
			if (receivedLevel === "ORDINARY") {
				temp = { ...temp, has_a_level: true }
			}

			setUser(temp)
			localStorage.setItem('user', JSON.stringify(temp))
			// reload the app
			window.location.reload()
		}
		catch (error) {
			// do nothing
		}
	}

	const updateAcademicYear = async (year) => {
		year = Number(year)

		try {
			const response = await fetch(`${BASE_API_URL}/academic-year/update?year=${year}`, {
				headers: {
					'x-access-token': token
				}
			})

			if (!response.ok) {
				return
			}

			let new_academic_years = []
			user?.academic_years?.forEach(academic_year => {
				let isSelected = false

				if (academic_year.year === year) {
					isSelected = true
				}

				new_academic_years.push({ ...academic_year, "is_selected": isSelected })
			})

			let temp = { ...user, academic_years: new_academic_years }
			setUser(temp)
			localStorage.setItem('user', JSON.stringify(temp))
			// reload the app
			window.location.reload()
		}
		catch (error) {
			// do nothing
		}
	}

	const updateHasALevel = (has_a_level) => {
		const temp = { ...user, has_a_level: has_a_level }
		setUser(temp)
		localStorage.setItem('user', JSON.stringify(temp))
	}

	const contextValue = {
		user,
		selected_academic_year: user?.academic_years?.find(year => year.is_selected)?.year,
		token,
		refreshToken,
		setToken,
		setRefreshToken,
		login,
		logout,
		refresh,
		isLoggedIn,
		updateProfile,
		updateLevel,
		updateAcademicYear,
		updateHasALevel
	}

	return (
		<AuthContext.Provider value={contextValue}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => {
	const contextValue = useContext(AuthContext)
	return contextValue
}
