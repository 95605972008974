import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { convertDateFormat } from '../../../utils/date_conversions'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import LevelSplit from '../../../components/offices/LevelSplit'
import toast from 'react-hot-toast'

function NewNectaExam() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/necta/exams`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    type,
                    name,
                    startDate,
                    endDate
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error('Failed to register exam. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Register NECTA Exam</p>
            <div className='shadow p-2'>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='type' className='form-label'>Exam Type</label>
                                <select
                                    className='form-select rounded-0'
                                    id='type'
                                    required
                                    disabled={isLoading}
                                    value={type}
                                    onChange={e => setType(e.target.value)}
                                >
                                    <option value=''>Select Exam Type</option>
                                    <option value="SFNA">SFNA</option>
                                    <option value="PSLE">PSLE</option>
                                    <option value="FTNA">FTNA</option>
                                    <option value="CSEE">CSEE</option>
                                    <option value="ACSEE" disabled>ACSEE</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='name' className='form-label'>Exam Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='name'
                                    placeholder='Mid Term Exam'
                                    required
                                    disabled={isLoading}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='start_date' className='form-label'>Start Date</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='start_date'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(startDate)}
                                    onChange={e => setStartDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='end_date' className='form-label'>End Date</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='end_date'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(endDate)}
                                    onChange={e => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1 text-end">
                        <button type='submit' className='btn btn-primary px-4 rounded-0 fw-bold' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Registering... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Register
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewNectaExam
