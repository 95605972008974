import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { Link } from 'react-router-dom'
import RoleOffice from '../../components/offices/RoleOffice'
import toast from 'react-hot-toast'

function BareFacilities() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [facilities, setFacilities] = useState([])
    const [selectedFacilities, setSelectedFacilities] = useState([])

    const columns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Facility",
            selector: row => row.name,
            sortable: true,
            grow: 2
        },
        {
            name: "Action",
            cell: row => <div>
                <RoleOffice roles={["Admin"]} element={
                    <Link
                        to={`${row.id}`}
                        state={{ facility: row }}
                        className='btn btn-sm btn-primary rounded-0'>
                        <i className='bi bi-eye-fill fs-6 mx-1'></i>
                    </Link>
                } />
                <RoleOffice roles={["Admin"]} reverse={true} element={
                    <button
                        className={`btn btn-sm btn${selectedFacilities.includes(row.id) || row.scope ? '' : '-outline'}-primary rounded-0`}
                        disabled={row.scope}
                        onClick={() => {
                            if (!selectedFacilities.includes(row.id)) {
                                setSelectedFacilities([...selectedFacilities, row.id])
                            }
                            else {
                                const newSelection = selectedFacilities.filter(fac => fac != row.id)
                                setSelectedFacilities(newSelection)
                            }
                        }}
                    >
                        <span className="me-2">
                            {selectedFacilities.includes(row.id) || row.scope ? "Selected" : "Select"}
                        </span>
                    </button>
                } />
            </div>,
            sortable: false
        }
    ]

    const fetchFacilities = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/facilities/bare`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setFacilities(data.facilities)
            }
        }
        catch (error) {
            toast.error("Failed to fetch facilities details")
        }
        finally {
            setIsLoading(false)
        }
    }

    const manageFacilityScope = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/facilities/scope`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({selectedFacilities})
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to update selected facilities. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchFacilities()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <div>
                    <p className='h3 fw-bold text-muted'>Bare Facilities</p>
                    <RoleOffice
                        roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]}
                        element={<span>Select facilities that you want to keep track of</span>}
                    />

                </div>
                <RoleOffice roles={["Admin"]} element={
                    <Link
                        to={'new'}
                        className="btn btn-sm btn-primary rounded-0 fw-bold"
                    >
                        <span className="me-2">New</span>
                        <i className="bi bi-plus-square"></i>
                    </Link>
                } />
            </div>

            <div className="shadow p-2 rounded-0">
                <DataTable
                    title={<div className="text-end">
                        <RoleOffice roles={["Admin"]} reverse={true} element={
                            <button
                                className="btn btn-sm btn-primary rounded-0 fw-bold"
                                disabled={isLoading || selectedFacilities.length < 1}
                                onClick={e => manageFacilityScope()}
                            >
                                <span className="me-2">Save</span>
                                <i className="bi bi-floppy2"></i>
                            </button>
                        } />
                    </div> }
                    columns={columns}
                    data={facilities}
                    progressPending={isLoading}
                    progressComponent={<>Loading... <Loading /></>}
                />
            </div>

        </div>
    )
}

export default BareFacilities
