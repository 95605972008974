import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import DivisionPerformance from '../../components/results/DivisionPerformance'
import SubjectPerformance from '../../components/results/SubjectPerformance'
import StudentPerformance from '../../components/results/StudentPerformance'
import ResultsTitle from '../../components/results/ResultsTitle'
import { useReactToPrint } from 'react-to-print'
import { saveAs } from 'file-saver'
import LevelSplit from '../../components/offices/LevelSplit'
import DivisionPerformancePrimary from '../../components/results/DivisionPerformancePrimary'
import toast from 'react-hot-toast'

function OverallResults() {
    const auth = useAuth()
    const params = useParams()
    const exam_id = params.exam_id
    const location = useLocation()

    const contentRef = useRef()

    const [isLoading, setIsLoading] = useState(false)
    const [exam, setExam] = useState(location.state.exam)
    const [form, setForm] = useState(location.state.form)
    const [school, setSchool] = useState(location.state.school)
    const [results, setResults] = useState({})
    const [isNectaFormat, setIsNectaFormat] = useState(false)
    const [orderByPosition, setOrderByPosition] = useState(false)

    const handlePrint = useReactToPrint({
        documentTitle: `MSSIS-${exam.name}-School Results`,
        removeAfterPrint: true,
    })

    const handleExport = async () => {
        setIsLoading(true)

        try {
            let format = isNectaFormat ? "necta" : "normal"
            let order = orderByPosition ? "position" : "name"
            let url = `${BASE_API_URL}/results/export/${exam_id}/${form.id ? form.id : form}/${order}`

            if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
                url += `?school=${exam.participants[0].id}&format=${format}`
            }

            // school is only passed for external exams
            if (school) {
                url = `${BASE_API_URL}/results/external/export/${exam_id}/${form.id ? form.id : form}/${school.id}/${order}`
                url += `?format=${format}`
            }

            if (!school && !["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"].includes(auth.user.role)) {
                url += `?format=${format}`
            }

            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            const filename = response.headers.get("x-filename")
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error("Failed to export results to PDF. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchResults = async () => {
        setIsLoading(true)

        try {
            let order = orderByPosition ? "position" : "name"
            let url = `${BASE_API_URL}/results/analysis/${exam_id}/${form.id ? form.id : form}/${order}`

            if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
                url += `?school=${exam.participants[0].id}`
            }

            // school is only passed for external exams
            if (school) {
                url = `${BASE_API_URL}/results/external/analysis/${exam_id}/${form.id ? form.id : form}/${school.id}/${order}`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setResults(data.results)
            }
        }
        catch (error) {
            toast.error('Failed to fetch exam results. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchResults()
    }, [orderByPosition])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-end mb-3">
                <div className={`d-flex align-items-center form-group border border-primary rounded-0 me-2 px-2 py-1`}>
                    <label htmlFor="order" className='me-2'>Order by Position</label>
                    <input
                        id='order'
                        type="checkbox"
                        className='form-check rounded-0'
                        checked={orderByPosition}
                        disabled={isLoading}
                        onChange={e => setOrderByPosition(!orderByPosition)}
                    />
                </div>
                <div className={`d-flex align-items-center form-group border border-primary rounded-0 me-2 px-2 py-1`}>
                    <label htmlFor="check" className='me-2'>NECTA Format</label>
                    <input
                        id='check'
                        type="checkbox"
                        className='form-check rounded-0'
                        checked={isNectaFormat}
                        disabled={isLoading}
                        onChange={e => setIsNectaFormat(!isNectaFormat)}
                    />
                </div>
                <button
                    className='btn btn-sm btn-primary rounded-0'
                    disabled={isLoading}
                    onClick={() => {
                        // handlePrint(null, () => contentRef.current)
                        handleExport()
                    }}
                >
                    <span className="me-2">Export to PDF</span>
                    <i className='bi bi-file-earmark-pdf'></i>
                </button>
            </div>

            {
                isLoading ?
                    (
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <>
                            {
                                results.division_performance && results.subject_performance && results.student_ranking &&
                                <div className="body shadow p-2 mb-3" id="printContent" ref={contentRef}>
                                    <div className="mb-3">
                                        <ResultsTitle exam={exam} form={form} school={results.school} nectaFormat={isNectaFormat} />
                                    </div>
                                    <div className="mb-3">
                                        <LevelSplit
                                            primary={
                                                <DivisionPerformancePrimary
                                                    summary={results.division_performance}
                                                    bottom={results.subject_performance.bottom_summary}
                                                    nectaFormat={isNectaFormat}
                                                />
                                            }
                                            ordinary={
                                                <DivisionPerformance
                                                    summary={results.division_performance}
                                                    nectaFormat={isNectaFormat}
                                                />
                                            }
                                        />
                                    </div>
                                    {
                                        !isNectaFormat &&
                                        <div className="mb-3">
                                            <SubjectPerformance
                                                summary={results.subject_performance}
                                                nectaFormat={isNectaFormat}
                                                level={exam.level === "ADVANCE" ? "A" : ""}
                                            />
                                        </div>
                                    }
                                    <div className="mb-3">
                                        <StudentPerformance summary={results.student_ranking} nectaFormat={isNectaFormat} />
                                    </div>
                                    {
                                        isNectaFormat &&
                                        <div className="mb-3">
                                            <SubjectPerformance
                                                summary={results.subject_performance}
                                                nectaFormat={isNectaFormat}
                                                level={exam.level === "ADVANCE" ? "A" : ""}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </>
                    )
            }
        </div>
    )
}

export default OverallResults
