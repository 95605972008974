import React, { useState } from 'react'
import { useAuth } from '../../../../context/AuthContext'
import { PAST_PAPER_EXAM_TYPES } from './PastPaperExams'
import Loading from '../../../../components/loading/Loading'
import { BASE_API_URL } from '../../../../utils/constants'
import { useLocation } from 'react-router-dom'
import { convertDateFormat } from '../../../../utils/date_conversions'
import toast from 'react-hot-toast'

function NewPastPaperExams({ purpose }) {
    const auth = useAuth()
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [exam, setExam] = useState(location?.state?.exam || {
        "title": "",
        "type": "",
        "level": "",
        "start_date": "",
        "end_date": ""
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/past-paper-exams`, {
                method: purpose === "edit" ? "PUT" : "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({ ...exam })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to register past paper exam. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>
                {purpose === "edit" ? "Edit" : "Register"} Past Paper Exam
            </p>

            <div className='shadow p-2 mb-3'>
                <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                        <label htmlFor="title" className="form-label">
                            Title <strong className='text-danger ms-1'>*</strong>
                        </label>
                        <input
                            id="title"
                            type="text"
                            className="form-control rounded-0"
                            placeholder="e.g Simiyu Regional Mock"
                            required
                            value={exam["title"]}
                            onChange={e => setExam({ ...exam, "title": e.target.value })}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="type" className="form-label">
                            Type <strong className='text-danger ms-1'>*</strong>
                        </label>
                        <select
                            id="type"
                            className="form-select rounded-0"
                            required
                            value={exam["type"]}
                            onChange={e => setExam({ ...exam, "type": e.target.value })}
                        >
                            <option value="">Select exam type</option>
                            {
                                PAST_PAPER_EXAM_TYPES.map((ex, index) => (
                                    <option value={ex.value} key={index}>{ex.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="level" className="form-label">
                            Section <strong className='text-danger ms-1'>*</strong>
                        </label>
                        <select
                            id="level"
                            className="form-select rounded-0"
                            required
                            value={exam["level"]}
                            onChange={e => setExam({ ...exam, "level": e.target.value })}
                        >
                            <option value="">Select section</option>
                            <option value="PRIMARY">Primary</option>
                            <option value="ORDINARY">Ordinary</option>
                            <option value="ADVANCE">Advance</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="start_date" className="form-label">Start Date</label>
                        <input
                            type="date"
                            id="start_date"
                            className="form-control rounded-0"
                            min={'1990-01-01'}
                            value={convertDateFormat(exam["start_date"])}
                            onChange={e => setExam({ ...exam, "start_date": e.target.value })}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="end_date" className="form-label">End Date</label>
                        <input
                            type="date"
                            id="end_date"
                            className="form-control rounded-0"
                            min={'1990-01-01'}
                            value={convertDateFormat(exam["end_date"])}
                            onChange={e => setExam({ ...exam, "end_date": e.target.value })}
                        />
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <span>
                            <strong className='text-danger ms-1'>*</strong> indicates required fields
                        </span>
                        <button type='submit' className='btn btn-primary fw-bold rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>{purpose === "edit" ? "Saving" : "Registering..."} <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            {purpose === "edit" ? "Save" : "Register"}
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewPastPaperExams
