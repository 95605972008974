import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import RoleOffice from '../../../components/offices/RoleOffice'
import { Link } from 'react-router-dom'
import Loading from '../../../components/loading/Loading'
import { saveAs } from 'file-saver'
import { PAST_PAPER_EXAM_TYPES } from './admin/PastPaperExams'
import toast from 'react-hot-toast'

function PastPapers() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [classes, setClasses] = useState([])

    const fetchClasses = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/classes`
            if (auth.user.role === "Admin") {
                url += '/bare'
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setClasses(data.classes)
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchClasses()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Past Papers</p>

            {isLoading && <div className="text-center">Loading...<Loading /></div>}

            {
                !isLoading &&
                <div className="mb-3">
                    {
                        classes?.map((form, index) => (
                            <div className="shadow rounded-0 p-2 mb-3" key={index}>
                                <p className='text-muted fw-bold'>{form?.name}</p>
                                <div className="row mx-0">
                                    {
                                        PAST_PAPER_EXAM_TYPES.map((exam, id) => (
                                            <div className="col-6 col-lg-2 mb-3" key={id}>
                                                <Link
                                                    to={`${form.id}/${exam.value}`}
                                                    state={{form}}
                                                >
                                                    {exam.name}
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default PastPapers
