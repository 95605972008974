import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import LevelSplit from '../../components/offices/LevelSplit'
import { BASE_API_URL } from '../../utils/constants'
import { formatDate } from '../../utils/date_conversions'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import toast from 'react-hot-toast'

function SaveButton({ onclick }) {
    return (
        <div className="text-end mb-3">
            <button
                className="btn btn-primary rounded-0 fw-bold"
                onClick={onclick}
            >
                <span className="me-2">Save</span>
                <i className="bi bi-floppy2"></i>
            </button>
        </div>
    )
}

function RecordAttendance() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState({ form: "", stream: "" })
    const [forms, setForms] = useState([])
    const [students, setStudents] = useState([])
    const [attendees, setAttendees] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: <LevelSplit primary={'PREM No'} ordinary={'PREMS No'} />,
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: <LevelSplit primary={'Class'} ordinary={'Form'} />,
            selector: row => row.form,
            sortable: true,
        },
        {
            name: <div>
                <input
                    type="checkbox"
                    className="rounded-0 me-2"
                    checked={students.length === attendees.length}
                    onChange={e => {
                        if (students.length === attendees.length) {
                            setAttendees([])
                        }
                        else {
                            setAttendees(students.map(s => s.id))
                        }
                    }}
                />
                ({attendees.length})
            </div>,
            cell: row =>
                <div className='d-flex'>
                    <button
                        type="button"
                        className={`btn btn-sm btn-${!attendees.includes(row.id) ? 'outline-' : ''}primary rounded-0 me-2`}
                        onClick={() => {
                            if (!attendees.includes(row.id)) {
                                setAttendees([...attendees, row.id])
                            }
                            else {
                                setAttendees(attendees.filter(m => m !== row.id))
                            }
                        }}
                    >
                        {attendees.includes(row.id) ? "Present" : "Absent"}
                    </button>
                </div>,
            sortable: false,
        }
    ]

    const fetchForms = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            toast.error('Failed to fetch forms. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchStudents = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/students?per_page=10000&form=${filters.form}&stream=${filters.stream}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setStudents(data.students)
            }
        }
        catch (error) {
            toast.error('Failed to fetch students. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/attendance/${filters.form}?stream=${filters.stream}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({ attendees })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchForms()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>
                Attendance: {formatDate(null, "dd/mm/yyyy")}
            </p>

            <div className="shadow rounded-0 p-2 mb-3">
                <form className="row mx-0" onSubmit={e => {
                    e.preventDefault()
                    fetchStudents()
                }}>
                    <div className="col-12 col-lg-3 mb-2">
                        <label htmlFor='form' className='form-label'>Form</label>
                        <select
                            id="form"
                            className="form-select rounded-0"
                            required
                            value={filters.form}
                            onChange={e => setFilters({ ...filters, form: e.target.value })}
                        >
                            <option value="">Select class</option>
                            {
                                forms.filter(f => auth.user.class_teacher.includes(f.name)).map(form => (
                                    <option key={form.id} value={form.id}>{form.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-12 col-lg-3 mb-2">
                        <label htmlFor='stream' className='form-label'>Stream</label>
                        <select
                            id="stream"
                            className="form-select rounded-0"
                            required
                            value={filters.stream}
                            onChange={e => setFilters({ ...filters, stream: e.target.value })}
                        >
                            <option value="">Select stream</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="G">G</option>
                        </select>
                    </div>
                    <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                        <button type='submit' className='btn btn-primary rounded-0 mt-4 fw-bold'>
                            <span className="me-2">Fetch</span>
                            <i className='bi bi-funnel-fill'></i>
                        </button>
                    </div>
                </form>
            </div>

            <div className="shadow rounded-0 p-2 mb-3">
                <DataTable
                    className='mb-3'
                    title={<SaveButton onclick={handleSubmit} />}
                    columns={columns}
                    data={students}
                    progressPending={isLoading}
                    progressComponent={<div className="text-center">Loading... <Loading /></div>}
                    noDataComponent={<LevelSplit primary={"No pupils found."} ordinary={"No students found."} />}
                />
                {students.length > 10 && <SaveButton onclick={handleSubmit} />}
            </div>
        </div>
    )
}

export default RecordAttendance
