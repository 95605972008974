import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import LoginCountChart from '../../components/charts/LoginCountChart'
import Card from '../../components/cards/Card'
import ContextSwitch from '../../components/navbar/ContextSwitch'
import toast from 'react-hot-toast'

function Dashboard() {
	const auth = useAuth()
	const location = useLocation()
	const error = location.state ? location.state.message : ''
	
	const toastId = "dashboard"
	const [isLoading, setIsLoading] = useState(true)

	const [cards, setCards] = useState([])
	const [quickLinks, setQuickLinks] = useState([])
	const [loginCount, setLoginCount] = useState([])

	if (location?.state?.message) {
		toast.error(location?.state?.message, {id: toastId})
	}

	const fetchDashboard = async () => {
		setIsLoading(true)

		try {
			const resp = await fetch(`${BASE_API_URL}/dashboard`, {
				headers: {
					'x-access-token': auth.token
				}
			})

			const data = await resp.json()
			if (!resp.ok) {
				toast.error("Failed to fetch dashboard data. Please, try again.", {id: toastId})
				return
			}

			setCards(data.cards)
			setQuickLinks(data.quick_links)
			setLoginCount(data.login_count)
		}
		catch (error) {
			toast.error("Failed to fetch dashboard data. Please, try again.", {id: toastId})
		}
		finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchDashboard()
	}, [])

	return (
		<div className='pt-2'>
			<div className="shadow p-2 mb-4 d-flex flex-wrap align-items-center justify-content-between">
				<span className='fw-bold text-muted'>
					Welcome, {auth.user.firstname} {auth.user.lastname}
				</span>
				<div>
					<ContextSwitch isLoading={isLoading} setIsLoading={setIsLoading} orientation={"horizontal"} />
				</div>
			</div>

			{
				isLoading ?
					(
						<div className="text-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					)
					:
					(
						<>
							{
								cards && cards.length > 0 && (
									<div className='row mx-0 mb-4'>
										{
											cards.map((card, index) => (
												<Card key={index} card={card} />
											))
										}
									</div>
								)
							}

							{
								quickLinks && quickLinks.length > 0 && (
									<div className="shadow p-2 mb-4">
										<span className='fw-bold text-muted mb-3'>
											Quick Links
										</span>

										<div className="d-flex flex-wrap align-items-center justify-content-start my-2">
											{
												quickLinks.map((link, index) => (
													<Link key={index} to={link.url} className="fw-bold py-1 px-2 me-4">
														<span className="me-2">
															<i className={`bi bi-${link.icon}`}></i>
														</span>
														{link.title}
													</Link>
												))
											}
										</div>
									</div>
								)
							}

							{
								loginCount && loginCount.length > 0 && (
									<div className="shadow p-2 mb-4">
										<span className='fw-bold text-muted mb-3'>
											Login Count
										</span>
										<LoginCountChart data={loginCount} />
									</div>
								)
							}
						</>
					)
			}
		</div>
	)
}

export default Dashboard
