import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import { convertDateFormat } from '../../utils/date_conversions'
import toast from 'react-hot-toast'

function EditSchool() {
    const auth = useAuth()
    const params = useParams()
    const schoolId = params.id

    const toastId = "EditSchool"
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [wards, setWards] = useState([])

    const [name, setName] = useState('')
    const [reg_no, setRegNo] = useState('')
    const [school_reg_no, setSchoolRegNo] = useState('')
    const [location, setLocation] = useState('')
    const [ownership, setOwnership] = useState('')
    const [date_launched, setDateLaunched] = useState('')

    const fetchWards = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/wards/none`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                setWards(data.wards)
            }
        }
        catch (error) {
            toast.error("Failed to fetch wards. Please try again.", {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSchool = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/${schoolId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                setName(data.school.name)
                setRegNo(data.school.reg_no)
                setSchoolRegNo(data.school.school_reg_no)
                setLocation(data.school.location)
                setOwnership(data.school.ownership)
                setDateLaunched(data.school.date_launched)
            }
        }
        catch (error) {
            toast.error('Failed to fetch school. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/${schoolId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    name,
                    reg_no,
                    school_reg_no,
                    location,
                    ownership,
                    date_launched
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                toast.success(data.message, {id: toastId})
            }
        } catch (error) {
            toast.error("Failed to update school. Please try again.", {id: toastId})
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchWards()
        fetchSchool()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Edit School Details</p>
            <div className='shadow p-2'>
                <form onSubmit={handleEdit}>
                    <p className='h4 mb-3 text-muted'>School Info</p>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='name'>Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='name'
                                    required
                                    disabled={isLoading}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='reg_no' className='form-label'>NECTA Registration Number</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='reg_no'
                                    placeholder='eg. S3881'
                                    required
                                    disabled={isLoading}
                                    value={reg_no}
                                    onChange={e => setRegNo(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='school_reg_no' className='form-label'>School Registration Number</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='school_reg_no'
                                    placeholder='eg. EM.12201'
                                    required
                                    disabled={isLoading}
                                    value={school_reg_no}
                                    onChange={e => setSchoolRegNo(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='location' className='form-label'>Location (Ward)</label>
                                <select
                                    className='form-select rounded-0'
                                    id='location'
                                    required
                                    disabled={isLoading}
                                    value={location}
                                    onChange={e => setLocation(e.target.value)}
                                >
                                    <option value={""}>Select Ward</option>
                                    {
                                        wards?.map((ward, index) => (
                                            <option key={index} value={ward.name}>{ward.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='ownership' className='form-label'>Ownership</label>
                                <select
                                    className='form-select rounded-0'
                                    id='ownership'
                                    required
                                    disabled={isLoading}
                                    value={ownership}
                                    onChange={e => setOwnership(e.target.value)}
                                >
                                    <option value=''>Select ownership</option>
                                    <option value='GOVERNMENT'>Government</option>
                                    <option value='PRIVATE'>Private</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label htmlFor='date_launched' className='form-label'>Date Launched</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='date_launched'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(date_launched)}
                                    onChange={e => setDateLaunched(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Saving... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Save
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditSchool
