import React from 'react'
import './table-styles.css'
import LevelSplit from '../offices/LevelSplit'

function StudentPerformance({ summary, nectaFormat }) {
    return (
        <div>
            {
                nectaFormat ?
                    <div className="table-responsive">
                        <table className='text-center necta-format'>
                            <thead>
                                <tr>
                                    <th>CNO</th>
                                    <th><LevelSplit primary={"PREM NO"} ordinary={"PREMS NO"} /></th>
                                    <th>NAME OF CANDIDATE</th>
                                    <th>SEX</th>
                                    <LevelSplit
                                        primary={null}
                                        ordinary={
                                            <>
                                                <th>AGGT</th>
                                                <th>DIV</th>
                                            </>
                                        }
                                    />
                                    <th className='text-start'>DETAILED SUBJECTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    summary.ranks.map((rank, index) => (
                                        <tr key={index}>
                                            <td>
                                                {rank.index_number}
                                            </td>
                                            <td>
                                                {rank.student.prems_number}
                                            </td>
                                            <td className='text-start'>
                                                {`${rank.student.firstname} ${rank.student.middlename} ${rank.student.lastname}`.toUpperCase()}
                                            </td>
                                            <td>
                                                {rank.student.sex[0]}
                                            </td>
                                            <LevelSplit
                                                primary={null}
                                                ordinary={
                                                    <>
                                                        <td>
                                                            {
                                                                rank.division.class === "ABS" ? "-" : rank.division.points
                                                            }
                                                        </td>
                                                        <td>
                                                            {rank.division.class}
                                                        </td>
                                                    </>
                                                }
                                            />
                                            <td className='text-start pe-5'>
                                                {
                                                    summary.subjects.map((subject, index) => {
                                                        const filteredSubject = rank.subjects.filter(sub => sub.name === subject.name)[0]
                                                        return filteredSubject ? `${subject.abbreviation.toUpperCase()} - '${filteredSubject?.grade}' ` : ''
                                                    })
                                                }
                                                Average Grade - '{rank.grade}'
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover nowrap">
                            <thead>
                                <tr>
                                    <th rowSpan={3}>
                                        <span className="vertical-text">S/N</span>
                                    </th>
                                    <th rowSpan={3}>NAME OF CANDIDATE</th>
                                    <th rowSpan={3}>
                                        <span className='vertical-text'>SEX</span>
                                    </th>
                                    <th rowSpan={3}>
                                        <span className='vertical-text'>INDEX NUMBER</span>
                                    </th>
                                    <th rowSpan={3}>
                                        <span className='vertical-text'>REGISTERED SUBJECTS</span>
                                    </th>
                                    <th colSpan={summary.subjects.length * 2}>GENERAL SUBJECTS</th>
                                    <th rowSpan={3}>
                                        <span className='vertical-text'>TOTAL MARKS</span>
                                    </th>
                                    <th rowSpan={3}>
                                        <span className='vertical-text'>AVERAGE</span>
                                    </th>
                                    <th rowSpan={3}>
                                        <span className='vertical-text'>GRADE</span>
                                    </th>
                                    <LevelSplit
                                        primary={null}
                                        ordinary={
                                            <>
                                                <th rowSpan={2} colSpan={2}>
                                                    <span className='vertical-text'>DIVISION</span>
                                                </th>
                                                <th rowSpan={3}>
                                                    <span className="vertical-text">GPA</span>
                                                </th>
                                            </>
                                        }
                                    />
                                    <th rowSpan={3}>
                                        <span className='vertical-text'>POSITION</span>
                                    </th>
                                </tr>
                                <tr>
                                    {
                                        summary.subjects.map((subject, index) => (
                                            <React.Fragment key={index}>
                                                <th colSpan={2}>
                                                    <span className='vertical-text'>{subject.name.toUpperCase()}</span>
                                                </th>
                                            </React.Fragment>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    {
                                        summary.subjects.map((subject, index) => (
                                            <React.Fragment key={index}>
                                                <th>
                                                    <span className='vertical-text'>MARKS</span>
                                                </th>
                                                <th>
                                                    <span className='vertical-text'>GRADE</span>
                                                </th>
                                            </React.Fragment>
                                        ))
                                    }
                                    <LevelSplit
                                        primary={null}
                                        ordinary={
                                            <>
                                                <th>
                                                    <span className='vertical-text'>POINTS</span>
                                                </th>
                                                <th>
                                                    <span className='vertical-text'>CLASS</span>
                                                </th>
                                            </>
                                        }
                                    />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    summary.ranks.map((rank, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className='text-start'>
                                                {`${rank.student.firstname} ${rank.student.middlename} ${rank.student.lastname}`.toUpperCase()}
                                            </td>
                                            <td>{rank.student.sex[0]}</td>
                                            <td>
                                                {rank.index_number}
                                            </td>
                                            <td>
                                                {rank.subjects_count}
                                            </td>
                                            {
                                                summary.subjects.map((subject, index) => {
                                                    const filteredSubjects = rank.subjects.filter(sub => sub.name === subject.name);
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <td>
                                                                {filteredSubjects.length > 0 ? filteredSubjects[0].score : ' '}
                                                            </td>
                                                            <td>
                                                                {filteredSubjects.length > 0 ? filteredSubjects[0].grade : ' '}
                                                            </td>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                            <td>
                                                {rank.total}
                                            </td>
                                            <td>
                                                {rank.average}
                                            </td>
                                            <td>
                                                {rank.grade}
                                            </td>
                                            <LevelSplit
                                                primary={null}
                                                ordinary={
                                                    <>
                                                        <td>
                                                            {
                                                                rank.division.class === "ABS" ? "-" : rank.division.points
                                                            }
                                                        </td>
                                                        <td>
                                                            {rank.division.class}
                                                        </td>
                                                        <td>
                                                            {rank.gpa}
                                                        </td>
                                                    </>
                                                }
                                            />
                                            <td>
                                                {rank.position}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default StudentPerformance
