import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { saveAs } from 'file-saver'
import RoleOffice from '../../components/offices/RoleOffice'
import toast from 'react-hot-toast'

function StaffCount() {
    const auth = useAuth()

    const toastId = "StaffCount"
    const [isLoading, setIsLoading] = useState(true)

    const [staffsCount, setStaffsCount] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row?.school?.name || "Total",
            sortable: true,
            grow: 2,
        },
        {
            name: 'Level',
            selector: row => row?.school?.level || "",
            sortable: true,
        },
        {
            name: 'Female',
            selector: row => row.female,
            sortable: true
        },
        {
            name: 'Male',
            selector: row => row.male,
            sortable: true
        },
        {
            name: 'Total',
            selector: row => row.total,
            sortable: true,
            grow: 2,
        },
    ]

    const fetchStaffsCount = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/count/staffs`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error("Failed to fetch staffs count. Please, try again.", {id: toastId})
            }

            setStaffsCount(data.count)
        }
        catch (error) {
            toast.error("Something went wrong. Please, try again.", {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleExport = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/count/staffs?export=1`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message, {id: toastId})
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }

            const filename = `${suff} Teachers' Statistics ${new Date().toISOString().split('T')[0]}.pdf`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error("Something went wrong. Please, try again.", {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStaffsCount()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <p className='h3 fw-bold text-muted'>Staffs Count</p>
                <RoleOffice roles={["Admin"]} reverse={true} element={
                    <button
                        className="btn btn-sm btn-primary rounded-0"
                        disabled={isLoading}
                        onClick={handleExport}
                    >
                        <span className="me-2">Export to PDF</span>
                        <i className="bi bi-file-earmark-pdf"></i>
                    </button>
                } />
            </div>

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={staffsCount}
                            highlightOnHover
                        />
                }
            </div>
        </div>
    )
}

export default StaffCount
