import React from 'react'

function DivisionPerformancePrimary({ summary, bottom, nectaFormat }) {
    return (
        <div>
            {
                nectaFormat ?
                    <div>
                        <div className="mb-3">
                            <span className="necta-format-title">
                                WALIOFANYA MTIHANI: {summary["data"]["T"]["total"] - summary["data"]["T"]["absent"]}
                            </span>
                            <br />
                            <span className="necta-format-title">
                                WASTANI WA SHULE: {bottom["average"]}
                                &nbsp;
                                <span className={`grade-${bottom["grade"]}`}>DARAJA {bottom["grade"]} ({bottom["remarks"]})</span>
                            </span>
                        </div>
                        <div className="table-responsive">
                            <table className='text-center necta-format'>
                                <caption>
                                    MADARAJA YA UFAULU WA UJUMLA
                                </caption>
                                <thead>
                                    <tr>
                                        <th>JINSI</th>
                                        {
                                            summary?.grades?.map((grade, index) => (
                                                <th key={index}>{grade.grade}</th>
                                            ))
                                        }
                                        {/* <th>ABS</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [
                                            { value: "F", name: "WASICHANA" },
                                            { value: "M", name: "WAVULANA" },
                                            { value: "T", name: "JUMLA" }
                                        ].map((gen, index) => (
                                            <tr key={index}>
                                                <td className='fw-bold text-start'>{gen["name"]}</td>
                                                {
                                                    summary?.grades?.map((grade, ind) => (
                                                        <td key={ind} className='px-3'>{summary["data"][gen["value"]][grade.grade]}</td>
                                                    ))
                                                }
                                                {/* <td>{summary["data"][gen]?.absent}</td> */}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <div className="table-responsive">
                        <table className="table table-bordered border-dark text-center table-warning table-sm">
                            <thead>
                                <tr>
                                    <th colSpan={2 + (summary?.grades?.length || 0)}>PERFORMANCE SUMMARY</th>
                                </tr>
                                <tr>
                                    <th>SEX</th>
                                    {
                                        summary?.grades?.map((grade, index) => (
                                            <th key={index}>{grade.grade}</th>
                                        ))
                                    }
                                    <th>ABSENT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ["F", "M", "T"].map((gen, index) => (
                                        <tr key={index}>
                                            <td className='fw-bold'>{gen}</td>
                                            {
                                                summary?.grades?.map((grade, ind) => (
                                                    <td key={ind} className='px-3'>{summary["data"][gen][grade.grade]}</td>
                                                ))
                                            }
                                            <td>{summary["data"][gen]?.absent}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default DivisionPerformancePrimary
