import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import toast from 'react-hot-toast'

function FillSchoolFacility() {
    const auth = useAuth()
    const params = useParams()
    const facilityId = params.facilityId

    const [isLoading, setIsLoading] = useState(true)
    const [facility, setFacility] = useState({})

    const fetchFacility = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/facilities/${facilityId}/school`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setFacility(data.facility)
            }
        }
        catch (error) {
            toast.error("Failed to fetch facility details. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const updateFacility = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/facilities/${facilityId}/school`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({facility})
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to update facility details. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchFacility()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>
                Facility: {facility?.name}
            </p>

            <div className="shadow p-2 rounded-0 mb-3">
                <form onSubmit={updateFacility}>
                    <div className="form-group mb-3">
                        <label htmlFor="required" className="form-label">Required</label>
                        <input
                            type="number"
                            id="required"
                            className="form-control rounded-0"
                            disabled={isLoading}
                            required
                            value={facility?.required}
                            onChange={e => setFacility({ ...facility, required: e.target.value })}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="available" className="form-label">Present</label>
                        <input
                            type="number"
                            id="available"
                            className="form-control rounded-0"
                            disabled={isLoading}
                            required
                            value={facility?.available}
                            onChange={e => setFacility({ ...facility, available: e.target.value })}
                        />
                    </div>
                    <div className="mb-3 text-end">
                        <button className="btn btn-primary rounded-0 fw-bold">
                            <span className="me-2">Update</span>
                            <i className="bi bi-floppy2"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FillSchoolFacility
