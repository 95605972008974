import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import toast from 'react-hot-toast'

function SMSManageUsage() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [usageHistory, setUsageHistory] = useState([])

    const usageColumns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            sortable: true,
            grow: 0
        },
        {
            name: "SMS Used",
            selector: row => row.sms_spent,
            sortable: true
        },
        {
            name: "SMS Receivers",
            selector: row => row.receivers_count,
            sortable: true
        },
        {
            name: "Date Sent",
            selector: row => row.date_sent,
            sortable: true
        },
        {
            name: "Description",
            selector: row => row.description,
            sortable: true,
            grow: 4
        },
        {
            name: "Staff Name",
            selector: row => row.staff,
            sortable: true
        },
        {
            name: "District",
            selector: row => row.district,
            sortable: true
        },
        {
            name: "Ward",
            selector: row => row.ward,
            sortable: true
        },
        {
            name: "School",
            selector: row => row.school,
            sortable: true
        },
        {
            name: "Action",
            cell: row => (
                <div className='d-flex'>
                    <Link
                        to={`delivery-report/${row.id}`}
                        className='btn btn-sm btn-primary rounded-0 me-2'
                    >
                        <i className='bi bi-eye-fill fs-6 mx-1'></i>
                    </Link>
                </div>
            ),
            sortable: false
        }
    ]

    const fetchUsages = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/sms/usage`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setUsageHistory(data.usage_history)
            }
        }
        catch (error) {
            toast.error("Failed to fetch SMS usages. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchUsages()
    }, [])

    return (
        <div className="mb-3">
            <p className="h5 fw-bold text-muted">SMS Usage</p>            
            <DataTable
                columns={usageColumns}
                data={usageHistory}
                progressPending={isLoading}
                progressComponent={<>Loading...<Loading /></>}
                noDataComponent={"No SMS usage history."}
            />
        </div>
    )
}

export default SMSManageUsage
