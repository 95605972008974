import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import toast from 'react-hot-toast'

function RecordPayment() {
    const auth = useAuth()
    const params = useParams()
    const subId = params.id

    const [isLoading, setIsLoading] = useState(false)
    const [amount, setAmount] = useState("")
    const [type, setType] = useState("")
    const [date, setDate] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subscriptions/${subId}/payment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({ amount, type, date })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Subscription Payment</p>
            <div className='shadow p-2 mb-4'>
                <form onSubmit={handleSubmit}>
                    <div className='form-group mb-3'>
                        <label htmlFor='amount' className='form-label'>Amount</label>
                        <input
                            type='number'
                            className='form-control rounded-0'
                            id='amount'
                            placeholder='e.g 100000'
                            min={10000}
                            required
                            disabled={isLoading}
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="type" className="form-label">Type</label>
                        <select
                            name="type"
                            id="type"
                            className="form-select rounded-0"
                            required
                            value={type}
                            onChange={e => setType(e.target.value)}
                        >
                            <option value="">Select fee type</option>
                            <option value="Entry">Entry Fee</option>
                            <option value="Recurring">Recurring Fee</option>
                        </select>
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor='date' className='form-label'>Date</label>
                        <input
                            type='date'
                            className='form-control rounded-0'
                            id='date'
                            required
                            disabled={isLoading}
                            value={date}
                            onChange={e => setDate(e.target.value)}
                        />
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Saving... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Save
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RecordPayment
