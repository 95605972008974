import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import toast from 'react-hot-toast'

function NewFacilities() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    const [key, setKey] = useState("")
    const [hidden, setHidden] = useState(false)

    const registerFacility = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/facilities/bare`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({
                    name,
                    key,
                    hidden
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to register new facility.")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>New Facility</p>
            <div className="shadow p-2 rounded-0 mb-3">
                <form onSubmit={registerFacility}>
                    <div className="form-group mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input 
                            type="text" 
                            id="name"
                            className="form-control rounded-0" 
                            disabled={isLoading}
                            required
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="key" className="form-label">Key</label>
                        <input 
                            type="text" 
                            id="key"
                            className="form-control rounded-0" 
                            disabled={isLoading}
                            required
                            value={key}
                            onChange={e => setKey(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="hidden" className="form-label me-3">Hidden</label>
                        <input 
                            type="checkbox" 
                            id="hidden"
                            className="form-check rounded-0" 
                            checked={hidden}
                            onChange={e => setHidden(!hidden)}
                        />
                    </div>
                    <div className="mb-3 text-end">
                        <button type="submit" className="btn btn-primary fw-bold rounded-0">
                            <span className="me-2">Register</span>
                            <i className="bi bi-floppy2"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewFacilities
