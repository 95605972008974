import React, { useState } from 'react'
import { useAuth } from '../../../../context/AuthContext'
import { Link, useLocation } from 'react-router-dom'
import { BASE_API_URL } from '../../../../utils/constants'
import { saveAs } from 'file-saver'
import toast from 'react-hot-toast'

function PastPaper() {
    const auth = useAuth()
    const location = useLocation()
    const pastPaper = location?.state?.paper

    const [isLoading, setIsLoading] = useState(false)

    const handleDelete = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/past-papers/${pastPaper?.id}`, {
                method: "DELETE",
                headers: {
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to delete past paper. Please try again")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleDownload = async (markingScheme) => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/past-papers/${pastPaper.id}/download`
            url += `?marking_scheme=${markingScheme}`
            const response = await fetch(url, {
                headers: {
                    "x-access-token": auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            // save file
            let fileType = markingScheme ? pastPaper.marking_scheme_file_type : pastPaper.file_type
            let filename = `MSSIS ${pastPaper.form.name} ${pastPaper.subject.name}.${fileType}`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error("Failed to download file. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <p className='h4 fw-bold text-muted mb-3'>
                    {pastPaper?.form?.name} {pastPaper?.subject?.name} {pastPaper?.practical}
                </p>
                <div className="d-flex flex-wrap justify-content-between">
                    <button
                        className="btn btn-danger btn-sm fw-bold rounded-0 me-2"
                        disabled={isLoading}
                        onClick={handleDelete}
                    >
                        <span className="me-2">Delete</span>
                        <i className="bi bi-trash"></i>
                    </button>
                    <Link
                        to={"edit"}
                        state={{paper: pastPaper}}
                        className="btn btn-primary btn-sm fw-bold rounded-0"
                    >
                        <span className="me-2">Edit</span>
                        <i className="bi bi-pencil-square"></i>
                    </Link>
                </div>
            </div>

            <div className="shadow rounded-0 p-2 mb-3">
                <p className='text-muted'>
                    Date Done: <span className='fw-bold'>{pastPaper?.date_done}</span>
                </p>
                <p className="text-muted">
                    Marking Scheme:&nbsp;
                    <span className="fw-bold">
                        {
                            pastPaper?.has_marking_scheme ? "Available" : "Not Available"
                        }
                    </span>
                </p>
                <div className="mb-3">
                    <button
                        className="btn btn-primary rounded-0 fw-bold me-2"
                        disabled={isLoading}
                        onClick={() => handleDownload(false)}
                    >
                        <span className="me-2">Exam</span>
                        <i className="bi bi-download"></i>
                    </button>
                    <button
                        className="btn btn-primary rounded-0 fw-bold me-2"
                        disabled={!pastPaper?.has_marking_scheme || isLoading}
                        onClick={() => handleDownload(true)}
                    >
                        <span className="me-2">Marking Scheme</span>
                        <i className="bi bi-download"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PastPaper
