import React, { useEffect, useState } from 'react'
import RoleOffice from '../../../components/offices/RoleOffice'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'

function SubjectTopicCoverage() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10)

    const [filterSchool, setFilterSchool] = useState("")
    const [filterForm, setFilterForm] = useState("")
    const [filterSubject, setFilterSubject] = useState("")

    const [schools, setSchools] = useState([])
    const [forms, setForms] = useState([])
    const [subjects, setSubjects] = useState([])
    const [classes, setClasses] = useState([])
    const [totalClasses, setTotalClasses] = useState(0)

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        ["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role) &&
        {
            name: 'School Name',
            selector: row => row.school.name,
            sortable: true
        },
        {
            name: 'Class Name',
            selector: row => row.form.name,
            sortable: true
        },
        {
            name: 'Subject Name',
            selector: row => row.subject.name,
            sortable: true
        },
        {
            name: 'Progress',
            selector: row => row.progress,
            sortable: true
        },
        {
            name: 'Actions',
            cell: row => <div>
                <Link
                    to={`view/${row.form.id}/${row.subject.id}`}
                    state={{ form: row.form, subject: row.subject, school: row.school }}
                    className='btn btn-sm btn-primary rounded-0'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            button: true
        }
    ]

    const fetchSchools = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            toast.error('Failed to fetch schools. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchForms = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            toast.error('Failed to fetch schools. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchFilterSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            toast.error('Failed to fetch schools. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSubjects = async (cp, pp, fs, ff, fsub) => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/subject-topic/class-subjects?page=${cp}&per_page=${pp}&school=${fs}&form=${ff}&subject=${fsub}`
            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setClasses(data.classes)
                setTotalClasses(data.total_classes)
                setCurrentPage(data.page)
                setPerPage(data.per_page)
            }
        }
        catch (error) {
            toast.error('Failed to fetch classes. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
            fetchSchools()
        }

        // prevent useles fetching done that could be done by admin
        if (auth.user.role !== "Admin") {
            fetchSubjects(currentPage, perPage, filterSchool, filterForm, filterSubject)
            fetchForms()
            fetchFilterSubjects()
        }
    }, [])

    useEffect(() => {
        if (auth.user.role !== "Admin") {
            fetchSubjects(currentPage, perPage, filterSchool, filterForm, filterSubject)
        }
    }, [currentPage, perPage])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Subject Topic Coverage</p>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <RoleOffice roles={["Admin", "DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} element={
                        <Link to='view' className='btn btn-primary btn-sm fw-bold rounded-0 me-2'>
                            <span className="me-2">View Subject Topics</span>
                            <i className='bi bi-list-task'></i>
                        </Link>
                    } />

                    <RoleOffice roles={["Admin"]} element={
                        <Link to='new' className='btn btn-primary btn-sm fw-bold rounded-0 me-2'>
                            <span className="me-2">Register</span>
                            <i className='bi bi-plus-square-fill'></i>
                        </Link>
                    } />

                    <RoleOffice roles={["Headmaster", "Second Master", "Academic Master", "Teacher"]} element={
                        <Link
                            to={'approve'}
                            className='btn btn-primary btn-sm rounded-0 fw-bold me-2'
                        >
                            <RoleOffice roles={["Headmaster", "Second Master", "Academic Master"]} element={
                                <span className="me-2">Sign and Approve</span>
                            } />
                            <RoleOffice roles={["Teacher"]} element={
                                <span className="me-2">Approval Status</span>
                            } />
                            <i className='bi bi-patch-check-fill'></i>
                        </Link>
                    } />

                    <Link to={"report"} className='btn btn-primary btn-sm rounded-0 fw-bold'>
                        <span className="me-2">View Report</span>
                        <i className='bi bi-file-earmark-text-fill'></i>
                    </Link>
                </div>
            </div>

            <RoleOffice roles={["Admin"]} reverse={true} element={
                <div className="shadow p-2 mb-3">
                    <p className="h5 text-muted">Select Class and Subject</p>

                    <div className="row mx-0 ">
                        <RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"]} element={
                            <div className="col-12 col-lg-3 mb-2">
                                <label htmlFor='school' className='form-label'>School</label>
                                <select
                                    id='school'
                                    className='form-select rounded-0'
                                    value={filterSchool}
                                    onChange={e => setFilterSchool(e.target.value)}
                                >
                                    <option value=''>All schools</option>
                                    {
                                        schools?.map((school, index) => (
                                            <option key={index} value={school.id}>{school.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        } />
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='class' className='form-label'>Class</label>
                            <select
                                id='class'
                                className='form-select rounded-0'
                                value={filterForm}
                                onChange={e => setFilterForm(e.target.value)}
                            >
                                <option value=''>All Classes</option>
                                {
                                    forms?.map((form, index) => (
                                        <option key={index} value={form.id}>{form.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='subject' className='form-label'>Subject</label>
                            <select
                                id='subject'
                                className='form-select rounded-0'
                                value={filterSubject}
                                onChange={e => setFilterSubject(e.target.value)}
                            >
                                <option value=''>All Subjects</option>
                                {
                                    subjects?.map((subject, index) => (
                                        <option key={index} value={subject.id}>{subject.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end mt-4">
                            <button
                                type='button'
                                className='btn btn-primary rounded-0'
                                onClick={() => {
                                    fetchSubjects(currentPage, perPage, filterSchool, filterForm, filterSubject)
                                }}
                            >
                                <span className="me-2">Filter</span>
                                <i className='bi bi-funnel-fill'></i>
                            </button>
                        </div>
                    </div>

                    <DataTable
                        columns={columns}
                        data={classes}
                        progressPending={isLoading}
                        progressComponent={
                            <div className="text-center">
                                Loading... <Loading />
                            </div>
                        }
                        pagination
                        paginationServer
                        paginationTotalRows={totalClasses}
                        onChangePage={page => setCurrentPage(page)}
                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 70, 150, 250, 500]}
                        noDataComponent={"No classes found."}
                    />
                </div>
            } error={
                <div className="text-center">Nothing for you here.</div>
            } />
        </div>
    )
}

export default SubjectTopicCoverage
