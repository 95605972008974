import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import toast from 'react-hot-toast'

function BareFacility() {
    const auth = useAuth()
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [facility, setFacility] = useState(location?.state?.facility)

    const updateFacility = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/facilities/bare/${facility?.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify(facility)
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to update facility.")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Facility: {facility?.name}</p>
            <div className="shadow p-2 rounded-0 mb-3">
                <form onSubmit={updateFacility}>
                    <div className="form-group mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            id="name"
                            className="form-control rounded-0"
                            disabled={isLoading}
                            required
                            value={facility?.name}
                            onChange={e => setFacility({ ...facility, name: e.target.value })}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="key" className="form-label">Key</label>
                        <input
                            type="text"
                            id="key"
                            className="form-control rounded-0"
                            disabled={isLoading}
                            required
                            value={facility?.key}
                            onChange={e => setFacility({ ...facility, key: e.target.value })}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="hidden" className="form-label me-3">Hidden</label>
                        <input
                            type="checkbox"
                            id="hidden"
                            className="form-check rounded-0"
                            checked={facility?.is_hidden}
                            onChange={e => setFacility({ ...facility, is_hidden: !facility?.is_hidden })}
                        />
                    </div>
                    <div className="mb-3 text-end">
                        <button type="submit" className="btn btn-primary fw-bold rounded-0">
                            <span className="me-2">Save</span>
                            <i className="bi bi-floppy2"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BareFacility
