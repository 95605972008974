import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import { useLocation, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

function NewCombination({ isEditing }) {
    const auth = useAuth()
    const params = useParams()
    const combinationId = params?.id
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [subjects, setSubjects] = useState([])
    const [combination, setCombination] = useState(location?.state?.combination || {
        "name": "", "order": "", "category": "", "subjects": [{ "id": "", "name": "", "is_core": false }]
    })

    const fetchSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            toast.error('Failed to fetch subjects. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        if (combination?.subjects?.length < 3) {
            toast.error("A combination must have at least 3 subjects.")
            return
        }

        try {
            let url = `${BASE_API_URL}/combinations/bare`
            let method = 'POST'

            if (isEditing) {
                url = `${BASE_API_URL}/combinations/bare/${combinationId}`
                method = 'PUT'
            }

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify(combination)
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        } catch (error) {
            toast.error("Failed to register combination. Please try again.")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>
                {isEditing ? 'Edit' : 'Register'} Combination
            </p>
            <div className='shadow p-2'>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='name'>Combination Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='name'
                                    placeholder='e.g PCM'
                                    required
                                    disabled={isLoading}
                                    value={combination?.name}
                                    onChange={e => setCombination({ ...combination, name: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='order'>Combination Order</label>
                                <input
                                    type='number'
                                    className='form-control rounded-0'
                                    id='order'
                                    placeholder='e.g 1'
                                    required
                                    disabled={isLoading}
                                    value={combination?.order}
                                    onChange={e => setCombination({ ...combination, order: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='category'>Combination Category</label>
                                <select
                                    id="category"
                                    className='form-select rounded-0'
                                    required
                                    disabled={isLoading}
                                    value={combination?.category}
                                    onChange={e => setCombination({ ...combination, category: e.target.value })}
                                >
                                    <option value="">Choose category</option>
                                    <option value="Science">Science</option>
                                    <option value="Art">Art</option>
                                    <option value="Business">Business</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {
                        combination?.subjects?.map((subject, index) => (
                            <div className="row mx-0" key={index}>
                                <div className="col-8 mb-2">
                                    <label htmlFor={`subject${index}`} className="form-label">Subject</label>
                                    <select
                                        id={`subject${index}`}
                                        className="form-select rounded-0"
                                        value={subject?.id}
                                        onChange={e => {
                                            let temp = combination?.subjects
                                            temp[index]["id"] = e.target.value
                                            setCombination({ ...combination, subjects: temp })
                                        }}
                                    >
                                        <option value="">Select subject</option>
                                        {
                                            subjects.filter(sub => sub.level === "ADVANCE").map((sub, ind) => (
                                                <option value={sub?.id} key={ind}>{sub?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-4 mb-2">
                                    <label htmlFor={`is_core${index}`} className="form-label">Is Core?</label>
                                    <select
                                        id={`is_core${index}`}
                                        className="form-select rounded-0"
                                        value={subject?.is_core}
                                        onChange={e => {
                                            let temp = combination?.subjects
                                            temp[index]["is_core"] = e.target.value
                                            setCombination({ ...combination, subjects: temp })
                                        }}
                                    >
                                        <option value="">Select yes if is core</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                            </div>
                        ))
                    }

                    <div className="text-end mb-3">
                        <button
                            className="btn btn-secondary rounded-0 me-2"
                            type="button"
                            onClick={() => {
                                let temp = combination?.subjects
                                temp = [...temp, { "id": "", "name": "", "is_core": false }]
                                setCombination({ ...combination, subjects: temp })
                            }}
                        >
                            Add Subject
                        </button>
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>
                                        {isEditing ? "Saving..." : "Registering..."} <Loading />
                                    </>
                                    :
                                    <>
                                        <span className="me-2">
                                            {isEditing ? "Save" : "Register"}
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewCombination
