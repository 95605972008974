import React, { Fragment, useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import StudentsCountHeader from '../../components/dashboard/StudentsCountHeader'
import StudentsCountContent from '../../components/dashboard/StudentsCountContent'
import LevelSplit from '../../components/offices/LevelSplit'
import RoleOffice from '../../components/offices/RoleOffice'
import { saveAs } from 'file-saver'
import toast from 'react-hot-toast'

function StudentsTable({ forms, students_count }) {
    const displayForms = [...forms.map(f => f.name), "total"]

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th rowSpan={2} style={{ width: "16px" }}>S/N</th>
                        <th rowSpan={2}>School</th>
                        {
                            forms.map(form => (
                                <th colSpan={3} key={form.id} className="text-center">
                                    {form.name}
                                </th>
                            ))
                        }
                        <th colSpan={3} className="text-center">Total</th>
                    </tr>
                    <tr>
                        {
                            displayForms.map((form, index) => (
                                <Fragment key={index}>
                                    <th className="text-center">F</th>
                                    <th className="text-center">M</th>
                                    <th className="text-center">T</th>
                                </Fragment>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        students_count?.map((school, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{school?.school?.name || "Total"}</td>
                                {
                                    displayForms.map((form, index) => {
                                        const { female = 0, male = 0, total = 0 } = school[form] || {}
                                        return (
                                            <Fragment key={index}>
                                                <td className="text-center">{female}</td>
                                                <td className="text-center">{male}</td>
                                                <td className="text-center">{total}</td>
                                            </Fragment>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

function StudentsCount() {
    const auth = useAuth()

    const toastId = "StudentsCount"
    const [isLoading, setIsLoading] = useState(true)

    const [studentsCount, setStudentsCount] = useState({})
    const [forms, setForms] = useState([])

    const fetchForms = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            toast.error('Failed to fetch forms. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchStudentCount = async () => {
        setIsLoading(true)

        try {
            const resp = await fetch(`${BASE_API_URL}/count/students`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await resp.json()
            if (!resp.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                setStudentsCount(data.count)
            }
        }
        catch (error) {
            toast.error('Something went wrong. Please, try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleExport = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/count/students?export=1`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message, {id: toastId})
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }

            const filename = `${suff} Students' Statistics ${new Date().toISOString().split('T')[0]}.pdf`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error("Something went wrong. Please, try again.", {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchForms()
        fetchStudentCount()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <p className='h3 fw-bold text-muted'>
                    <LevelSplit primary={"Pupils"} ordinary={"Students"} /> Count
                </p>
                <RoleOffice roles={["Admin"]} reverse={true} element={
                    <button
                        className="btn btn-sm btn-primary rounded-0"
                        disabled={isLoading}
                        onClick={handleExport}
                    >
                        <span className="me-2">Export to PDF</span>
                        <i className="bi bi-file-earmark-pdf"></i>
                    </button>
                } />
            </div>

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <>
                            <RoleOffice roles={["Admin"]} reverse={true} element={
                                <StudentsTable
                                    forms={forms.filter(f => (f.level === auth.user.level || (f.level === "ADVANCE" && auth.user.level === "ORDINARY")))}
                                    students_count={studentsCount ? studentsCount[auth.user.level] : []}
                                />
                            } />

                            <RoleOffice roles={["Admin"]} element={
                                <>
                                    <StudentsTable
                                        forms={forms.filter(f => f.level === "PRIMARY")}
                                        students_count={studentsCount ? studentsCount["PRIMARY"] : []}
                                    />
                                    <div>
                                        <p className='h3 fw-bold text-muted'>
                                            Students Count
                                        </p>
                                        <StudentsTable
                                            forms={forms.filter(f => f.level !== "PRIMARY")}
                                            students_count={studentsCount ? studentsCount["ORDINARY"] : []}
                                        />
                                    </div>
                                </>
                            } />
                        </>
                }
            </div>
        </div>
    )
}

export default StudentsCount
