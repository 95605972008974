import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import { PAST_PAPER_EXAM_TYPES } from './admin/PastPaperExams'
import { saveAs } from 'file-saver'
import toast from 'react-hot-toast'

function PastPapersDownload() {
    const auth = useAuth()
    const params = useParams()
    const formId = params.formId
    const examType = params.examType
    const location = useLocation()
    const form = location?.state?.form

    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [totalPapers, setTotalPapers] = useState(0)
    const [papers, setPapers] = useState([])

    const fetchPastPapers = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/past-papers/${formId}/${examType}`
            url += `?page=${page}&per_page=${perPage}`
            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setPapers(data.papers)
                setPage(data.page)
                setPerPage(data.per_page)
                setTotalPapers(data.total)
            }
        }
        catch (error) {
            toast.error('Failed to fetch past paper exams. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleDownload = async (pastPaper, fileType, markingScheme) => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/past-papers/${pastPaper.id}/download`
            url += `?marking_scheme=${markingScheme}`
            const response = await fetch(url, {
                headers: {
                    "x-access-token": auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            // save file
            let filename = `MSSIS ${examType} ${pastPaper.form.name} ${pastPaper.subject.name}.${fileType}`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error("Failed to download file. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchPastPapers()
    }, [page, perPage])

    return (
        <div className='pt-2'>
            <p className='text-muted'>
                <span className="h3 fw-bold">Past Papers</span>
                <br />
                <span className="h4 fw-bold">
                    {form?.name} {PAST_PAPER_EXAM_TYPES.find(past => past.value == examType).name}
                </span>
            </p>

            {isLoading && <div className="text-center">Loading...<Loading /></div>}
            {!isLoading && papers.length < 1 && <div className="text-center">No past papers found.</div>}

            {
                !isLoading && <div> {
                    papers.map((exam, index) => (
                        <div className="shadow rounded-0 p-2 mb-3" key={index}>
                            <p className="text-muted fw-bold">{exam.year} {exam.title}</p>
                            <div className="row mx-0">
                                {
                                    exam?.papers?.map((paper, ind) => (
                                        <div className="col-12 col-lg-4 p-0 mb-2" key={ind}>
                                            <div className="me-1 border">
                                                <button
                                                    className="btn text-primary"
                                                    onClick={() => handleDownload(paper, paper.file_type, false)}
                                                    disabled={isLoading}
                                                >
                                                    {paper.subject.name} {paper?.practical}
                                                </button>
                                                {
                                                    paper.has_marking_scheme &&
                                                    <>
                                                        |
                                                        <button
                                                            className="btn text-primary"
                                                            onClick={() => handleDownload(paper, paper.marking_scheme_file_type, true)}
                                                            disabled={isLoading}
                                                        >
                                                            Marking Scheme
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                } </div>
            }
        </div>
    )
}

export default PastPapersDownload
