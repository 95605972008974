import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import RoleOffice from '../../../components/offices/RoleOffice'
import { BASE_API_URL } from '../../../utils/constants'
import { Link } from 'react-router-dom'
import Loading from '../../../components/loading/Loading'
import toast from 'react-hot-toast'

function TeachersLogbook() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [selectedSchool, setSelectedSchool] = useState(sessionStorage.getItem("selectedSchool") || "")
    const [schools, setSchools] = useState([])
    const [formSubjects, setFormSubjects] = useState([])

    const fetchSchools = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            toast.error("Failed to fetch schools. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchFromSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects/core-form-subjects?school=${selectedSchool}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setFormSubjects(data.core_subjects)
            }
        }
        catch (error) {
            toast.error('Failed to fetch class subjects. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
            fetchSchools()
        }
    }, [])

    useEffect(() => {
        sessionStorage.setItem("selectedSchool", selectedSchool)
        fetchFromSubjects()
    }, [selectedSchool])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Teacher's Subject Log book</p>
            
            <RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"]} element={
                <div className="shadow p-2 mb-3">
                    <form>
                        <div className="form-group">
                            <label htmlFor="school" className="form-label">Choose School</label>
                            <select 
                                id="school" 
                                className="form-select rounded-0" 
                                disabled={isLoading} 
                                required
                                value={selectedSchool}
                                onChange={e => setSelectedSchool(e.target.value)}
                            >
                                <option value="">Choose a school</option>
                                {
                                    schools?.map((school, index) => (
                                        <option key={index} value={school.id}>{school.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </form>
                </div>
            } />

            <div>
                {
                    isLoading && 
                    <div className="text-center mb-3">Loading... <Loading /></div>
                }
                {
                    // Programmers dont need math 🚮🚮🚮 I just used logic to simplify the below statement
                    // I just did it as a joke but when it actually worked it felt amazing 😇
                    // I know it's something small but the feeling is what counts!
                    (selectedSchool || !["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) &&
                    !isLoading && formSubjects?.map((fs, index) => (
                        <div key={index} className="shadow p-2 mb-3">
                            <p className="fw-bold text-muted">{fs.form.name} Log Book</p>
                            <div className="row mx-0">
                                {
                                    fs?.core_subjects?.map((subject, ind) => (
                                        <Link 
                                            to={`${fs.form.id}/${subject.id}`}
                                            state={{schoolId: selectedSchool}}
                                            key={ind} 
                                            className={`col col-auto fw-bold rounded-0 btn btn-primary me-2 mb-2 ${isLoading&&"disabled"}`}
                                        >
                                            {subject.name} | <i className="bi bi-check-lg"></i>
                                        </Link>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TeachersLogbook
