import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link } from 'react-router-dom'
import { BASE_API_URL } from '../../../utils/constants'
import DataTable from 'react-data-table-component'
import Loading from '../../../components/loading/Loading'
import RoleOffice from '../../../components/offices/RoleOffice'
import toast from 'react-hot-toast'

function BareSubjectTopics() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [filterForm, setFilterForm] = useState("")
    const [filterSubject, setFilterSubject] = useState("")

    const [topics, setTopics] = useState([])
    const [filteredTopics, setFilteredTopics] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Class',
            selector: row => row.form.name,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Subject',
            selector: row => row.subject.name,
            sortable: true
        },
        {
            name: 'Topic',
            selector: row => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Actions',
            cell: row => <div>
                <Link
                    to={`${row.id}`}
                    state={{ topic: row, form: row.form, subject: row.subject }}
                    className='btn btn-sm btn-primary rounded-0'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            button: true
        }
    ]

    const filterData = () => {
        let filtered = topics

        if (filterForm) {
            filtered = filtered.filter(item => item.form.id === filterForm)
        }

        if (filterSubject) {
            filtered = filtered.filter(item => item.subject.id === filterSubject)
        }

        setFilteredTopics(filtered)
    }

    const fetchTopics = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/bare`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setTopics(data.topics)
                setFilteredTopics(data.topics)
            }
        }
        catch (error) {
            toast.error('Failed to fetch topics. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTopics()
    }, [])

    useEffect(() => {
        filterData()
    }, [filterForm, filterSubject])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Registered Subject Topics</p>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <RoleOffice roles={["Admin"]} element={
                        <>
                            <Link to='new' className='btn btn-primary btn-sm fw-bold rounded-0 me-2'>
                                <span className="me-2">New Subject Topic</span>
                                <i className='bi bi-plus-square-fill'></i>
                            </Link>
                        </>
                    } />
                </div>
            </div>

            <div className="shadow p-2 mb-3">
                <p className="h5 text-muted">Select Topic</p>

                <div className="row mb-3">
                    <div className="col-12 col-lg-3 mb-2">
                        <label htmlFor='class' className='form-label'>Class</label>
                        <select
                            id='class'
                            className='form-select rounded-0'
                            value={filterForm}
                            onChange={e => setFilterForm(e.target.value)}
                        >
                            <option value=''>All Classes</option>
                            {
                                Array.from(new Set(topics?.map(cl => cl.form.id)))
                                    .map(id => {
                                        const uniqueClass = topics.find(cl => cl.form.id === id)
                                        return (
                                            <option key={uniqueClass.form.id} value={uniqueClass.form.id}>
                                                {uniqueClass.form.name}
                                            </option>
                                        )
                                    })
                            }
                        </select>
                    </div>
                    <div className="col-12 col-lg-3 mb-2">
                        <label htmlFor='subject' className='form-label'>Subject</label>
                        <select
                            id='subject'
                            className='form-select rounded-0'
                            value={filterSubject}
                            onChange={e => setFilterSubject(e.target.value)}
                        >
                            <option value=''>All Subjects</option>
                            {
                                Array.from(new Set(topics?.map(cl => cl.subject.id)))
                                    .map(id => {
                                        const uniqueClass = topics.find(cl => cl.subject.id === id)
                                        return (
                                            <option key={uniqueClass.subject.id} value={uniqueClass.subject.id}>
                                                {uniqueClass.subject.name}
                                            </option>
                                        )
                                    })
                            }
                        </select>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredTopics}
                    highlightOnHover
                    progressPending={isLoading}
                    progressComponent={<div className='text-center'>Loading... <Loading /></div>}
                    noDataComponent={"No topics found."}
                />
            </div>
        </div>
    )
}

export default BareSubjectTopics
