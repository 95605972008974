import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import LevelSplit from '../../components/offices/LevelSplit'
import toast from 'react-hot-toast'

function ExamCandidatesRegister({ purpose }) {
    const auth = useAuth()
    const params = useParams()
    const location = useLocation()
    const examId = params.exam_id

    const [isLoading, setIsLoading] = useState(true)
    const [errors, setErrors] = useState([])

    const [form, setForm] = useState(location?.state?.form || "")
    const [forms, setForms] = useState([])
    const [students, setStudents] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])

    const students_columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'PREMS No',
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Admission No',
            selector: row => row.admission_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name:
                <div>
                    <input
                        type='checkbox'
                        className='me-1'
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedStudents(students.map(subject => subject.id))
                            } else {
                                setSelectedStudents([])
                            }
                        }}
                    />
                    <span>({selectedStudents.length})</span>
                </div>,
            cell: row => <button
                type='button'
                className={`btn btn-sm rounded-0 ${selectedStudents.includes(row.id) ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => {
                    if (selectedStudents.includes(row.id)) {
                        setSelectedStudents(selectedStudents.filter(subjectId => subjectId !== row.id))
                    } else {
                        setSelectedStudents([...selectedStudents, row.id])
                    }
                }}>
                {selectedStudents.includes(row.id) ? 'Selected' : 'Select'}
            </button>,
            sortable: false,
        }
    ]

    const fetchForms = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            toast.error('Failed to fetch forms. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchStudents = async () => {
        setIsLoading(true)

        try {
            // per_page=10000 an arbitrary large number to fetch all students of the selected form
            let url = `${BASE_API_URL}/students?page=1&per_page=10000&form=${form}`
            if (purpose === "necta") {
                url = `${BASE_API_URL}/necta/exams/${examId}/students`
            }
            else if (purpose === "repeaters") {
                url += '&type=normal'
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setStudents(data.students)
            }
        }
        catch (error) {
            toast.error('Failed to fetch students. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleRegister = async () => {
        setIsLoading(true)

        if (selectedStudents.length === 0) {
            toast.error('Please select students to register.')
            setIsLoading(false)
            return
        }

        try {
            let url = `${BASE_API_URL}/exams/${examId}/candidates`
            if (purpose === "necta") {
                url = `${BASE_API_URL}/necta/exams/${examId}/candidates`
            }
            else if (purpose === "repeaters") {
                url = `${BASE_API_URL}/classes/repeaters`
            }

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    form: form,
                    candidates: selectedStudents
                })
            })
            const data = await response.json()
            if (!response.ok) {
                if (data.errors) {
                    setErrors(data.errors)
                }
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
                setSelectedStudents([])
            }
        }
        catch (error) {
            toast.error('Failed to register students. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (purpose === "repeaters") {
            fetchForms()
        }
        else {
            fetchStudents()
        }
    }, [])

    return (
        <div className='pt-2'>
            <p className='mb-3 h3 fw-bold text-muted'>
                {purpose !== "repeaters" ? `Register ${form} Candidates` : "Repeaters Registration"}
            </p>

            <div className="mb-3">
                {
                    errors.map((error, index) => (
                        <div className="alert alert-warning p-2 mb-1" key={index}>
                            {error}
                        </div>
                    ))
                }
            </div>

            <div className="shadow rounded-0 p-2 mb-3">
                {
                    purpose === "repeaters" &&
                    <div className="mb-3">
                        <form className="d-flex align-items-center" onSubmit={(e) => {
                            e.preventDefault()
                            fetchStudents()
                        }}>
                            <select
                                className="form-select rounded-0 me-1"
                                id="class"
                                name="class"
                                required
                                value={form}
                                onChange={e => setForm(e.target.value)}
                            >
                                <option value="">Select class/form</option>
                                {
                                    forms.map(form => (
                                        <option key={form.id} value={form.id}>{form.name}</option>
                                    ))
                                }
                            </select>
                            <button type='submit' className="btn btn-primary px-3 fw-bold rounded-0">
                                Fetch
                            </button>
                        </form>
                    </div>
                }

                <DataTable
                    title={
                        <div className='d-flex align-items-center justify-content-between'>
                            <span>
                                {students.length} <LevelSplit primary={"Pupils"} ordinary={"Students"} />
                            </span>
                            <button className='btn btn-primary rounded-0 fw-bold' onClick={handleRegister}>
                                <span className="me-2">Register</span>
                                <i className='bi bi-person-fill-add'></i>
                            </button>
                        </div>
                    }
                    columns={students_columns}
                    data={students}
                    progressPending={isLoading}
                    progressComponent={
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    }
                    noDataComponent={"No students found."}
                />
                <div className="text-end my-3">
                    <button className='btn btn-primary rounded-0 fw-bold' onClick={handleRegister}>
                        <span className="me-2">Register</span>
                        <i className='bi bi-person-fill-add'></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ExamCandidatesRegister
