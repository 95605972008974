import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { BASE_API_URL } from '../../../../utils/constants'
import { convertDateFormat } from '../../../../utils/date_conversions'
import Loading from '../../../../components/loading/Loading'
import toast from 'react-hot-toast'

function EditPastPaper() {
    const auth = useAuth()
    const location = useLocation()
    const pastPaper = location?.state?.paper

    const [isLoading, setIsLoading] = useState(false)
    const [classes, setClasses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [paper, setPaper] = useState({
        "form": pastPaper?.form?.id,
        "subject": pastPaper?.subject?.id,
        "hidden": pastPaper?.is_hidden,
        "date": pastPaper?.date_done,
        "practical": pastPaper?.practical
    })
    const [files, setFiles] = useState({ "exam": "", "marking_scheme": "" })

    const fetchClasses = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/classes`
            if (auth.user.role === "Admin") {
                url += '/bare'
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setClasses(data.classes)
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            toast.error('Failed to fetch subjects. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            let formData = new FormData()
            formData.append("form", paper["form"])
            formData.append("subject", paper["subject"])
            formData.append("hidden", paper["hidden"])
            formData.append("date", paper["date"])
            formData.append("practical", paper["practical"])
            formData.append("exam", files["exam"])
            formData.append("marking_scheme", files["marking_scheme"])

            const response = await fetch(`${BASE_API_URL}/past-papers/${pastPaper?.id}`, {
                method: "PUT",
                headers: {
                    "x-access-token": auth.token
                },
                body: formData
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to upload past paper. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchClasses()
        fetchSubjects()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h4 fw-bold text-muted mb-3'>
                Edit {pastPaper?.form?.name} {pastPaper?.subject?.name} {pastPaper?.practical}
            </p>

            <div className='shadow p-2 mb-3'>
                <form onSubmit={handleEdit} encType="multipart/form-data">
                    <div className="form-group mb-3">
                        <label htmlFor="form" className="form-label">
                            Class/Form <strong className='text-danger ms-1'>*</strong>
                        </label>
                        <select
                            id="form"
                            className="form-select rounded-0"
                            required
                            disabled={isLoading}
                            value={paper["form"]}
                            onChange={e => setPaper({ ...paper, "form": e.target.value })}
                        >
                            <option value="">Select class/form</option>
                            {
                                classes.map((cls, index) => (
                                    <option value={cls.id} key={index}>{cls.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="subject" className="form-label">
                            Subject <strong className='text-danger ms-1'>*</strong>
                        </label>
                        <select
                            id="subject"
                            className="form-select rounded-0"
                            required
                            disabled={isLoading}
                            value={paper["subject"]}
                            onChange={e => setPaper({ ...paper, "subject": e.target.value })}
                        >
                            <option value="">Select subject</option>
                            {
                                subjects.map((subject, index) => (
                                    <option value={subject.id} key={index}>{subject.name} - {subject.level}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="practical" className="form-label">
                            Paper Type <strong className='text-danger ms-1'>*</strong>
                        </label>
                        <select
                            id="practical"
                            className="form-select rounded-0"
                            value={paper["practical"]}
                            onChange={e => setPaper({ ...paper, "practical": e.target.value })}
                        >
                            <option value="">Paper 1</option>
                            <option value="2">Paper 2</option>
                            <option value="2A">Paper 2A</option>
                            <option value="2B">Paper 2B</option>
                            <option value="2C">Paper 2C</option>
                            <option value="3A">Paper 3A</option>
                            <option value="3B">Paper 3B</option>
                            <option value="3C">Paper 3C</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="date" className="form-label">Date Done</label>
                        <input
                            type="date"
                            id="date"
                            className="form-control rounded-0"
                            min={'1990-01-01'}
                            disabled={isLoading}
                            value={convertDateFormat(paper["date"])}
                            onChange={e => setPaper({ ...paper, "date": e.target.value })}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <div className="d-flex flex-align-items-center">
                            <span className="me-2">
                                Hidden: ({paper["hidden"] ? "Yes" : "No"})
                            </span>
                            <input
                                type="checkbox"
                                id="hidden"
                                className="form-check rounded-0"
                            disabled={isLoading}
                                checked={paper["hidden"]}
                                onChange={e => setPaper({ ...paper, "hidden": !paper["hidden"] })}
                            />
                        </div>
                    </div>
                    <p className="text-info">
                        If you don't want to change the existing files, don't upload anything in the file fields
                    </p>
                    <div className="form-group mb-3">
                        <label htmlFor="exam" className="form-label">Exam File</label>
                        <input
                            type="file"
                            id="exam"
                            className="form-control rounded-0"
                            disabled={isLoading}
                            accept='.pdf, .doc, .docx'
                            onChange={e => setFiles({ ...files, "exam": e.target.files[0] })}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="marking_scheme" className="form-label">Marking Scheme File</label>
                        <input
                            type="file"
                            id="marking_scheme"
                            className="form-control rounded-0"
                            disabled={isLoading}
                            accept='.pdf, .doc, .docx'
                            onChange={e => setFiles({ ...files, "marking_scheme": e.target.files[0] })}
                        />
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <span>
                            <strong className='text-danger ms-1'>*</strong> indicates required fields
                        </span>
                        <button type='submit' className='btn btn-primary fw-bold rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Saving... <Loading /></> :
                                    <>
                                        <span className="me-2">Save</span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditPastPaper
