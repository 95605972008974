import React, { useEffect, useState } from 'react'
import RoleOffice from '../../components/offices/RoleOffice'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import toast from 'react-hot-toast'

function Locations() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [locations, setLocations] = useState([])

    const fetchLocations = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/locations`, {
                headers: {
                    'x-access-token': auth.token
                }
            }
            )
            const data = await response.json()

            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setLocations(data.locations)
            }
        }
        catch (error) {
            toast.error("Failed to fetch locations. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchLocations()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Registered Locations</p>
                <RoleOffice roles={["Admin"]} element={
                    <Link
                        to='/locations/new'
                        state={{ locations }}
                        className='btn btn-primary btn-sm fw-bold rounded-0'
                    >
                        <span className="me-2">Register</span>
                        <i className='bi bi-plus-square-fill'></i>
                    </Link>}
                />
            </div>
            <div className="body shadow p-2">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Region</th>
                                        <th>District</th>
                                        <th>Ward</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        locations.map((region, regionIndex) => (
                                            <React.Fragment key={regionIndex}>
                                                {
                                                    region.districts.map((district, districtIndex) => (
                                                        <React.Fragment key={districtIndex}>
                                                            {
                                                                district.wards.map((ward, wardIndex) => (
                                                                    <tr key={wardIndex}>
                                                                        {
                                                                            wardIndex === 0 && districtIndex === 0 &&
                                                                            <td rowSpan={region.districts.reduce((acc, d) => acc + d.wards.length, 0)}>
                                                                                {region.name}
                                                                            </td>
                                                                        }
                                                                        {
                                                                            wardIndex === 0 &&
                                                                            <td rowSpan={district.wards.length}>{district.name}</td>
                                                                        }
                                                                        <td>{ward.name}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </div>
    )
}

export default Locations
