import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'
import RoleOffice from '../../components/offices/RoleOffice'
import Loading from '../../components/loading/Loading'
import LevelSplit from '../../components/offices/LevelSplit'
import { saveAs } from 'file-saver'
import toast from 'react-hot-toast'

function DSEOStaff() {
	const auth = useAuth()

	const toastId = "DSEOStaff"
	const [isLoading, setIsLoading] = useState(false)

	const [fetchedStaffs, setFetchedStaffs] = useState([])

	const columns = [
		{
			name: 'S/N',
			selector: (row, index) => index + 1,
			sortable: false,
			grow: 0,
		},
		{
			name: 'Name',
			selector: row => `${row?.firstname} ${row?.lastname}`,
			grow: 2,
			sortable: true,
		},
		{
			name: 'Check Number',
			selector: row => row?.check_number,
			sortable: false,
		},
		{
			name: 'TSC Number',
			selector: row => row?.tsc_number,
			sortable: false,
		},
		{
			name: 'Phone',
			selector: row => row?.phone,
			sortable: false,
		},
		{
			name: 'Sex',
			selector: row => row?.sex,
			sortable: true,
		},
		{
			name: 'Station',
			selector: row => row?.station,
			sortable: true,
		},
		{
			name: 'Designation',
			selector: row => row?.designation,
			sortable: true,
		},
		{
			name: 'Action',
			cell: row =>
				<div className='d-flex'>
					<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} element={
						<Link
							to={`/staffs/${row?.id}/edit`}
							state={{ staff: row }}
							className='btn btn-sm btn-primary rounded-0 me-2'>
							<i className='bi bi-pencil-square fs-6 mx-1'></i>
						</Link>}
					/>
					<Link
						to={`/staffs/${row?.id}`}
						state={{ staff: row }}
						className='btn btn-sm btn-primary rounded-0'>
						<i className='bi bi-eye-fill fs-6 mx-1'></i>
					</Link>
				</div>,
			sortable: false,
		}
	]

	const fetchStaffs = async () => {
		setIsLoading(true)

		try {
			const response = await fetch(`${BASE_API_URL}/employees/dseo`, {
				headers: {
					'x-access-token': auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				toast.error(data.message, {id: toastId})
			}
			else {
				setFetchedStaffs(data.employees)
			}
		} catch (error) {
			toast.error('Failed to fetch staffs. Please try again.', {id: toastId})
		} finally {
			setIsLoading(false)
		}
	}

	const handleExport = async (exportType) => {
			setIsLoading(true)
	
			try {
				let url = `${BASE_API_URL}/employees/dseo/export/${exportType}`
				const response = await fetch(url, {
					headers: {
						'x-access-token': auth.token
					}
				})
	
				if (!response.ok) {
					const data = await response.json()
					toast.error(data.message, {id: toastId})
					return
				}
	
				let suff = "MSSIS"
				if (auth.user.school) {
					suff = auth.user.school.name
				}
	
				let extension = "pdf"
				if (exportType === "excel") {
					extension = "xlsx"
				}
	
				const filename = `${suff} Staffs Export ${new Date().toISOString().split('T')[0]}.${extension}`
	
				const blob = await response.blob()
				saveAs(blob, filename)
			}
			catch (error) {
				toast.error('Failed to export staffs. Please try again.', {id: toastId})
			}
			finally {
				setIsLoading(false)
			}
		}

	useEffect(() => {
		fetchStaffs()
	}, [])

	return (
		<div className='pt-2'>
			<div className="d-flex align-items-center justify-content-between mb-3">
				<p className='h3 fw-bold text-muted'>
					<LevelSplit
						primary={"DPPEO "}
						ordinary={"DSEO "}
					/>
					Office Staff Members
				</p>
				<Link to='/staffs/new' className='btn btn-primary btn-sm fw-bold rounded-0'>
					<span className="me-2">Register</span>
					<i className='bi bi-person-plus-fill'></i>
				</Link>
			</div>

			<div className="my-3 shadow p-2">
				<DataTable
					title={
						<div className='d-flex flex-wrap align-items-center justify-content-between'>
							<div>{fetchedStaffs.length} Staff Members</div>
							<div className="d-flex flex-wrap align-items-center justify-content-between">
								<button className='btn btn-sm btn-primary rounded-0 me-2' onClick={e => handleExport("pdf")}>
									<span className="me-2">Export to PDF</span>
									<i className='bi bi-file-earmark-pdf'></i>
								</button>
								<button className='btn btn-sm btn-primary rounded-0' onClick={e => handleExport("excel")}>
									<span className="me-2">Export to Excel</span>
									<i className='bi bi-file-earmark-spreadsheet'></i>
								</button>
							</div>
						</div>
					}
					columns={columns}
					data={fetchedStaffs ? fetchedStaffs : []}
					progressPending={isLoading}
					progressComponent={
						<div className="text-center">
							Loading... <Loading />
						</div>
					}
					noDataComponent={"No staff members found."}
				/>
			</div>
		</div>
	)
}

export default DSEOStaff
