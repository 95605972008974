import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import { convertDateFormat } from '../../utils/date_conversions'
import LevelSplit from '../../components/offices/LevelSplit'
import toast from 'react-hot-toast'

function EditStaff() {
    const auth = useAuth()
    const params = useParams()
    const staffId = params.id

    const toastId = "EditStaff"
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [phoneError, setPhoneError] = useState(false)

    const [firstname, setFirstname] = useState('')
    const [middlename, setMiddlename] = useState('')
    const [lastname, setLastname] = useState('')
    const [sex, setSex] = useState('')
    const [checkNumber, setCheckNumber] = useState('')
    const [tscNumber, setTscNumber] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [dob, setDob] = useState('')
    const [educationLevel, setEducationLevel] = useState('')
    const [collegeAttended, setCollegeAttended] = useState('')
    const [completionYear, setCompletionYear] = useState('')
    const [residenceRegion, setResidenceRegion] = useState('')
    const [residenceDistrict, setResidenceDistrict] = useState('')
    const [salaryLevel, setSalaryLevel] = useState('')
    const [subjectOne, setSubjectOne] = useState('')
    const [subjectTwo, setSubjectTwo] = useState('')
    const [subjectThree, setSubjectThree] = useState('')
    const [subjectFour, setSubjecFour] = useState('')
    const [employmentDate, setEmploymentDate] = useState('')
    const [confirmationDate, setConfirmationDate] = useState('')
    const [designationDate, setDesignationDate] = useState('')
    const [designation, setDesignation] = useState('')

    const [employee, setEmployee] = useState({})
    const [subjects, setSubjects] = useState([])

    const fetchSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            toast.error('Failed to fetch subjects. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchStaff = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/employees/${staffId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                setEmployee(data.employee)
                setFirstname(data.employee.firstname)
                setMiddlename(data.employee.middlename)
                setLastname(data.employee.lastname)
                setEmail(data.employee.email)
                setPhone(data.employee.phone)
                setDob(data.employee.dob)
                setSex(data.employee.sex)
                setCheckNumber(data.employee.check_number)
                setTscNumber(data.employee.tsc_number)
                setEducationLevel(data.employee.education_level)
                setCollegeAttended(data.employee.college_attended)
                setCompletionYear(data.employee.year_of_completion)
                setResidenceRegion(data.employee.residence_region)
                setResidenceDistrict(data.employee.residence_district)
                setSalaryLevel(data.employee.salary_level)
                setSubjectOne(data.employee.subject_one ? data.employee.subject_one.id : '')
                setSubjectTwo(data.employee.subject_two ? data.employee.subject_two.id : '')
                setSubjectThree(data.employee.subject_three ? data.employee.subject_three.id : '')
                setSubjecFour(data.employee.subject_four ? data.employee.subject_four.id : '')
                setConfirmationDate(data.employee.confirmation_date)
                setDesignationDate(data.employee.designation_date)
                setEmploymentDate(data.employee.date_hired)
                if (data.employee.school && ['Teacher', 'Temporary Teacher'].includes(data.employee.school.role)) {
                    setDesignation(data.employee.school.role)
                }
            }
        }
        catch (error) {
            toast.error('Failed to fetch staff. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
    }, [])

    const handleEdit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/employees/${staffId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    firstname,
                    middlename,
                    lastname,
                    email,
                    phone,
                    dob,
                    sex,
                    checkNumber,
                    tscNumber,
                    educationLevel,
                    collegeAttended,
                    completionYear,
                    residenceRegion,
                    residenceDistrict,
                    salaryLevel,
                    subjectOne,
                    subjectTwo,
                    subjectThree,
                    subjectFour,
                    confirmationDate,
                    designationDate,
                    employmentDate,
                    designation
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message, {id: toastId})
            }
            else {
                toast.success(data.message, {id: toastId})
            }
        }
        catch (error) {
            toast.error('Failed to update staff. Please try again.', {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStaff()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>Edit Staff Member</p>
            <div className='shadow p-2'>
                <form onSubmit={handleEdit}>
                    <p className='h4 mb-3 text-muted'>Basic Info</p>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='firstname'>First Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='firstname'
                                    required
                                    disabled={isLoading}
                                    value={firstname}
                                    onChange={e => setFirstname(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label className='form-label' htmlFor="middlename">Middle Name</label>
                                <input
                                    type="text"
                                    className="form-control rounded-0"
                                    id="middlename"
                                    disabled={isLoading}
                                    value={middlename}
                                    onChange={e => setMiddlename(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='lastname'>Last Name</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='lastname'
                                    required
                                    disabled={isLoading}
                                    value={lastname}
                                    onChange={e => setLastname(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label className='form-label' htmlFor="dob">Date of Birth</label>
                                <input
                                    type="date"
                                    className="form-control rounded-0"
                                    id="dob"
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(dob)}
                                    onChange={e => setDob(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label className='form-label' htmlFor="sex">Sex</label>
                                <select
                                    className="form-select rounded-0"
                                    id='sex'
                                    required
                                    disabled={isLoading}
                                    value={sex}
                                    onChange={e => setSex(e.target.value)}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label className='form-label' htmlFor="educationLevel">Education Level</label>
                                <select
                                    className="form-select rounded-0"
                                    id='educationLevel'
                                    required
                                    disabled={isLoading}
                                    value={educationLevel}
                                    onChange={e => setEducationLevel(e.target.value)}
                                >
                                    <option value="">Select education level</option>
                                    <option value="Certificate">Certificate</option>
                                    <option value="Diploma">Diploma</option>
                                    <option value="Degree">Degree</option>
                                    <option value="Masters">Masters</option>
                                    <option value="PhD">PhD</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label className='form-label' htmlFor="college">College Attended</label>
                                <input
                                    type="text"
                                    className="form-control rounded-0"
                                    id="college"
                                    placeholder="e.g Marangu Teachers College"
                                    disabled={isLoading}
                                    value={collegeAttended}
                                    onChange={e => setCollegeAttended(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label className='form-label' htmlFor="completion">Year of Studies Completion</label>
                                <input
                                    type="number"
                                    className="form-control rounded-0"
                                    id="completion"
                                    placeholder="e.g 2020"
                                    disabled={isLoading}
                                    value={completionYear}
                                    onChange={e => setCompletionYear(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <p className='h4 mb-3 text-muted'>Employment Info</p>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='checkNumber'>Check Number</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='checkNumber'
                                    required
                                    disabled={isLoading}
                                    value={checkNumber}
                                    onChange={e => setCheckNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='tscNumber'>TSC Number</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='tscNumber'
                                    required
                                    disabled={isLoading}
                                    value={tscNumber}
                                    onChange={e => setTscNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='salaryLevel'>Salary Level</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='salaryLevel'
                                    required
                                    disabled={isLoading}
                                    value={salaryLevel}
                                    onChange={e => setSalaryLevel(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='employmentDate'>Date of Employment</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='employmentDate'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(employmentDate)}
                                    onChange={e => setEmploymentDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='confirmationDate'>Date of Confirmation</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='confirmationDate'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(confirmationDate)}
                                    onChange={e => setConfirmationDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label className='form-label' htmlFor="designation">Designation</label>
                                <select
                                    className="form-select rounded-0"
                                    id='designation'
                                    disabled={isLoading}
                                    value={designation}
                                    onChange={e => setDesignation(e.target.value)}
                                >
                                    <option value="">Select Designation</option>
                                    <option value="Teacher">Teacher</option>
                                    <option value="Temporary Teacher">Temporary Teacher</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='designationDate'>Date of Designation</label>
                                <input
                                    type='date'
                                    className='form-control rounded-0'
                                    id='designationDate'
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(designationDate)}
                                    onChange={e => setDesignationDate(e.target.value)}
                                />
                            </div>
                        </div>

                        {
                            // Display subject fields only if the user is a teacher
                            !["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"].includes(employee.role) &&
                            <LevelSplit primary={<></>} ordinary={
                                <>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label className='form-label' htmlFor='subjectOne'>Subject One</label>
                                            <select
                                                className='form-select'
                                                id='subjectOne'
                                                disabled={isLoading}
                                                value={subjectOne}
                                                onChange={e => setSubjectOne(e.target.value)}
                                            >
                                                <option value=''>Select Subject</option>
                                                {
                                                    subjects.map(subject => (
                                                        <option key={subject.id} value={subject.id}>
                                                            {subject.name}
                                                            <LevelSplit primary={null} ordinary={` - ${subject.level}`} />
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label className='form-label' htmlFor='subjectTwo'>Subject Two</label>
                                            <select
                                                className='form-select'
                                                id='subjectTwo'
                                                disabled={isLoading}
                                                value={subjectTwo}
                                                onChange={e => setSubjectTwo(e.target.value)}
                                            >
                                                <option value=''>Select Subject</option>
                                                {
                                                    subjects.map(subject => (
                                                        <option key={subject.id} value={subject.id}>
                                                            {subject.name}
                                                            <LevelSplit primary={null} ordinary={` - ${subject.level}`} />
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label className='form-label' htmlFor='subjectThree'>Subject Three</label>
                                            <select
                                                className='form-select'
                                                id='subjectThree'
                                                disabled={isLoading}
                                                value={subjectThree}
                                                onChange={e => setSubjectThree(e.target.value)}
                                            >
                                                <option value=''>Select Subject</option>
                                                {
                                                    subjects.map(subject => (
                                                        <option key={subject.id} value={subject.id}>
                                                            {subject.name}
                                                            <LevelSplit primary={null} ordinary={` - ${subject.level}`} />
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label className='form-label' htmlFor='subjectFour'>Subject Four</label>
                                            <select
                                                className='form-select'
                                                id='subjectFour'
                                                disabled={isLoading}
                                                value={subjectFour}
                                                onChange={e => setSubjecFour(e.target.value)}
                                            >
                                                <option value=''>Select Subject</option>
                                                {
                                                    subjects.map(subject => (
                                                        <option key={subject.id} value={subject.id}>
                                                            {subject.name}
                                                            <LevelSplit primary={null} ordinary={` - ${subject.level}`} />
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </>
                            } />
                        }
                    </div>

                    <p className='h4 mb-3 text-muted'>Contact Info</p>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='email'>Email</label>
                                <input
                                    type='email'
                                    className='form-control rounded-0'
                                    id='email'
                                    required
                                    disabled={isLoading}
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='phone'>Phone (255...)</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='phone'
                                    required
                                    value={phone}
                                    onChange={(e) => {
                                        // test phonenumber with regex: 255(6|7)[0-9]{8}
                                        if (!(/^255(6|7)[0-9]{8}$/.test(e.target.value))) {
                                            setPhoneError(true)
                                        }
                                        else {
                                            setPhoneError(false)
                                        }
                                        setPhone(e.target.value)
                                    }
                                    }
                                />
                                {
                                    phoneError &&
                                    <small className="text-danger">Enter a valid phone number</small>
                                }
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='region'>Region of Domicile</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='region'
                                    disabled={isLoading}
                                    value={residenceRegion}
                                    onChange={e => setResidenceRegion(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label className='form-label' htmlFor='district'>District of Domicile</label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='district'
                                    disabled={isLoading}
                                    value={residenceDistrict}
                                    onChange={e => setResidenceDistrict(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            message &&
                            <div className={`alert alert-${variant} p-2`} role="alert">
                                {message}
                            </div>
                        }
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading || phoneError}>
                            {
                                isLoading ?
                                    <>Saving... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Save
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditStaff
