import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'

function NewSchoolSubject() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])

    const [subjects, setSubjects] = useState([])
    const [selectedSubjects, setSelectedSubjects] = useState([])

    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => <span>{index + 1}</span>,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Code',
            selector: row => row.code,
            sortable: true,
        },
        {
            name: 'Level',
            selector: row => row.level,
            sortable: true,
        },
        {
            name: <input
                type='checkbox'
                onChange={(e) => {
                    if (e.target.checked) {
                        setSelectedSubjects(subjects.map(subject => subject.id))
                    } else {
                        setSelectedSubjects([])
                    }
                }}
            />,
            cell: row => <button
                type='button'
                className={`btn btn-sm rounded-0 ${selectedSubjects.includes(row.id) ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => {
                    if (selectedSubjects.includes(row.id)) {
                        setSelectedSubjects(selectedSubjects.filter(subjectId => subjectId !== row.id))
                    } else {
                        setSelectedSubjects([...selectedSubjects, row.id])
                    }
                }}>
                {selectedSubjects.includes(row.id) ? 'Selected' : 'Select'}
            </button>,
            sortable: false,
        }
    ]

    const fetchSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects/bare`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjects(data.subjects)
            }
        } catch (error) {
            toast.error("Failed to fetch subjects. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        if (selectedSubjects.length < 1) {
            toast.error("Please select at least one subject.")
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    subjects: selectedSubjects
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
                setErrors(data.errors)
            }
            else {
                toast.success(data.message)
            }
        } catch (error) {
            toast.error("Failed to register subjects. Please try again.")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
    }, [])

    return (
        <div className='pt-2'>
            <p className='mb-3'>
                <span className="h3 fw-bold text-muted">
                    Register Subject
                </span>
                <br />
                <small>Select subjects that will be taught in this school</small>
            </p>
            <div className='shadow p-2'>
                {
                    errors.map((error, index) => (
                        <div key={index} className="alert alert-danger p-2 mb-1" role="alert">
                            {error}
                        </div>
                    ))
                }

                <form onSubmit={handleSubmit}>
                    <DataTable
                        columns={columns}
                        data={subjects}
                        highlightOnHover
                        noDataComponent={"No subjects registered yet."}
                    />
                    <div className="my-2">
                        {
                            errors.map((error, index) => (
                                <div key={index} className="alert alert-danger p-2 mb-1" role="alert">
                                    {error}
                                </div>
                            ))
                        }
                    </div>
                    <div className="text-end mb-3">
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Registering... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Register ({selectedSubjects.length} selected)
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewSchoolSubject
