import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL, CLASSES_PRIMARY, CLASSES_SECONDARY } from '../../utils/constants'
import { Link } from 'react-router-dom'
import LevelSplit from '../../components/offices/LevelSplit'
import RoleOffice from '../../components/offices/RoleOffice'
import { FormSelect } from '../student/Students'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import { saveAs } from 'file-saver'
import { formatDate } from '../../utils/date_conversions'
import toast from 'react-hot-toast'

function Attendance() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState({ date: formatDate(null, "yyyy-mm-dd"), form: "", school: "", category: "" })
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(50)

    const [forms, setForms] = useState([])
    const [schools, setSchools] = useState([])
    const [attendance, setAttendance] = useState({})
    const [attendees, setAttendees] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (page - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: <LevelSplit primary={'PREM No'} ordinary={'PREMS No'} />,
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: <LevelSplit primary={'Class'} ordinary={'Form'} />,
            selector: row => row.form,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.is_present ? "Present" : "Absent",
            sortable: true,
        }
    ]

    const fetchSchools = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            toast.error('Failed to fetch schools. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchForms = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            toast.error('Failed to fetch forms. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchAttendance = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/attendance?form=${filters.form}`
            url += `&date=${filters.date}&school=${filters.school}&category=${filters.category}`
            url += `&page=${page}&per_page=${perPage}`

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setAttendance(data.attendance)
                setAttendees(data.attendance?.attendees)
            }
        }
        catch (error) {
            toast.error('Failed to fetch attendance. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleExport = async (exportType) => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/attendance?form=${filters.form}`
            url += `&date=${filters.date}&school=${filters.school}&category=${filters.category}`
            url += `&export=${exportType}`

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            const filename = response.headers.get("x-filename")
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error('Failed to export attendance. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchForms()
        if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
            fetchSchools()
        }
    }, [])

    useEffect(() => {
        fetchAttendance()
    }, [page, perPage])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>
                    <LevelSplit primary={"Pupils"} ordinary={"Students"} /> Attendance
                </p>

                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <RoleOffice
                        roles={["Headmaster", "Second Master", "Academic Master", ...CLASSES_PRIMARY, ...CLASSES_SECONDARY]}
                        element={
                            <Link to="new" className='btn btn-primary btn-sm fw-bold rounded-0 me-2 mb-1 disabled'>
                                <span className="me-2">QR Code</span>
                                <i className='bi bi-qr-code-scan fs-6'></i>
                            </Link>
                        }
                    />
                    <RoleOffice roles={[...CLASSES_PRIMARY, ...CLASSES_SECONDARY]} element={
                        <Link to="record" className='btn btn-primary btn-sm fw-bold rounded-0 mb-1'>
                            <span className="me-2">Take Attendance</span>
                            <i className='bi bi-person-check-fill fs-6'></i>
                        </Link>
                    } />
                </div>
            </div>

            <div className="shadow rounded-0 p-2 mb-3">
                <p className='text-muted fw-bold'>
                    Filters
                </p>
                <form onSubmit={e => {
                    e.preventDefault()
                    fetchAttendance()
                }}>
                    <div className="row mx-0 ">
                        <div className="col-12 col-lg-2 mb-2">
                            <label htmlFor='date' className='form-label'>Date</label>
                            <input
                                type="date"
                                className="form-control rounded-0"
                                required
                                max={formatDate(null, "yyyy-mm-dd")}
                                value={filters.date}
                                onChange={e => setFilters({ ...filters, date: e.target.value })}
                            />
                        </div>
                        <RoleOffice
                            roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"]}
                            element={
                                <div className="col-12 col-lg-3 mb-2">
                                    <label htmlFor='school' className='form-label'>School</label>
                                    <select
                                        id='school'
                                        className='form-select rounded-0'
                                        value={filters.school}
                                        onChange={e => setFilters({ ...filters, school: e.target.value })}
                                    >
                                        <option value=''>All Schools</option>
                                        {
                                            schools.map(school =>
                                                <option key={school.id} value={school.id}>{school.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            } />
                        {/* for class teachers and other school staffs */}
                        <RoleOffice roles={[...CLASSES_PRIMARY, ...CLASSES_SECONDARY]} element={
                            <RoleOffice roles={["Headmaster", "Second Master", "Academic Master"]} reverse={true}
                                element={
                                    <FormSelect
                                        classes="col-12 col-lg-2 mb-2"
                                        value={filters.form}
                                        onchange={(val) => setFilters({ ...filters, form: val })}
                                        options={forms.filter(f => auth.user.class_teacher.includes(f.name))}
                                    />
                                }
                                error={
                                    <FormSelect
                                        classes="col-12 col-lg-2 mb-2"
                                        value={filters.form}
                                        onchange={(val) => setFilters({ ...filters, form: val })}
                                        options={forms}
                                    />
                                }
                            />
                        } />
                        {/* for remaining staffs */}
                        <RoleOffice roles={[...CLASSES_PRIMARY, ...CLASSES_SECONDARY]} reverse={true} element={
                            <FormSelect
                                classes="col-12 col-lg-2 mb-2"
                                value={filters.form}
                                onchange={(val) => setFilters({ ...filters, form: val })}
                                options={forms}
                            />
                        } />
                        <div className="col-12 col-lg-2 mb-2">
                            <label htmlFor='category' className='form-label'>Category</label>
                            <select
                                id='category'
                                className='form-select rounded-0'
                                value={filters.category}
                                onChange={e => setFilters({ ...filters, category: e.target.value })}
                            >
                                <option value=''>All</option>
                                <option value="absent">Absent</option>
                                <option value="present">Present</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                            <button type='submit' className='btn btn-primary rounded-0 mt-4'>
                                <span className="me-2">Filter</span>
                                <i className='bi bi-funnel-fill'></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="shadow rounded-0 p-2 mb-3">
                <div className="table-responsive-sm mb-3">
                    <table className="table table-bordered table-striped table-hover table-sm">
                        <thead>
                            <tr className="text-center">
                                <th>Category</th>
                                {filters.category !== "present" && <th>Absent</th>}
                                {filters.category !== "absent" && <th>Present</th>}
                                {
                                    filters.category === "" &&
                                    <th>Total</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ["F", "M", "T"].map((gen, index) =>
                                    <tr key={index} className="text-center">
                                        <td className="fw-bold">{gen}</td>
                                        {
                                            filters.category !== "present" &&
                                            <td>{attendance.summary && <>{attendance?.summary[gen]["absent"]}</>}</td>
                                        }
                                        {
                                            filters.category !== "absent" &&
                                            <td>{attendance.summary && <>{attendance?.summary[gen]["present"]}</>}</td>
                                        }
                                        {
                                            filters.category === "" &&
                                            <td>{attendance.summary && <>{attendance?.summary[gen]["total"]}</>}</td>
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                <div className="mb-3">
                    <div className='d-flex flex-wrap align-items-center justify-content-between mb-3'>
                        <span className="fs-5">
                            {attendance.total} <LevelSplit primary={"Pupils"} ordinary={"Students"} />
                        </span>
                        <div className='d-flex flex-wrap align-items-center justify-content-between'>
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <button className='btn btn-sm btn-primary rounded-0 me-2' onClick={e => handleExport("pdf")}>
                                    <span className="me-2">Export to PDF</span>
                                    <i className='bi bi-file-earmark-pdf'></i>
                                </button>
                                <button className='btn btn-sm btn-primary rounded-0' onClick={e => handleExport("excel")}>
                                    <span className="me-2">Export to Excel</span>
                                    <i className='bi bi-file-earmark-spreadsheet'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={attendees}
                        progressPending={isLoading}
                        progressComponent={<div className="text-center">Loading... <Loading /></div>}
                        pagination
                        paginationServer
                        paginationTotalRows={attendance.total}
                        onChangePage={p => setPage(p)}
                        onChangeRowsPerPage={pp => setPerPage(pp)}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[50, 150, 250, 500]}
                        noDataComponent={"No attendance records found."}
                    />
                </div>
            </div>
        </div>
    )
}

export default Attendance
