import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import toast from 'react-hot-toast'

function SMSManageOrders() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([])

    const ordersColumns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Staff Name",
            selector: row => row.staff,
            sortable: true
        },
        {
            name: "Phone",
            selector: row => row.phone,
            sortable: false
        },
        {
            name: "SMS Quantity",
            selector: row => row.sms_count,
            sortable: true
        },
        {
            name: "Unit Cost",
            selector: row => row.unit_cost,
            sortable: true
        },
        {
            name: "Total Cost",
            selector: row => row.unit_cost * row.sms_count,
            sortable: true
        },
        {
            name: "Purchase Date",
            selector: row => row.date_purchased,
            sortable: true
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true
        },
        {
            name: "Action",
            cell: row => (<div className="d-flex flex-wrap align-items-center justify-content-between">
                <button
                    className="btn btn-sm btn-primary rounded-0 fw-bold me-2"
                    onClick={() => handleOrder(row.id, "completed")}
                >
                    Approve
                </button>
                <button
                    className="btn btn-sm btn-primary rounded-0 fw-bold"
                    onClick={() => handleOrder(row.id, "rejected")}
                >
                    Reject
                </button>
            </div>),
            sortable: false,
            grow: 1.5
        }
    ]

    const fetchOrders = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/sms/purchase/orders`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setOrders(data.orders)
            }
        }
        catch (error) {
            toast.error("Failed to fetch SMS orders. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleOrder = async (orderId, status) => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/sms/purchase/orders/${orderId}/${status}`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
                fetchOrders()
            }
        }
        catch (error) {
            toast.error("Failed to handle order. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    return (
        <div className="mb-3">
            <p className="h5 fw-bold text-muted">SMS Orders</p>            
            <DataTable
                columns={ordersColumns}
                data={orders}
                progressPending={isLoading}
                progressComponent={<>Loading...<Loading /></>}
                noDataComponent={"No pending SMS orders."}
            />
        </div>
    )
}

export default SMSManageOrders
