import React, { useEffect, useState } from 'react'
import RoleOffice from '../../components/offices/RoleOffice'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import { saveAs } from 'file-saver'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import toast from 'react-hot-toast'

function SubjectTeachers() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(100)
    const [totalStaffs, setTotalStaffs] = useState(0)

    const [schools, setSchools] = useState([])
    const [classes, setClasses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [filterSchool, setFilterSchool] = useState('')
    const [filterClass, setFilterClass] = useState('')
    const [filterSubject, setFilterSubject] = useState('')

    const [subjectTeachers, setSubjectTeachers] = useState([])
    const [totalSubjectTeachers, setTotalSubjectTeachers] = useState(0)

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name: 'Subject',
            selector: row => row.subject,
            sortable: true,
        },
        {
            name: 'Teacher',
            selector: row => row.teacher,
            sortable: true,
        }
    ]

    const fetchSchools = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            toast.error('Failed to fetch schools. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchClasses = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setClasses(data.classes)
            }
        }
        catch (error) {
            toast.error('Failed to fetch classes. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjects(data.subjects)
            }
        }
        catch (error) {
            toast.error('Failed to fetch subjects. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSubjectTeachers = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(
                `${BASE_API_URL}/subjects/teachers?page=${currentPage}&per_page=${perPage}&school=${filterSchool}&form=${filterClass}&subject=${filterSubject}`,
                {
                    headers: {
                        'x-access-token': auth.token
                    }
                })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjectTeachers(data.teachers)
                setCurrentPage(data.page)
                setPerPage(data.per_page)
                setTotalSubjectTeachers(data.total_subject_teachers)
            }
        }
        catch (error) {
            toast.error('Failed to fetch subject teachers. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleExport = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(
                `${BASE_API_URL}/subjects/teachers?export=pdf&page=${currentPage}&per_page=${perPage}&school=${filterSchool}&form=${filterClass}&subject=${filterSubject}`,
                {
                    headers: {
                        'x-access-token': auth.token
                    }
                })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }

            const filename = `${suff} Subject Teachers Export ${new Date().toISOString().split('T')[0]}.pdf`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error('Failed to export subject teachers. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
            fetchSchools()
        }
        fetchClasses()
        fetchSubjects()
    }, [])

    useEffect(() => {
        fetchSubjectTeachers()
    }, [currentPage, perPage])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>All Subject Teachers</p>
                <RoleOffice
                    roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO",
                        "AEK", "Headmaster", "Second Master", "Academic Master"]}
                    element={
                        <button
                            className='btn btn-primary btn-sm fw-bold rounded-0 me-2'
                            disabled={isLoading}
                            onClick={handleExport}
                        >
                            <span className="me-2">Export to PDF</span>
                            <i className='bi bi-file-earmark-pdf'></i>
                        </button>
                    }
                />
            </div>

            <div className="my-3 shadow p-2">
                <p className="text-muted fw-bold">Filter Subject Teachers</p>
                <form onSubmit={e => {
                    e.preventDefault()
                    fetchSubjectTeachers()
                }}>
                    <div className="row mx-0 ">
                        <RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"]} element={
                            <div className="col-12 col-lg-3 mb-2">
                                <label htmlFor='school' className='form-label'>School</label>
                                <select
                                    id='school'
                                    className='form-select rounded-0'
                                    value={filterSchool}
                                    onChange={e => setFilterSchool(e.target.value)}
                                >
                                    <option value='all'>All Schools</option>
                                    {
                                        schools.map(school =>
                                            <option key={school.id} value={school.id}>{school.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        } />
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='form' className='form-label'>Class/Form</label>
                            <select
                                id='form'
                                className='form-select rounded-0'
                                value={filterClass}
                                onChange={e => setFilterClass(e.target.value)}
                            >
                                <option value='all'>All Classes/Forms</option>
                                {
                                    classes.map(cls =>
                                        <option key={cls.id} value={cls.id}>{cls.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='subject' className='form-label'>Subject</label>
                            <select
                                id='subject'
                                className='form-select rounded-0'
                                value={filterSubject}
                                onChange={e => setFilterSubject(e.target.value)}
                            >
                                <option value='all'>All Subjects</option>
                                {
                                    subjects.map(sub =>
                                        <option key={sub.id} value={sub.id}>{sub.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end mt-4">
                            <button type='submit' className='btn btn-primary rounded-0'>
                                <span className="me-2">Filter</span>
                                <i className='bi bi-funnel-fill'></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="shadow p-2 mb-3">
                <DataTable
                    title={`${totalSubjectTeachers} Subject Teachers`}
                    columns={columns}
                    data={subjectTeachers}
                    progressPending={isLoading}
                    progressComponent={<>Loading... <Loading /></>}
                    responsive
                    pagination
                    paginationServer
                    paginationTotalRows={totalSubjectTeachers}
                    onChangePage={p => setCurrentPage(p)}
                    onChangeRowsPerPage={pp => setPerPage(pp)}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[100, 300, 500, 1500]}
                    noDataComponent={"No subject teachers found."}
                />
            </div>
        </div>
    )
}

export default SubjectTeachers
