import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../../utils/constants'
import toast from 'react-hot-toast'

function NewSubTopic() {
    const auth = useAuth()
    const params = useParams()
    const topicId = params.topicId
    const location = useLocation()
    const topic = location?.state?.topic

    const [isLoading, setIsLoading] = useState(false)
    const [subtopic, setSubtopic] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/${topicId}/subtopic`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({ subtopic })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }

        }
        catch(error) {
            toast.success("Failed to add sub topic. Please try again later.")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <div>
                <p className='h3 fw-bold text-muted'>New Subject Topic</p>
                {
                    topic &&
                    <p>
                        Topic: {topic?.name}
                    </p>
                }
            </div>

            <div className='shadow p-2 mb-3'>
                <form onSubmit={handleSubmit} className="mb-3">
                    <div className="mb-3">
                        <label htmlFor="subtopic" className="form-label">Sub Topic Name</label>
                        <input 
                            type="text" 
                            id="subtopic"
                            className="form-control rounded-0"
                            required
                            value={subtopic}
                            onChange={e => setSubtopic(e.target.value)}
                        />
                    </div>
                    <div className="text-end">
                        <button
                            type="submit"
                            className="btn btn-primary fw-bold rounded-0"
                        >
                            <span className="me-2">Submit</span>
                            <i className="bi bi-floppy2"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewSubTopic
