import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import toast from 'react-hot-toast'

function PasswordUpdate() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [current, setCurrent] = useState('')
    const [confirm, setConfirm] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        if (password !== confirm) {
            toast.error('Passwords do not match')
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/profile/password`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    current,
                    password,
                    confirm
                })
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error("Failed to update password. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted mb-3'>Update Password</p>
            </div>

            <div className="">
                <form onSubmit={handleSubmit}>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='current' className='form-label'>Current Password</label>
                                <input
                                    type='password'
                                    className='form-control rounded-0'
                                    id='current'
                                    required
                                    disabled={isLoading}
                                    value={current}
                                    onChange={(e) => setCurrent(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='password' className='form-label'>New Password</label>
                                <input
                                    type='password'
                                    className='form-control rounded-0'
                                    id='password'
                                    required
                                    disabled={isLoading}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='confirm' className='form-label'>Confirm Password</label>
                                <input
                                    type='password'
                                    className='form-control rounded-0'
                                    id='confirm'
                                    required
                                    disabled={isLoading}
                                    value={confirm}
                                    onChange={(e) => setConfirm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <button
                                type='submit'
                                className='btn btn-primary rounded-0'
                                disabled={isLoading}
                            >
                                {isLoading ? 'Updating...' : 'Update Password'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PasswordUpdate
