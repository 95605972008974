import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import toast from 'react-hot-toast'

function Subscriptions() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [subscriptions, setSubscriptions] = useState({})
    const [subItems, setSubItems] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Date Started',
            selector: row => row.date_started,
            sortable: true,
        },
        {
            name: 'Date Ended',
            selector: row => row.date_ended,
            sortable: true,
        },
        {
            name: 'Service Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <div className='d-flex'>
                {
                    // row.status === "Active" ?
                    //     <button
                    //         className='btn btn-sm btn-warning rounded-0 me-2'>
                    //         <i className='bi bi-sign-stop fs-6 mx-1'></i>
                    //     </button>
                    //     :
                    //     <button
                    //         className={`btn btn-sm btn-warning rounded-0 me-2 ${row.status !== "Suspended" && 'disabled'}`}>
                    //         <i className='bi bi-shield-check fs-6 mx-1'></i>
                    //     </button>
                }
                <Link
                    to={`${row.id}`}
                    state={{ subscription: row }}
                    className='btn btn-sm btn-primary rounded-0'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            sortable: false,
        }
    ]

    const filterSubscriptions = (filter) => {
        if (filter.length < 1) {
            setSubItems(subscriptions.items)
            return
        }

        setSubItems(subscriptions.items.filter(s => s.name?.toLowerCase().includes(filter)))
    }

    const fetchSubscriptions = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subscriptions`, {
                headers: {
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubscriptions(data?.subscriptions)
                setSubItems(data?.subscriptions?.items)
            }
        }
        catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubscriptions()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Subscriptions</p>
                <Link to='new' className='btn btn-primary btn-sm fw-bold rounded-0'>
                    <span className="me-2">New</span>
                    <i className='bi bi-plus-square'></i>
                </Link>
            </div>

            <div className="shadow p-2 mb-3 rounded-0">
                <p>
                    <span className="me-3">Active: {subscriptions.summary?.active}</span>
                    <span className="me-3">Suspended: {subscriptions.summary?.suspended}</span>
                    <span className="me-3">Ended: {subscriptions.summary?.ended}</span>
                </p>
            </div>

            <div className="shadow p-2 mb-3 rounded-0">
                <form onSubmit={e => e.preventDefault()}>
                    <input
                        type="text"
                        className="form-control me-1 rounded-0 mb-3"
                        placeholder={"Search by name"}
                        onChange={e => filterSubscriptions(e.target.value)}
                    />
                </form>
                <DataTable
                    columns={columns}
                    data={subItems}
                    progressPending={isLoading}
                    progressComponent={<div className="text-center">Loading...<Loading /></div>}
                    noDataComponent={"No subscriptions available."}
                />
            </div>
        </div>
    )
}

export default Subscriptions
