import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../context/AuthContext'
import { BASE_API_URL } from '../../../../utils/constants'
import RoleOffice from '../../../../components/offices/RoleOffice'
import { Link } from 'react-router-dom'
import Loading from '../../../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'

const PAST_PAPER_EXAM_TYPES = [
    {
        name: "Tests",
        value: "tests"
    },
    {
        name: "Monthly Exams",
        value: "monthly"
    },
    {
        name: "Opening Exams",
        value: "opening"
    },
    {
        name: "Midterm Exams",
        value: "midterm"
    },
    {
        name: "Terminal Exams",
        value: "terminal"
    },
    {
        name: "Annual Exams",
        value: "annual"
    },
    {
        name: "Pre-Mock Exams",
        value: "pre-mock"
    },
    {
        name: "Mock Exams",
        value: "mock"
    },
    {
        name: "Pre-NECTA Exams",
        value: "pre-necta"
    },
    {
        name: "NECTA Exams",
        value: "necta"
    }
]

function PastPaperExams() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(100)
    const [totalExams, setTotalExams] = useState(0)

    const [filters, setFilters] = useState({ "type": "", "year": "", "title": "" })
    const [exams, setExams] = useState([])

    const columns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: "Title",
            selector: row => row.title,
            grow: 2,
        },
        {
            name: "Type",
            selector: row => row.type,
            sortable: true,
        },
        {
            name: "Level",
            selector: row => row.level,
            sortable: true,
        },
        {
            name: "Start Date",
            selector: row => row.start_date,
            sortable: true,
        },
        {
            name: "End Date",
            selector: row => row.end_date,
            sortable: true,
        },
        {
            name: "Action",
            cell: row => <div>
                <div className='d-flex'>
                    <Link
                        to={`${row.id}/edit`}
                        state={{ exam: row }}
                        className='btn btn-sm btn-primary rounded-0 me-2'>
                        <i className='bi bi-pencil-square fs-6 mx-1'></i>
                    </Link>
                    <Link
                        to={`${row.id}`}
                        state={{ exam: row }}
                        className='btn btn-sm btn-primary rounded-0'>
                        <i className='bi bi-eye-fill fs-6 mx-1'></i>
                    </Link>
                </div>
            </div>,
            sortable: false,
        }
    ]

    const fetchPastPaperExams = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/past-paper-exams`
            url += `?type=${filters["type"]}&year=${filters["year"]}&title=${filters["title"]}&page=${page}&per_page=${perPage}`
            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setExams(data.exams)
                setPage(data.page)
                setPerPage(data.per_page)
                setTotalExams(data.total)
            }
        }
        catch (error) {
            toast.error('Failed to fetch past paper exams. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchPastPaperExams()
    }, [page, perPage])

    return (
        <div className='pt-2'>
            <div className="flex d-flex align-items center justify-content-between">
                <p className='h3 fw-bold text-muted'>Past Paper Exams</p>
                <div>
                    <RoleOffice roles={["Admin"]} element={
                        <Link
                            to={"new"}
                            className="btn btn-primary btn-sm fw-bold rounded-0"
                        >
                            <span className="me-2">Register</span>
                            <i className="bi bi-plus-square"></i>
                        </Link>
                    } />
                </div>
            </div>

            <div className="mb-3 shadow rounded-0 p-2">
                <p className='text-muted fw-bold'>
                    Filters
                </p>
                <form onSubmit={e => {
                    e.preventDefault()
                    fetchPastPaperExams()
                }}>
                    <div className="row mx-0 ">
                        <div className="col-6 col-lg-2 mb-2">
                            <label htmlFor='type' className='form-label'>Exam Type</label>
                            <select
                                id='type'
                                className='form-select form-select-sm rounded-0'
                                disabled={isLoading}
                                value={filters["type"]}
                                onChange={e => setFilters({ ...filters, "type": e.target.value })}
                            >
                                <option value="">All Exam Types</option>
                                {
                                    PAST_PAPER_EXAM_TYPES.map((exam, index) => (
                                        <option value={exam.value} key={index}>{exam.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-6 col-lg-1 mb-2">
                            <label htmlFor='year' className='form-label'>Year</label>
                            <input
                                type="number"
                                id="year"
                                className="form-control form-control-sm rounded-0"
                                disabled={isLoading}
                                placeholder='2024'
                                min={1990}
                                value={filters["year"]}
                                onChange={e => setFilters({ ...filters, "year": e.target.value })}
                            />
                        </div>
                        <div className="col-6 col-lg-3 mb-2">
                            <label htmlFor='title' className='form-label'>Title</label>
                            <input
                                type="text"
                                id="title"
                                className="form-control form-control-sm rounded-0"
                                disabled={isLoading}
                                value={filters["title"]}
                                onChange={e => setFilters({ ...filters, "title": e.target.value })}
                            />
                        </div>
                        <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                            <button type='submit' className='btn btn-primary btn-sm rounded-0 mt-4'>
                                <span className="me-2">Filter</span>
                                <i className='bi bi-funnel-fill'></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <DataTable
                title={<div className="fs-5">Total Past Paper Exams: {totalExams}</div>}
                columns={columns}
                data={exams}
                progressPending={isLoading}
                progressComponent={
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                }
                pagination
                paginationServer
                paginationTotalRows={totalExams}
                onChangePage={p => setPage(p)}
                onChangeRowsPerPage={pp => setPerPage(pp)}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[100, 200, 300, 500]}
                noDataComponent="No past paper exams found."
            />
        </div>
    )
}

export { PastPaperExams, PAST_PAPER_EXAM_TYPES }
