import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'
import { useAuth } from '../../context/AuthContext'
import FacilitiesTable from '../../components/facilities/FacilitiesTable'
import toast from 'react-hot-toast'

function SingleFacility() {
    const auth = useAuth()
    const params = useParams()
    const facilityId = params.facilityId

    const [isLoading, setIsLoading] = useState(true)
    const [facility, setFacility] = useState({})

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: 'School',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Required',
            selector: row => row.required,
            sortable: true
        },
        {
            name: 'Present',
            selector: row => row.available,
            sortable: true
        },
        {
            name: "Deficit",
            selector: row => row.deficit,
            sortable: true
        },
        {
            name: "Excess",
            selector: row => row.excess,
            sortable: true
        }
    ]

    const fetchFacility = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/facilities/${facilityId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setFacility(data.facility)
            }
        }
        catch (error) {
            toast.error("Failed to fetch facility details. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchFacility()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>
                Facility: {facility?.name}
            </p>

            <div className="shadow p-2 rounded-0 mb-3">
                <DataTable
                    columns={columns}
                    data={facility?.schools}
                    progressPending={isLoading}
                    progressComponent={<>Loading... <Loading /></>}
                />
            </div>
        </div>
    )
}

export default SingleFacility
