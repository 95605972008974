import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import { Link } from 'react-router-dom'
import RoleOffice from '../../../components/offices/RoleOffice'
import Loading from '../../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'

function Syllabus() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [subjects, setSubjects] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Abbreviation',
            selector: row => row.abbreviation,
            sortable: true,
        },
        {
            name: 'Level',
            selector: row => row.level,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <div className='d-flex'>
                <Link
                    to={`subject/${row.id}`}
                    state={{ subject: row }}
                    className='btn btn-sm btn-primary rounded-0'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            sortable: false,
        }
    ]

    const fetchSubjects = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/syllabus/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSubjects(data.subjects)
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjects()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Syllabuses</p>
                <div>
                    <RoleOffice roles={["Admin"]} element={
                        <Link
                            to={"new"}
                            className="btn btn-primary btn-sm fw-bold rounded-0"
                        >
                            <span className="me-2">Upload</span>
                            <i className="bi bi-plus-square"></i>
                        </Link>
                    } />
                </div>
            </div>

            <div className="shadow p-2 rounded-0 mb-3">
                <DataTable
                    columns={columns}
                    data={subjects}
                    noDataComponent={<div className="text-center">Loading...<Loading /></div>}
                />
            </div>
        </div>
    )
}

export default Syllabus
