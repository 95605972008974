import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { useAuth } from '../../context/AuthContext'
import RoleOffice from '../../components/offices/RoleOffice'
import toast from 'react-hot-toast'

function Schools() {
    const auth = useAuth()

    const toastId = "Schools"
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [schools, setSchools] = useState([])
    const [fetchedSchools, setFetchedSchools] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Reg No',
            selector: row => row.school_reg_no,
            sortable: true,
        },
        {
            name: 'Exam Reg No',
            selector: row => row.reg_no,
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => row.location,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <div className='d-flex'>
                <RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} element={
                    <Link
                        to={`/schools/${row.id}/edit`}
                        state={{ staff: row }}
                        className='btn btn-sm btn-primary rounded-0 me-2'>
                        <i className='bi bi-pencil-square fs-6 mx-1'></i>
                    </Link>
                } />
                <Link
                    to={`/schools/${row.id}`}
                    state={{ school: row }}
                    className='btn btn-sm btn-primary rounded-0'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            sortable: false,
        }
    ]

    const handleSearch = (value) => {
        if (value.length < 1) {
            setSchools(fetchedSchools)
            return
        }

        const searchResults = fetchedSchools.filter(school => {
            return school.name.toLowerCase().includes(value.toLowerCase()) ||
                school.reg_no.toLowerCase().includes(value.toLowerCase())
        })

        setSchools(searchResults)
    }

    const fetchSchools = async () => {
        setIsLoading(true)

        try {
            const resp = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await resp.json()
            if (!resp.ok) {
                toast.error(data.message, {id: toastId})
            }

            setSchools(data.schools)
            setFetchedSchools(data.schools)
        }
        catch (error) {
            toast.error("Something went wrong. Please, try again.", {id: toastId})
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSchools()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Registered Schools</p>
                <RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"]} element={
                    <Link to='/schools/new' className='btn btn-primary btn-sm fw-bold rounded-0'>
                        <span className="me-2">Register</span>
                        <i className='bi bi-plus-square-fill'></i>
                    </Link>}
                />
            </div>
            <div className="body shadow p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1 rounded-0"
                    placeholder="Search schools..."
                    onChange={e => handleSearch(e.target.value)}
                />
                <button className="btn btn-sm btn-primary px-3 fw-bold rounded-0">
                    Search
                </button>
            </div>

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={schools}
                            highlightOnHover
                            pagination
                            paginationPerPage={perPage}
                            onChangePage={page => setCurrentPage(page)}
                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                            paginationRowsPerPageOptions={[10, 20, 50, 150, 200]}
                            noDataComponent={"No schools found."}
                        />
                }
            </div>
        </div>
    )
}

export default Schools
