import React, { useEffect, useState } from 'react'
import RoleOffice from '../../components/offices/RoleOffice'
import { Link } from 'react-router-dom'
import LevelSplit from '../../components/offices/LevelSplit'
import { useAuth } from '../../context/AuthContext'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import toast from 'react-hot-toast'

function StudentTransfer() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [filterType, setFilterType] = useState("incoming")
    const [filterStatus, setFilterStatus] = useState("pending")

    const [fetchedTransfers, setFetchedTransfers] = useState([])
    const [transfers, setTransfers] = useState([])

    const columns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Student",
            selector: row => row.student,
            grow: 2,
        },
        {
            name: "Current School",
            selector: row => row.old_school.name
        },
        {
            name: "New School",
            selector: row => row.new_school.name
        },
        {
            name: "Date Requested",
            selector: row => row.new_school.date
        },
        {
            name: "Date Approved",
            selector: row => row.old_district.date
        },
        {
            name: "Status",
            selector: row => row.old_district.status
        },
        {
            name: "Action",
            cell: row => <div className='d-flex'>
                <button
                    className='btn btn-sm btn-danger rounded-0 me-2'
                    disabled={row.action !== "approve" || isLoading}
                    onClick={() => approveTransfer(row.id, "rejected")}
                >
                    <i className='bi bi-x-lg fs-6 mx-1'></i>
                </button>
                <button
                    className='btn btn-sm btn-primary rounded-0'
                    disabled={row.action !== "approve" || isLoading}
                    onClick={() => approveTransfer(row.id, "approved")}
                >
                    <i className='bi bi-check-lg fs-6 mx-1'></i>
                </button>
            </div>
        }
    ]

    const handleSearch = (searchTerm) => {
        if (searchTerm.length < 1) {
            setTransfers(fetchedTransfers)
            return
        }

        setTransfers(fetchedTransfers.filter(f => f.student.toLowerCase().includes(searchTerm.toLowerCase())))
    }

    const fetchTransfers = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/transfer/students?type=${filterType}&status=${filterStatus}`
            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setTransfers(data.transfers)
                setFetchedTransfers(data.transfers)
            }
        }
        catch (error) {
            toast.error('Failed to fetch transfers. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const approveTransfer = async (transferId, status) => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/transfer/student/approve/${transferId}/${status}`
            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
            }
        }
        catch (error) {
            toast.error('Failed to approve transfer. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTransfers()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>
                    <LevelSplit primary={"Pupils"} ordinary={"Students"} /> Transfer
                </p>
                <RoleOffice roles={["Headmaster", "Second Master", "Academic Master"]} element={
                    <Link to='request' className='btn btn-primary btn-sm fw-bold rounded-0'>
                        <span className="me-2">Request Transfer</span>
                        <i className='bi bi-person-fill-down fs-6'></i>
                    </Link>
                } />
            </div>

            <form onSubmit={null} className="body shadow p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1 rounded-0"
                    placeholder={"Search students by name"}
                    onChange={e => handleSearch(e.target.value)}
                />
            </form>

            <div className="my-3 shadow p-2">
                <p className='text-muted fw-bold'>
                    Filter Transfer Requests
                </p>
                <form onSubmit={e => {
                    e.preventDefault()
                    fetchTransfers()
                }}>
                    <div className="row mx-0 ">
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='type' className='form-label'>Type</label>
                            <select
                                id='type'
                                className='form-select rounded-0'
                                value={filterType}
                                onChange={e => setFilterType(e.target.value)}
                            >
                                <option value="incoming">Incoming</option>
                                <option value="outgoing">Outgoing</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='status' className='form-label'>Status</label>
                            <select
                                id='status'
                                className='form-select rounded-0'
                                value={filterStatus}
                                onChange={e => setFilterStatus(e.target.value)}
                            >
                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                            <button type='submit' className='btn btn-primary rounded-0 mt-4'>
                                <span className="me-2">Filter</span>
                                <i className='bi bi-funnel-fill'></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="my-3 shadow p-2">
                <DataTable
                    columns={columns}
                    data={transfers}
                    progressPending={isLoading}
                    progressComponent={<>Loading...<Loading /></>}
                />
            </div>
        </div>
    )
}

export default StudentTransfer
