import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link, useLocation } from 'react-router-dom'
import Loading from '../../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../../utils/constants'
import { saveAs } from 'file-saver'
import toast from 'react-hot-toast'

function NectaResults() {
    const auth = useAuth()
    const location = useLocation()
    const exam = location?.state?.exam

    const [isLoading, setIsLoading] = useState(false)
    const [schools, setSchools] = useState([])

    const columns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: "Analysis",
            selector: row => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name: "Download",
            cell: row => <div className="d-flex flex-wrap">
                <button
                    className="btn btn-sm text-primary fw-bold me-3"
                    onClick={() => downloadResult(row.link, "pdf", row.name)}
                >
                    <span className="me-2">PDF</span>
                    <i className="bi bi-file-earmark-pdf"></i>
                </button>
                <button
                    className="btn btn-sm text-primary fw-bold"
                    onClick={() => downloadResult(row.link, "excel", row.name)}
                >
                    <span className="me-2">Excel</span>
                    <i className="bi bi-file-earmark-spreadsheet"></i>
                </button>
            </div>,
            sortable: false
        }
    ]

    const fetchExam = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/necta/exams/${exam?.id}`, {
                headers: {
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setSchools(data.exam.participants)
            }
        }
        catch (error) {
            toast.error("Failed to fetch exam details. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    const downloadResult = async (link, type, name) => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/necta/results/export/ranking/${link}?format=${type}`, {
                headers: {
                    "x-access-token": auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                toast.error(data.message)
                return
            }

            let extension = "pdf"
            if (type === "excel") {
                extension = "xlsx"
            }

            const filename = `MSSIS ${exam?.type} ${name}.${extension}`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            toast.error("Failed to download results analysis. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchExam()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>{exam?.name}</p>
            <div className="shadow rounded-0 p-2 mb-3">
                <p className='h4 fw-bold text-muted'>Exam Details</p>
                <div className=''>
                    <p className='text-muted'>
                        Exam Type: <span className='fw-bold'>{exam?.type}</span>
                    </p>
                    <p className='text-muted'>
                        Start Date: <span className='fw-bold'>{exam?.start_date}</span>
                    </p>
                    <p className='text-muted'>
                        End Date: <span className='fw-bold'>{exam?.end_date}</span>
                    </p>
                </div>
            </div>

            {
                isLoading ?
                    (
                        <div className='text-center my-2'>
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <div className="shadow rounded-0 p-2 mb-3">
                            <p className='h4 fw-bold text-muted'>Download NECTA Analysis</p>
                            <DataTable
                                className="mb-3"
                                title={<p className="h5">District Level Analysis</p>}
                                columns={columns}
                                data={[
                                    {
                                        name: "District School Ranking",
                                        link: `schools/${exam?.id}`
                                    },
                                    {
                                        name: "Best Students",
                                        link: `students/${exam?.id}`
                                    },
                                    {
                                        name: "Overall Students Ranking",
                                        link: `students/${exam?.id}/overall`
                                    },
                                    {
                                        name: "Overall Teachers' Ranking",
                                        link: `teachers/${exam?.id}`
                                    },
                                    {
                                        name: "Subjectwise Teachers' Ranking",
                                        link: `teachers/subjectwise/${exam?.id}`
                                    },
                                    {
                                        name: "Subjectwise Schools' Ranking",
                                        link: `schools/subjectwise/${exam?.id}`
                                    },
                                    {
                                        name: "Overall Subjects' Ranking",
                                        link: `subjects/${exam?.id}`
                                    }
                                ]}
                            />

                            <DataTable
                                className="mb-3"
                                title={<p className="h5">School Level Analysis</p>}
                                columns={columns}
                                data={schools?.map(school => (
                                    {
                                        name: school?.name,
                                        link: `schools/${school?.id}/${exam?.id}`
                                    }
                                ))}
                            />
                        </div>
                    )
            }
        </div>
    )
}

export default NectaResults
