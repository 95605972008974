import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Loading from '../../../components/loading/Loading'
import RoleOffice from '../../../components/offices/RoleOffice'
import { useAuth } from '../../../context/AuthContext'
import { BASE_API_URL } from '../../../utils/constants'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'

function NectaExams() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const [filteredExams, setFilteredExams] = useState([])
    const [exams, setExams] = useState([])

    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.start_date,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.end_date,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row =>
                <div className='d-flex'>
                    <Link
                        to={`/academics/necta/exams/${row.id}`}
                        className='btn btn-sm btn-primary rounded-0 me-2'
                    >
                        <i className='bi bi-eye-fill fs-6 mx-1'></i>
                    </Link>
                    <Link
                        to={`/academics/results/${row.id}/necta`}
                        state={{ exam: row }}
                        className='btn btn-sm btn-primary rounded-0'
                    >
                        <i className='bi bi-graph-up-arrow fs-6 mx-1'></i>
                    </Link>
                </div>,
            sortable: false,
        }
    ]

    const handleSearch = (value) => {
        let data = exams.filter(exam => (
            exam.name.toLowerCase().includes(value.toLowerCase()) ||
            exam.start_date.includes(value.toLowerCase()) ||
            exam.end_date.includes(value.toLowerCase())
        ))
        setFilteredExams(data)
    }

    const fetchExams = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/necta/exams`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setFilteredExams(data.exams)
                setExams(data.exams)
            }
        }
        catch (error) {
            toast.error('Failed to fetch exams. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchExams()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>NECTA Exams</p>
                <RoleOffice
                    roles={["Admin"]}
                    element={
                        <Link to='/academics/necta/exams/new' className='btn btn-primary btn-sm fw-bold rounded-0'>
                            <span className="me-2">Register</span>
                            <i className='bi bi-plus-square-fill'></i>
                        </Link>
                    }
                />
            </div>
            <div className="body shadow p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1 rounded-0"
                    placeholder="Filter exams..."
                    onChange={e => handleSearch(e.target.value)}
                />
                <button className="btn btn-sm btn-primary px-3 fw-bold rounded-0 d-flex align-items-center">
                    <span className="me-2">Filter</span>
                    <i className='bi bi-funnel-fill'></i>
                </button>
            </div>

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={filteredExams}
                            highlightOnHover
                            pagination
                            paginationPerPage={perPage}
                            onChangePage={page => setCurrentPage(page)}
                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                            paginationRowsPerPageOptions={[10, 20, 50, 150, 200]}
                            noDataComponent={"No exams published or done yet."}
                        />
                }
            </div>
        </div>
    )
}

export default NectaExams
