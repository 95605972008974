import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import LevelSplit from '../offices/LevelSplit'
import toast from 'react-hot-toast'

function ResultsTitle({ exam, form, school, main_authority, sub_authoruty, region, district, lang, display, title, nectaFormat }) {
    // if display then dont fetch data from server, the component has been called only to display data
    const auth = useAuth()

    const month = Number(exam.start_date.split('/')[1])
    const month_str = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const month_str_sw = ['Januari', 'Febuari', 'Machi', 'Aprili', 'Mei', 'Juni', 'Julai', 'Agosti', 'Septemba', 'Oktoba', 'Novemba', 'Disemba']
    const year = exam.start_date.split('/')[2]
    
    const [isLoading, setIsLoading] = useState(false)
    const [mainAuthority, setMainAuthority] = useState(main_authority || "PRESIDENT'S OFFICE")
    const [subAuthority, setSubAuthority] = useState(sub_authoruty || "REGIONAL ADMINISTRATION AND LOCAL GOVERNMENT AUTHORITY")
    const [reg, setReg] = useState(region || "SIMIYU REGION")
    const [dist, setDist] = useState(district || "MEATU DISTRICT COUNCIL")
    const [language, setLanguage] = useState(lang || "ENGLISH")

    const fetchExamResultsTitle = async () => {
        if (display) {
            return
        }

        setIsLoading(true)
        try {
            const response = await fetch(`${BASE_API_URL}/results/title`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setMainAuthority(data.title.main_authority)
                setSubAuthority(data.title.sub_authority)
                setReg(data.title.region)
                setDist(data.title.district)
                setLanguage(data.title.language)
            }
        } catch (error) {
            toast.error('An error occurred. Please try again')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!display) {
            fetchExamResultsTitle()
        }
        else {
            setMainAuthority(main_authority)
            setSubAuthority(sub_authoruty)
            setReg(region)
            setDist(district)
            setLanguage(lang)
        }
    }, [main_authority, sub_authoruty, region, district, lang])

    return (
        <div className='row' style={{ fontFamily: "Times New Roman", color: "#800080" }}>
            <div className='col-12 text-center'>
                <h4 className='fw-bold'>{mainAuthority.toUpperCase() || "PRESIDENT'S OFFICE"}</h4>
                <h4 className="fw-bold">{subAuthority.toUpperCase() || "REGIONAL ADMINISTRATION AND LOCAL GOVERNMENT AUTHORITY"}</h4>
                <h4 className="fw-bold">{reg.toUpperCase() || "REGION"}</h4>
                <h4 className='fw-bold'>{dist.toUpperCase() || "DISTRICT COUNCIL"}</h4>
                <h4 className='fw-bold'>
                    {
                        language === "KISWAHILI" ?
                            `MATOKEO YA ${form.name || ''} ${exam.name}, ${month_str_sw[month - 1]}-${year}`.toUpperCase()
                            :
                            `${form.name || ''} ${exam.name} RESULTS, ${month_str[month - 1]}-${year}`.toUpperCase()
                    }
                </h4>
                {
                    school &&
                    <h4>
                        {
                            nectaFormat ?
                                <span className='fw-bold'>
                                    {
                                        language === "KISWAHILI" ?
                                            <>
                                                <LevelSplit primary={"SHULE YA MSINGI "} ordinary={"SHULE YA SEKONDARI "} />
                                                {school.reg_no} {school.name.toUpperCase()}
                                            </>
                                            :
                                            <>
                                                {school.reg_no} {school.name.toUpperCase()}
                                                <LevelSplit primary={" PRIMARY SCHOOL"} ordinary={" SECONDARY SCHOOL"} />
                                            </>
                                    }
                                </span>
                                :
                                <>
                                    <strong>
                                    {
                                        language === "KISWAHILI" ?
                                        "NAMBA YA KITUO: "
                                        :
                                        "CENTRE NO: " 
                                    }
                                    </strong>
                                    {school.reg_no}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <strong>
                                    {
                                        language === "KISWAHILI" ?
                                        "JINA LA KITUO: "
                                        :
                                        "CENTRE NAME: " 
                                    }
                                    </strong>
                                    {
                                        language === "KISWAHILI" ?
                                            <>
                                                <LevelSplit primary={"SHULE YA MSINGI "} ordinary={"SHULE YA SEKONDARI "} />
                                                {school.name.toUpperCase()}
                                            </>
                                            :
                                            <>
                                                {school.name.toUpperCase()}
                                                <LevelSplit primary={" PRIMARY SCHOOL"} ordinary={" SECONDARY SCHOOL"} />
                                            </>
                                    }
                                </>
                        }
                    </h4>
                }
                {
                    title && <h4 className='fw-bold'>{title.toUpperCase()}</h4>
                }
            </div>
        </div>
    )
}

export default ResultsTitle
