import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import Loading from '../../../components/loading/Loading'
import { BASE_API_URL } from '../../../utils/constants'
import { Link } from 'react-router-dom'
import RoleOffice from '../../../components/offices/RoleOffice'
import toast from 'react-hot-toast'

function LessonNotes({ purpose }) {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [classes, setClasses] = useState([])

    const fetchClasses = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/${purpose}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setClasses(data.classes)
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again.")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchClasses()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <p className='h3 fw-bold text-muted'>
                    {purpose === "notes" && "Lesson Notes"}
                    {purpose === "books" && "TIE Books"}
                    {purpose === "syllabus" && "Syllabuses"}
                </p>
                <div>
                    <RoleOffice roles={["Admin"]} element={
                        <Link
                            to={"new"}
                            className="btn btn-primary btn-sm fw-bold rounded-0"
                        >
                            <span className="me-2">Upload</span>
                            <i className="bi bi-plus-square"></i>
                        </Link>
                    } />
                </div>
            </div>

            {isLoading && <div className="text-center">Loading...<Loading /></div>}
            {
                !isLoading && classes.length < 1 && 
                <div className="text-center">
                    {purpose === "notes" && "No lesson notes uploaded."}
                    {purpose === "books" && "No TIE books uploaded."}
                    {purpose === "syllabus" && "No syllabuses uploaded."}
                </div>
            }

            {
                !isLoading &&
                <div className="mb-3">
                    {
                        classes?.map((cls, index) => (
                            <div className="shadow rounded-0 p-2 mb-3" key={index}>
                                <p className='text-muted fw-bold'>{cls?.name}</p>
                                <div className="row mx-0">
                                    {
                                        cls?.subjects?.map((subject, id) => (
                                            <div className="col-6 col-lg-2 mb-3" key={id}>
                                                <Link
                                                    to={`${cls.id}/${subject.id}`}
                                                    state={{ form: cls, subject }}
                                                >
                                                    {subject.name}
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default LessonNotes
