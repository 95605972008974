import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../../utils/constants'
import RoleOffice from '../../../components/offices/RoleOffice'
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog'
import LevelSplit from '../../../components/offices/LevelSplit'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'

function ViewNectaExam() {
    const auth = useAuth()
    const param = useParams()
    const examId = param.exam_id
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [exam, setExam] = useState(null)
    const [showDialog, setShowDialog] = useState(false)

    const columns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Name",
            selector: row => row.name,
        },
        {
            name: "Reg. No",
            selector: row => row.reg_no,
        }
    ]

    const fetchExam = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/necta/exams/${examId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                setExam(data.exam)
            }
        }
        catch (error) {
            toast.error('Failed to fetch exam details. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const confirmDelete = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/necta/exams/${examId}`, {
                method: 'DELETE',
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                toast.error(data.message)
            }
            else {
                toast.success(data.message)
                navigate('/academics/necta/exams')
            }
        }
        catch (error) {
            toast.error('Failed to delete exam. Please try again.')
        }
        finally {
            setIsLoading(false)
            setShowDialog(false)
        }
    }

    useEffect(() => {
        fetchExam()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>{exam?.name}</p>
                <div className="d-flex flex-wrap align-items-center">
                    <RoleOffice roles={["Admin"]}
                        element={
                            <button
                                className='btn btn-danger btn-sm fw-bold rounded-0 mb-2 me-2'
                                onClick={() => setShowDialog(true)}
                            >
                                <span className="me-2">Delete</span>
                                <i className='bi bi-trash'></i>
                            </button>}
                    />
                    <RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "Headmaster", "Second Master", "Master", "Academic Master"]}
                        element={
                            <Link
                                to={`candidates`}
                                className='btn btn-primary btn-sm fw-bold rounded-0 mb-2'
                            >
                                <span className='me-2'>Registered Candidates</span>
                                <i className='bi bi-person-fill-check'></i>
                            </Link>
                        }
                    />
                </div>
            </div>
            <ConfirmationDialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false)
                    setIsLoading(false)
                }}
                onConfirm={confirmDelete}
                isLoading={isLoading}
                title='Delete Exam'
                message={`Are you sure you want to delete ${exam?.name}?`}
            />

            <div className="shadow rounded-0 p-2 mb-3">
                <p className='h4 fw-bold text-muted'>Exam Details</p>
                <div className=''>
                    <p className='text-muted'>
                        Exam Type: <span className='fw-bold'>{exam?.type}</span>
                    </p>
                    <p className='text-muted'>
                        Start Date: <span className='fw-bold'>{exam?.start_date}</span>
                    </p>
                    <p className='text-muted'>
                        End Date: <span className='fw-bold'>{exam?.end_date}</span>
                    </p>
                </div>
            </div>

            <div className="shadow rounded-0 p-2 mb-3">
                <p className='h4 fw-bold text-muted'>
                    Examination Centers ({exam?.participants?.length})
                </p>
                <DataTable
                    columns={columns}
                    data={exam?.participants}
                    responsive
                    progressPending={isLoading}
                    noDataComponent={"No examination centers found."}
                />
            </div>
        </div>
    )
}

export default ViewNectaExam
