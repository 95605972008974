import React from 'react'
import './SubjectPerformance.css'
import './table-styles.css'
import LevelSplit from '../offices/LevelSplit'
import { useAuth } from '../../context/AuthContext'

function SubjectPerformance({ summary, nectaFormat, level }) {
    const auth = useAuth()
    const columns_count = auth.user.level == "PRIMARY" ? 6 : 7
    return (
        <div>
            {
                nectaFormat ?
                    <div className="table-responsive">
                        <LevelSplit
                            primary={null}
                            ordinary={
                                <table className='text-center necta-format necta-format-collapse'>
                                    <thead>
                                        <tr>
                                            <th colSpan={2} className='pe-5'>
                                                EXAMINATION CENTRE OVERALL PERFORMANCE
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-start'>
                                        <tr>
                                            <td>EXAMINATION CENTRE REGION</td>
                                            <td>{summary?.bottom_summary?.region}</td>
                                        </tr>
                                        <tr>
                                            <td>TOTAL PASSED CANDIDATES</td>
                                            <td>{summary?.bottom_summary?.pass}</td>
                                        </tr>
                                        <tr>
                                            <LevelSplit
                                                primary={
                                                    <>
                                                        <td>CENTRE PASS PERCENTAGE</td>
                                                        <td>
                                                            {summary?.bottom_summary?.pass_percentage}
                                                        </td>
                                                    </>
                                                }
                                                ordinary={
                                                    <>
                                                        <td>EXAMINATION CENTRE GPA</td>
                                                        <td>
                                                            {summary?.bottom_summary?.gpa}&nbsp;
                                                            <span className={`grade-${level}${summary?.bottom_summary?.grade}`}>GRADE {summary?.bottom_summary?.grade}</span>
                                                        </td>
                                                    </>
                                                }
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        />
                        {
                            false &&
                            <table className='text-center necta-format necta-format-collapse'>
                                <thead>
                                    <tr>
                                        <th className='text-start pe-5' colSpan={11}>EXAMINATION CENTRE DIVISION PERFORMANCE</th>
                                    </tr>
                                    <tr>
                                        <th>REGIST</th>
                                        <th>ABSENT</th>
                                        <th>SAT</th>
                                        <th>WITHHELD</th>
                                        <th>NO-CA</th>
                                        <th>CLEAN</th>
                                        <th>DIV I</th>
                                        <th>DIV II</th>
                                        <th>DIV III</th>
                                        <th>DIV IV</th>
                                        <th>DIV 0</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        <table className='text-center necta-format necta-format-collapse'>
                            <thead>
                                <tr>
                                    <th className='text-start' colSpan={columns_count + 4}>
                                        <LevelSplit
                                            primary={"UFAULU WA MASOMO KATIKA MAKUNDI YA UMAHIRI"}
                                            ordinary={"EXAMINATION CENTRE SUBJECTS PERFORMANCE"}
                                        />
                                    </th>
                                </tr>
                                <tr className='nowrap'>
                                    <th>
                                        <LevelSplit primary={"NAMBA"} ordinary={"CODE"} />
                                    </th>
                                    <th>
                                        <LevelSplit primary={"SOMO"} ordinary={"SUBJECT NAME"} />
                                    </th>
                                    <th><LevelSplit primary={"WALIOSAJILIWA"} ordinary={"REG"} /></th>
                                    <th><LevelSplit primary={"WALIOFANYA"} ordinary={"SAT"} /></th>
                                    <LevelSplit primary={null} ordinary={<th>NO-CA</th>} />
                                    <th><LevelSplit primary={"WALIOFUTIWA/SITISHIWA"} ordinary={"W/HD"} /></th>
                                    <th><LevelSplit primary={"WENYE MATOKEO"} ordinary={"CLEAN"} /></th>
                                    <th><LevelSplit primary={"WALIOFAULU (GREDI A-C)"} ordinary={"PASS"} /></th>
                                    <th><LevelSplit primary={"WASTANI WA ALAMA (/50)"} ordinary={"GPA"} /></th>
                                    <LevelSplit primary={null} ordinary={<th>SCHL/RANK</th>} />
                                    <th><LevelSplit primary={"KUNDI LA UMAHIRI"} ordinary={"COMPENTENCY LEVEL"} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    summary.ranks.map((rank, index) => (
                                        <tr key={index}>
                                            <td className='text-start'>{rank?.subject?.code}</td>
                                            <td className='text-start'>{rank?.subject?.name.toUpperCase()}</td>
                                            {/* for primary level value for registered is equal to the total number of pupils */}
                                            <td className='text-end'>
                                                <LevelSplit
                                                    primary={summary.bottom_summary["total_pupils"]}
                                                    ordinary={null}
                                                />
                                            </td>
                                            <td className='text-end'>{rank?.total}</td>
                                            <td className='text-end'>0</td>
                                            <LevelSplit 
                                                primary={null} 
                                                ordinary={<td className='text-end'>0</td>} 
                                            />
                                            <td className='text-end'>{rank?.total}</td>
                                            <td className='text-end'>{rank?.pass}</td>
                                            <LevelSplit
                                                primary={
                                                    <td className='text-end'>{rank?.average}</td>
                                                }
                                                ordinary={
                                                    <td className='text-end'>{rank?.gpa}</td>
                                                }
                                            />
                                            <LevelSplit 
                                                primary={null} 
                                                ordinary={<td>{rank?.rank}/{summary?.ranks?.length}</td>}
                                            />
                                            <td className='text-start p-0'>
                                                <div className={`grade-${level}${rank.grade} px-1`}>
                                                    <LevelSplit
                                                        primary={rank.competance && `Daraja ${rank.grade} (${rank.competance})`}
                                                        ordinary={rank.competance && `Grade ${rank.grade} (${rank.competance})`}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover">
                            <thead>
                                <tr>
                                    <th colSpan={columns_count + 3 + summary.grades.length * 3}>
                                        SUBJECT PERFORMANCE SUMMARY
                                    </th>
                                </tr>
                                <tr className='align-middle'>
                                    <th rowSpan={4}>S/N</th>
                                    <th rowSpan={4}>SUBJECTS</th>
                                    <th colSpan={1 + summary.grades.length * 3}>SUBJECT GRADE</th>
                                    <th colSpan={columns_count}>SUBJECT PERFORMANCE ANALYSIS</th>
                                </tr>
                                <tr className='align-middle'>
                                    {
                                        summary.grades.map((grade, index) => (
                                            <React.Fragment key={index}>
                                                <th colSpan={3}>{grade.grade}</th>
                                            </React.Fragment>
                                        ))
                                    }
                                    <th rowSpan={2}>
                                        <span className='vertical-text'>TOTAL</span>
                                    </th>
                                    <th rowSpan={2}>
                                        <span className='vertical-text'>AVERAGE</span>
                                    </th>
                                    <th rowSpan={2}>
                                        <span className='vertical-text'>GRADE</span>
                                    </th>
                                    <LevelSplit
                                        primary={null}
                                        ordinary={
                                            <th rowSpan={2}>
                                                <span className='vertical-text'>GPA</span>
                                            </th>
                                        }
                                    />
                                    <th rowSpan={2}>
                                        <span className='vertical-text'>PASS</span>
                                    </th>
                                    <th rowSpan={2}>
                                        <span className='vertical-text'>FAIL</span>
                                    </th>
                                    <th rowSpan={2}>
                                        <span className='vertical-text'>RANK</span>
                                    </th>
                                    <th rowSpan={2}>COMPETENCE LEVEL</th>
                                </tr>
                                <tr className='align-middle'>
                                    {
                                        summary.grades.map((grade, index) => (
                                            <React.Fragment key={index}>
                                                <th>F</th>
                                                <th>M</th>
                                                <th>T</th>
                                            </React.Fragment>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    summary.ranks.map((rank, index) => (
                                        <tr key={index} className='text-readable'>
                                            <td>{index + 1}</td>
                                            <td className='text-start'>{rank.subject.name.toUpperCase()}</td>
                                            {
                                                summary.grades.map((grade, index) => (
                                                    <React.Fragment key={index}>
                                                        <td>{rank.grades[grade.grade]["F"]}</td>
                                                        <td>{rank.grades[grade.grade]["M"]}</td>
                                                        <td>{rank.grades[grade.grade]["T"]}</td>
                                                    </React.Fragment>
                                                ))
                                            }
                                            <td>{rank.total}</td>
                                            <td>{rank.average}</td>
                                            <td>{rank.grade}</td>
                                            <LevelSplit
                                                primary={null}
                                                ordinary={<td>{rank.gpa}</td>}
                                            />
                                            <td>{rank.pass}</td>
                                            <td>{rank.fail}</td>
                                            <td>{rank.rank}/{summary.ranks.length}</td>
                                            <td className={`text-start p-0`}>
                                                <div className={`grade-${level}${rank.grade} px-1`}>
                                                    {rank.competance && `Grade ${rank.grade} (${rank.competance})`}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                {
                                    // dear future reader, 
                                    // how I obtained the colspans in the code below is a mystrey
                                    // a little bit of math, a little bit of trial and error and a lot of magic
                                    // anyway dont be alarmed, it's not as bad as this comment conveys
                                    summary.bottom_summary &&
                                    <>
                                        <tr className="fw-bold">
                                            <td colSpan={columns_count + 3 + summary.grades.length * 3}>EXAMINATION CENTRE RANKING</td>
                                        </tr>
                                        <tr className="fw-bold">
                                            <td className="text-start" colSpan={2}>EXAMINATION CENTRE REGION: </td>
                                            <td className="text-info text-start" colSpan={4}>{summary.bottom_summary["region"]}</td>
                                            <td className="text-start" colSpan={6}>TOTAL PASSED CANDIDATES: </td>
                                            <td className="text-info" colSpan={2}>{summary.bottom_summary["pass"]}</td>
                                            {
                                                summary?.bottom_summary?.type === "internal" ?
                                                    <td className="text-start" colSpan={3 + summary.grades.length * 3 - 12}>CLASS CATEGORY: </td>
                                                    :
                                                    <td className="text-start" colSpan={3 + summary.grades.length * 3 - 12}>CENTRE CATEGORY: </td>
                                            }
                                            <td className="text-info" colSpan={columns_count - 2}>{summary.bottom_summary["category"]}</td>
                                        </tr>
                                        <tr className="fw-bold">
                                            <td className="text-start" colSpan={2}>EXAMINATION CENTRE DISTRICT: </td>
                                            <td className="text-info text-start" colSpan={4}>{summary.bottom_summary["district"]}</td>
                                            {
                                                summary?.bottom_summary?.type === "internal" ?
                                                    <LevelSplit
                                                        primary={
                                                            <td className="text-start" colSpan={6}>CLASS PASS PERCENTAGE: </td>
                                                        }
                                                        ordinary={
                                                            <td className="text-start" colSpan={6}>EXAMINATION CLASS GPA: </td>
                                                        }
                                                    />
                                                    :
                                                    <LevelSplit
                                                        primary={
                                                            <td className="text-start" colSpan={6}>CENTRE PASS PERCENTAGE: </td>
                                                        }
                                                        ordinary={
                                                            <td className="text-start" colSpan={6}>EXAMINATION CENTRE GPA: </td>
                                                        }
                                                    />
                                            }
                                            <LevelSplit
                                                primary={
                                                    <td className="text-info" colSpan={2}>{summary.bottom_summary["pass_percentage"]}</td>
                                                }
                                                ordinary={
                                                    <td className="text-info" colSpan={2}>{summary.bottom_summary["gpa"]}</td>
                                                }
                                            />
                                            {
                                                summary?.bottom_summary?.type === "external" &&
                                                <>
                                                    <td className="text-start" colSpan={summary.grades.length * 3 - 12}>CENTRE POSITION: </td>
                                                    <td className="text-info" colSpan={2}>{summary.bottom_summary["position"]}</td>
                                                </>
                                            }
                                            {
                                                summary?.bottom_summary?.type === "internal" ?
                                                    <>
                                                        <td className="text-start" colSpan={3 + summary.grades.length * 3 - 12}>CLASS AVERAGE: </td>
                                                        <td className="text-info" colSpan={4 + columns_count - 7}>{summary.bottom_summary["average"]}</td>
                                                    </>
                                                    :
                                                    <>
                                                        <td className="text-start" colSpan={3}>SCHOOL AVERAGE: </td>
                                                        <td className="text-info" colSpan={2 + columns_count - 7}>{summary.bottom_summary["average"]}</td>
                                                    </>
                                            }
                                            <td className="text-info" colSpan={1}>{summary.bottom_summary["grade"]}</td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default SubjectPerformance
